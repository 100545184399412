@import '~vue-multiselect/dist/vue-multiselect.min.css';
/*
  All a user has to do to change the calendar size is
  change the font-size on the container and everything
  magically resizes accordingly. Relative units ftw!
*/
.qs-datepicker-container {
  font-size: 1rem;
  font-family: sans-serif;
  color: black;
  position: absolute;
  width: 15.625em;
  display: flex;
  flex-direction: column;
  z-index: 9001;
  user-select: none;
  border: 1px solid gray;
  border-radius: 0.263921875em;
  overflow: hidden;
  background: white;
  box-shadow: 0 1.25em 1.25em -0.9375em rgba(0, 0, 0, 0.3);
}
.qs-datepicker-container * {
  box-sizing: border-box;
}

.qs-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qs-hidden {
  display: none;
}

.qs-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  width: 100%;
  height: 100%;
  padding: 0.5em;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: column;
}
.qs-overlay.qs-hidden {
  opacity: 0;
  z-index: -1;
}
.qs-overlay .qs-overlay-year {
  border: none;
  background: transparent;
  border-bottom: 1px solid white;
  border-radius: 0;
  color: white;
  font-size: 0.875em;
  padding: 0.25em 0;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.qs-overlay .qs-overlay-year::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.qs-overlay .qs-close {
  padding: 0.5em;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
.qs-overlay .qs-submit {
  border: 1px solid white;
  border-radius: 0.263921875em;
  padding: 0.5em;
  margin: 0 auto auto;
  cursor: pointer;
  background: rgba(128, 128, 128, 0.4);
}
.qs-overlay .qs-submit.qs-disabled {
  color: gray;
  border-color: gray;
  cursor: not-allowed;
}
.qs-overlay .qs-overlay-month-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}
.qs-overlay .qs-overlay-month {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.15s;
}
.qs-overlay .qs-overlay-month.active, .qs-overlay .qs-overlay-month:hover {
  opacity: 1;
}

.qs-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  background: lightgray;
  filter: blur(0px);
  transition: filter 0.3s;
}
.qs-controls.qs-blur {
  filter: blur(5px);
}

.qs-arrow {
  height: 1.5625em;
  width: 1.5625em;
  position: relative;
  cursor: pointer;
  border-radius: 0.263921875em;
  transition: background 0.15s;
}
.qs-arrow:hover {
  background: rgba(0, 0, 0, 0.1);
}
.qs-arrow:hover.qs-left:after {
  border-right-color: black;
}
.qs-arrow:hover.qs-right:after {
  border-left-color: black;
}
.qs-arrow:after {
  content: "";
  border: 0.390625em solid transparent;
  position: absolute;
  top: 50%;
  transition: border 0.2s;
}
.qs-arrow.qs-left:after {
  border-right-color: gray;
  right: 50%;
  transform: translate(25%, -50%);
}
.qs-arrow.qs-right:after {
  border-left-color: gray;
  left: 50%;
  transform: translate(-25%, -50%);
}

.qs-month-year {
  font-weight: bold;
  transition: border 0.2s;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}
.qs-month-year:hover {
  border-bottom: 1px solid gray;
}
.qs-month-year:focus, .qs-month-year:active:focus {
  outline: none;
}

.qs-month {
  padding-right: 0.5ex;
}

.qs-year {
  padding-left: 0.5ex;
}

.qs-squares {
  display: flex;
  flex-wrap: wrap;
  padding: 0.3125em;
  filter: blur(0px);
  transition: filter 0.3s;
}
.qs-squares.qs-blur {
  filter: blur(5px);
}

.qs-square {
  width: calc(100% / 7);
  height: 1.5625em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.1s;
  border-radius: 0.263921875em;
}
.qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
  background: orange;
}

.qs-current {
  font-weight: bold;
  text-decoration: underline;
}

/*
  3 possibilities:
    1. Single, active date.
    2. Daterange start selection.
    3. Daterange end selection.
*/
.qs-active,
.qs-range-start,
.qs-range-end {
  background: lightblue;
}

.qs-range-start:not(.qs-range-6) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.qs-range-middle {
  background: #d4ebf2;
}
.qs-range-middle:not(.qs-range-0):not(.qs-range-6) {
  border-radius: 0;
}
.qs-range-middle.qs-range-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.qs-range-middle.qs-range-6 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.qs-range-end:not(.qs-range-0) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.qs-disabled,
.qs-outside-current-month {
  opacity: 0.2;
}

.qs-disabled {
  cursor: not-allowed;
}

.qs-empty {
  cursor: default;
}

.qs-day {
  cursor: default;
  font-weight: bold;
  color: gray;
}

.qs-event {
  position: relative;
}
.qs-event:after {
  content: "";
  position: absolute;
  width: 0.46875em;
  height: 0.46875em;
  border-radius: 50%;
  background: #07f;
  bottom: 0;
  right: 0;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon svg {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.btn {
  display: inline-block;
  border-radius: 64px;
  appearance: none;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  transition: 0.2s ease;
  cursor: pointer;
  text-decoration: none;
  padding: 20px;
  min-width: 200px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 600px) {
  .btn {
    padding: 13px;
    font-size: 14px;
    line-height: 22px;
  }
}
.btn-small {
  padding: 12px;
}
.btn-tiny {
  padding: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.btn-tiny-font {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.btn-iconed {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-iconed .btn__icon {
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  min-width: 16px;
  height: 16px;
}
.btn-iconed .btn__icon svg {
  max-width: 100%;
  width: 100%;
  height: 100%;
}
.btn-blue {
  background-color: #2150AA;
  border-color: #2150AA;
  color: #FFFFFF;
}
.btn-blue:active {
  background: linear-gradient(0deg, rgba(0, 0, 9, 0.2), rgba(0, 0, 9, 0.2)), #2150AA;
  border-color: rgba(0, 0, 0, 0);
}
.btn-orange {
  background-color: #F19202;
  border-color: #F19202;
  color: #FFFFFF;
}
.btn-orange:active {
  background: linear-gradient(0deg, rgba(0, 0, 9, 0.2), rgba(0, 0, 9, 0.2)), #F19202;
  border-color: rgba(0, 0, 0, 0);
}
.btn-green {
  background-color: #29BDAB;
  border-color: #29BDAB;
  color: #FFFFFF;
}
.btn-green:active {
  background: linear-gradient(0deg, rgba(0, 0, 9, 0.2), rgba(0, 0, 9, 0.2)), #29BDAB;
  border-color: rgba(0, 0, 0, 0);
}
.btn-red {
  background-color: #F13B02;
  border-color: #F13B02;
  color: #FFFFFF;
}
.btn-red:active {
  background: linear-gradient(0deg, rgba(0, 0, 9, 0.2), rgba(0, 0, 9, 0.2)), #F13B02;
  border-color: rgba(0, 0, 0, 0);
}
.btn-outline {
  background-color: #FFFFFF;
  border-color: #D2DADC;
  color: #2150AA;
}
.btn-outline:hover {
  border-color: #2150AA;
}
.btn-outline:active {
  background-color: #E4EFFF;
}
.btn-outline2 {
  background-color: #FFFFFF;
  border-color: #EBF3F8;
  color: #6A7378;
}
.btn-outline2:hover {
  background-color: #EBF3F8;
}
.btn-outline2:active {
  border-color: #E4EFFF;
  background-color: #E4EFFF;
}
.btn-flat {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  color: #2150AA;
}
.btn-flat:hover {
  background-color: #EBF3F8;
  border-color: #EBF3F8;
}
.btn-flat:active {
  background-color: #E4EFFF;
  border-color: #E4EFFF;
}
.btn-blue:hover, .btn-orange:hover, .btn-green:hover, .btn-red:hover {
  opacity: 0.9;
}
.btn-blue:disabled, .btn-blue.disabled, .btn-orange:disabled, .btn-orange.disabled, .btn-green:disabled, .btn-green.disabled, .btn-red:disabled, .btn-red.disabled {
  opacity: 0.5;
}
.btn-special {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.btn-special .sub-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.7;
}
.btn-special .sub-text--iconed {
  display: flex;
  align-items: center;
}
.btn-special .sub-text--iconed .icon {
  margin-left: 10px;
  width: 16px;
  height: 16px;
}
.btn-special .sub-text--iconed .icon svg {
  width: 100%;
  height: 100%;
}
@media (max-width: 600px) {
  .btn-special {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    flex-direction: row;
  }
  .btn-special .sub-text {
    font-size: 0;
  }
}

.main--error-page::before, .advantages__name, .reviews__item::before, .reviews__name, .section__title--forum-font, .section--questions__title, .what-is__name, .section--lawyers__name, .peek__title, .footer .footer-top-column .name {
  font-family: "Forum", serif;
}

.body {
  font-family: "Cabin", sans-serif;
}

.input-label, .textarea-label, .select-wrapper {
  display: block;
  position: relative;
}
.input-label .tooltip, .textarea-label .tooltip, .select-wrapper .tooltip {
  position: absolute;
  right: 20px;
  z-index: 1;
  border-radius: 50%;
  background-color: #FFFFFF;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("../../img/info-tooltip-icon.svg");
}
.input-label .tooltip + .field, .input-label .tooltip + .textarea, .textarea-label .tooltip + .field, .textarea-label .tooltip + .textarea, .select-wrapper .tooltip + .field, .select-wrapper .tooltip + .textarea {
  padding-right: 60px;
}
.input-label .tooltip::after, .textarea-label .tooltip::after, .select-wrapper .tooltip::after {
  content: attr(data-text);
  position: absolute;
  padding: 16px;
  max-width: 240px;
  min-width: 240px;
  left: 50%;
  bottom: calc(100% + 14px);
  transform: translateX(-50%);
  transition: 0.2s ease;
  background-color: #000009;
  opacity: 0;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
}
.input-label .tooltip::before, .textarea-label .tooltip::before, .select-wrapper .tooltip::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #000009 transparent transparent transparent;
  left: 50%;
  bottom: calc(100% + 6px);
  transform: translateX(-50%);
  opacity: 0;
  transition: 0.2s ease;
}
.input-label .tooltip:hover::before, .input-label .tooltip:hover::after, .textarea-label .tooltip:hover::before, .textarea-label .tooltip:hover::after, .select-wrapper .tooltip:hover::before, .select-wrapper .tooltip:hover::after {
  opacity: 1;
}

.input-label {
  display: flex;
  flex-direction: column;
  position: relative;
}
.input-label .name {
  max-width: calc(100% - 40px);
  padding-right: 6px;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 40%;
  transition: 0.2s ease;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #40434B;
  transform: translate(0, calc(-50% - 9px));
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.input-label .field {
  padding: 30px 20px 12px 20px;
  border: 2px solid #D2DADC;
  appearance: none;
  outline: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000009;
  background-color: #FFFFFF;
  transition: 0.2s ease;
  box-shadow: none;
}
.input-label .field [type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.input-label .field::-webkit-outer-spin-button, .input-label .field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-label .field:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.input-label .field:disabled + .name {
  opacity: 0.5;
  cursor: not-allowed;
}
.input-label .field:required + .name::after {
  content: "*";
  display: inline-block;
  color: #F13B02;
  position: absolute;
  top: 0;
  right: 0;
}
.input-label .field.filled + .name {
  color: #6A7378;
}
.input-label .field.has-error {
  border-color: #F13B02;
}
.input-label .field.has-error + .name {
  color: #F13B02;
}
.input-label .field:focus {
  border-color: #2150AA;
}
.input-label .field:focus + .name {
  color: #2150AA;
}
.input-label .field:focus + .name, .input-label .field.filled + .name {
  transform: translate(0, calc(-50% - 9px));
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.input-label .field[data-datepicker], .input-label .field.field-datepicker {
  width: 100%;
  display: inline-block;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  background-image: url("../../img/datepicker-icon.svg");
}
.input-label .field[data-datepicker]:focus, .input-label .field.field-datepicker:focus {
  background-image: url("../../img/datepicker-icon-sapphire.svg");
}
.input-label .error {
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  cursor: pointer;
  color: #F13B02;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.input-label .tooltip {
  top: 50%;
  transform: translateY(-50%);
}

.input-label--datepicker:focus-within .name, .input-label--datepicker.field-not-empty .name {
  color: #2150AA;
  transform: translate(0, calc(-50% - 9px));
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.input-label--datepicker.required .name::after {
  content: "*";
  color: #F13B02;
}

.inputs-line {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 24px;
}
.inputs-line.added {
  grid-template-columns: minmax(0, calc(50% - 24px)) minmax(0, calc(50% - 44px)) 20px;
}

.remove-added-line {
  width: 20px;
  height: 20px;
  min-width: 20px;
  align-self: center;
  color: #6A7378;
  transition: 0.2s ease;
  cursor: pointer;
}
.remove-added-line:hover {
  color: #131F49;
}

.repeatable-case .remove-added-line {
  margin-top: 24px;
  margin-left: 24px;
}

.checkbox-name, .radio-name {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  vertical-align: middle;
}
.checkbox-name a, .radio-name a {
  color: #2150AA;
}
.checkbox-name a:hover, .radio-name a:hover {
  text-decoration: none;
}

.checkbox {
  display: block;
  cursor: pointer;
}
.checkbox input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
.checkbox .checkbox-name {
  display: flex;
  align-items: flex-start;
}
.checkbox .checkbox-name::before {
  content: "";
  margin-top: 3px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 1px solid #D2DADC;
  transition: 0.2s ease;
}
.checkbox input:checked + .checkbox-name::before {
  border-color: rgba(0, 0, 0, 0);
  background-color: #2150AA;
  background-image: url("../../img/checkmark-white.svg");
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}
.checkbox:focus-within .checkbox-name::before {
  box-shadow: 0 0 0 3px rgba(33, 80, 170, 0.25);
}

.checkbox-switch {
  cursor: pointer;
  position: relative;
  display: inline-flex;
}
.checkbox-switch input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
.checkbox-switch .checkbox-name {
  padding-left: 36px;
}
.checkbox-switch .checkbox-name::before {
  content: "";
  position: absolute;
  width: 30px;
  min-width: 30px;
  height: 20px;
  background-color: #D2DADC;
  transition: 0.2s ease;
  border-radius: 22px;
  left: 0;
  top: 2px;
}
.checkbox-switch .checkbox-name::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 6px;
  width: 12px;
  min-width: 12px;
  height: 12px;
  background-color: #FFFFFF;
  border-radius: 50%;
  transition: 0.2s ease;
}
.checkbox-switch input:checked + .checkbox-name::before {
  background-color: #2150AA;
}
.checkbox-switch input:checked + .checkbox-name::after {
  transform: translateX(10px);
}
.checkbox-switch:focus-within .checkbox-name::before {
  box-shadow: 0 0 0 3px rgba(33, 80, 170, 0.25);
}

.radio {
  cursor: pointer;
  display: flex;
}
.radio + .radio:not(.radio--lined) {
  margin-top: 12px;
}
.radio input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
.radio .radio-name {
  display: flex;
}
.radio .radio-name::before {
  content: "";
  margin-right: 8px;
  width: 24px;
  min-width: 24px;
  height: 24px;
  box-shadow: inset 0 0 0 2px #D2DADC;
  transition: 0.2s ease;
  border-radius: 50%;
}
.radio input:checked + .radio-name::before {
  border-width: 0;
  box-shadow: inset 0 0 0 7px #000009;
}
.radio:focus-within .checkbox-name::before {
  box-shadow: inset 0 0 0 2px #D2DADC, 0 0 0 3px rgba(33, 80, 170, 0.25);
}
.radio:focus-within input:checked + .radio-name::before {
  border-width: 0;
  box-shadow: inset 0 0 0 7px #000009, 0 0 0 3px rgba(33, 80, 170, 0.25);
}

.radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.radio-group--horizontal {
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -12px -12px -12px;
}
.radio-group--horizontal .radio + .radio:not(.radio--lined) {
  margin-top: 0;
}
.radio-group--horizontal .radio:not(.radio--lined) {
  margin: 0 12px 12px 12px;
}

.textarea-label {
  display: block;
  position: relative;
}
.textarea-label .name-line {
  display: flex;
  align-items: flex-end;
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #6A7378;
  width: 100%;
}
.textarea-label .name-line .name {
  letter-spacing: 1px;
  position: relative;
  padding-right: 10px;
}
.textarea-label .name-line .toggler {
  display: flex;
  align-items: center;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: -3px;
  text-transform: none;
}
.textarea-label .name-line .checkbox-switch .checkbox-name::after {
  top: 4px;
}
.textarea-label .name-line .checkbox-switch .checkbox-name::before {
  top: 0;
}
.textarea-label .name-line .length {
  opacity: 0.5;
  margin-left: auto;
}
.textarea-label.required .name-line .name::after {
  content: "*";
  color: #F13B02;
  position: absolute;
  top: 0;
  right: 0;
}
.textarea-label .textarea {
  display: block;
  width: 100%;
  padding: 10px 20px;
  border: 2px solid #D2DADC;
  appearance: none;
  outline: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000009;
  background-color: #FFFFFF;
  transition: opacity 0.2s ease, border 0.2s ease;
  box-shadow: none;
  resize: vertical;
}
.textarea-label .textarea.no-resize {
  resize: none;
}
.textarea-label .textarea.single-line {
  height: 46px;
}
.textarea-label .textarea:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.textarea-label .textarea.has-error {
  border-color: #F13B02;
}
.textarea-label .textarea:focus {
  border-color: #2150AA;
}
.textarea-label .tooltip {
  top: 36px;
}

.custom-file-upload .selected-files .item {
  padding: 12px 12px 12px 10px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #EBF3F8;
  font-size: 14px;
  line-height: 22px;
  color: #40434B;
  margin-bottom: 6px;
}
.custom-file-upload .selected-files .item .icon {
  margin-right: 10px;
  width: 24px;
  min-width: 24px;
  height: 24px;
}
.custom-file-upload .selected-files .item .name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-file-upload .selected-files .item .remove {
  cursor: pointer;
  margin-left: auto;
  transition: 0.2s ease;
}
.custom-file-upload .selected-files .item .remove:hover {
  opacity: 0.8;
}
.custom-file-upload .selected-files .item .remove .svg-icon {
  width: 16px;
  min-width: 16px;
  height: 16px;
}
.custom-file-upload .custom-file-input {
  width: 100%;
}
.custom-file-upload .custom-file-input input {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}
.custom-file-upload .custom-file-input .info-line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 12px 20px;
  transition: 0.2s ease;
  border: 1px dashed rgba(33, 80, 170, 0.5);
}
.custom-file-upload .custom-file-input .info-line:hover {
  border-color: currentColor;
}
.custom-file-upload .custom-file-input .info-line .name {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000009;
}
.custom-file-upload .custom-file-input .info-line .fake-btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
}
.custom-file-upload .custom-file-input .info-line .fake-btn .text {
  white-space: nowrap;
}
.custom-file-upload .custom-file-input .info-line .fake-btn .icon {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-left: 6px;
}

.inputs-add {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.inputs-add::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  transform: translateY(-50%);
  background-color: #E4EFFF;
}
.inputs-add .button {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  background: #E4EFFF;
  border-radius: 24px;
  font-size: 14px;
  line-height: 22px;
  padding: 4px 16px;
  color: #2150AA;
  transition: 0.2s ease;
}
.inputs-add .button .icon {
  margin-left: 6px;
  width: 16px;
  min-width: 16px;
  height: 16px;
}
.inputs-add .button:hover {
  background: #2150AA;
  color: #FFFFFF;
}

.select-wrapper .tooltip {
  top: 50%;
  transform: translateY(-50%);
}
.select-wrapper .tooltip::after {
  pointer-events: none;
}
.select-wrapper .multiselect.no-label .multiselect__tags, .select-wrapper .multiselect__tags {
  padding-right: 60px;
}

.multiselect {
  cursor: pointer;
  min-width: 192px;
  min-height: 68px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.multiselect::before {
  content: attr(data-label-text);
  display: flex;
  position: absolute;
  top: 10px;
  left: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #6A7378;
  padding-right: 7px;
}
.multiselect[data-required=true]::before {
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.40801 3.174L0.476013 2.04L0.854013 1.368L2.67401 2.614L2.60401 0.5H3.37401L3.30401 2.614L5.12401 1.368L5.50201 2.04L3.57001 3.174L5.50201 4.322L5.12401 4.994L3.30401 3.748L3.37401 5.862H2.60401L2.67401 3.748L0.854013 4.994L0.476013 4.322L2.40801 3.174Z' fill='%23F13B02'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 4.5px 4.5px;
  background-position: 100% 3px;
}
.multiselect__tag {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  color: #000009;
  padding: 4px 20px 4px 0;
  margin-right: 6px;
  margin-bottom: 0;
}
.multiselect__tag-icon::after {
  font-size: 18px;
}
.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background: rgba(0, 0, 0, 0);
}
.multiselect__tag-icon:focus::after, .multiselect__tag-icon:hover::after {
  color: inherit;
}
.multiselect__option--highlight:after, .multiselect__option--selected:after {
  display: none;
}
.multiselect__placeholder {
  padding: 0;
  margin: 0;
}
.multiselect__single {
  padding: 0;
  margin: 0;
}
.multiselect__tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 68px;
  border: 2px solid #D2DADC;
  border-radius: 0;
  padding: 28px 56px 10px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  transition: 0.2s ease;
}
.multiselect__select {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0;
}
.multiselect__select::before {
  background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M3.49951%205.75L7.99951%2010.25L12.4995%205.75%22%20stroke%3D%22%236A7378%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 16px;
  height: 16px;
  border: 0;
  top: 0;
  margin: 0;
}
.multiselect__option {
  width: 100%;
  padding: 10px 18px;
  color: #000009;
}
.multiselect__option:hover, .multiselect__option.multiselect__option--highlight {
  background-color: #F7FAFC;
  color: #2150AA;
}
.multiselect__option.multiselect__option--selected {
  background-color: #F7FAFC;
  color: #6A7378;
}
.multiselect__tags-wrap {
  display: flex;
  flex-wrap: wrap;
}
.multiselect__content-wrapper {
  top: calc(100% + 2px);
  border: 2px solid #D2DADC;
}
.multiselect__content, .multiselect__element {
  max-width: 100%;
}
.multiselect__option {
  max-width: 100%;
  white-space: initial;
}
.multiselect--active::before {
  color: #2150AA;
}
.multiselect--active .multiselect__tags {
  border-color: #2150AA;
}
.multiselect--active .multiselect__select {
  transform: translateY(-50%) rotate(180deg);
}
.multiselect--active .multiselect__select::before {
  background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M3.49951%205.75L7.99951%2010.25L12.4995%205.75%22%20stroke%3D%22%232150AA%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
}
.multiselect.no-label::before {
  display: none;
}
.multiselect.no-label .multiselect__tags {
  padding: 12px 20px;
  min-height: 48px;
}
.multiselect.no-label {
  min-height: 48px;
  min-width: 160px;
}

.multiselect__input, .multiselect__single {
  padding: 0;
  margin: 0;
}

.multiselect, .multiselect__input, .multiselect__single {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.multiselect__input, .multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

select[data-select] {
  display: none;
}

.custom-select {
  position: relative;
  cursor: pointer;
  min-width: 220px;
  user-select: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.custom-select-current {
  background-color: #FFFFFF;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 28px 56px 10px 20px;
  border: 2px solid #D2DADC;
  transition: 0.2s ease;
}
.custom-select-current::before {
  content: attr(data-name);
  position: absolute;
  top: 10px;
  left: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #6A7378;
}
.custom-select-current::after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M3.49951%205.75L7.99951%2010.25L12.4995%205.75%22%20stroke%3D%22%236A7378%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease;
}
.custom-select-options {
  width: 100%;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  max-height: 172px;
  overflow-y: auto;
  transition: 0.3s ease;
  border: 2px solid #D2DADC;
  background-color: #FFFFFF;
  z-index: 2;
  transform: scaleY(0);
  transform-origin: center top;
}
.custom-select-options-item {
  padding: 10px 18px;
  color: #000009;
}
.custom-select-options-item:hover {
  background-color: #F7FAFC;
  color: #2150AA;
}
.custom-select-options-item.selected {
  background-color: #F7FAFC;
  color: #6A7378;
}
.custom-select:focus {
  outline: 0;
}
.custom-select:focus .custom-select-current {
  border-color: #2150AA;
}
.custom-select:focus .custom-select-current::before {
  color: #2150AA;
}
.custom-select.active .custom-select-current {
  border-color: #2150AA;
}
.custom-select.active .custom-select-current::before {
  color: #2150AA;
}
.custom-select.active .custom-select-current::after {
  background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M3.49951%205.75L7.99951%2010.25L12.4995%205.75%22%20stroke%3D%22%232150AA%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  transform: translateY(-50%) rotate(180deg);
}
.custom-select.active .custom-select-options {
  transform: scaleY(1);
}

.multiselect__content-wrapper {
  bottom: unset;
}

.multiselect--above .multiselect__content-wrapper,
.multiselect--below .multiselect__content-wrapper {
  border-bottom: 2px solid #D2DADC;
  border-top: 2px solid #D2DADC;
  border-radius: 0;
}

.multiselect--above .multiselect__content-wrapper {
  top: unset;
  bottom: calc(100% + 2px);
}

.multiselect__strong {
  margin-bottom: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #40434B;
  margin-left: 4px;
}

.multiselect__tag {
  padding: 0 10px;
  border-radius: 24px;
  background-color: #000009;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 22px;
  display: inline-flex;
  align-items: center;
}

.multiselect__tag-icon {
  position: static;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 11.5L4.5 4.5' stroke='%23D2DADC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 4.5L4.5 11.5' stroke='%23D2DADC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-left: 4px;
}
.multiselect__tag-icon::after {
  display: none;
  opacity: 0;
  visibility: hidden;
}
.multiselect__tag-icon:hover {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 11.5L4.5 4.5' stroke='%23D2DADC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 4.5L4.5 11.5' stroke='%23D2DADC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-color: none;
  opacity: 0.8;
}

#app .vdp-datepicker__calendar {
  top: calc(100% + 4px);
  border: 2px solid #D2DADC;
  background-color: #FFFFFF;
  padding: 0 11px 11px;
}
#app .vdp-datepicker__calendar header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D2DADC;
  padding: 16px 0;
  margin-bottom: 16px;
  line-height: 22px;
}
#app .vdp-datepicker__calendar header .prev, #app .vdp-datepicker__calendar header .next {
  width: 16px;
  min-width: 16px;
  height: 16px;
  font-size: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.74951 12.5L10.2495 8L5.74951 3.5' stroke='%236A7378' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
}
#app .vdp-datepicker__calendar header .prev::after, #app .vdp-datepicker__calendar header .next::after {
  display: none;
}
#app .vdp-datepicker__calendar header .prev {
  transform: rotate(180deg);
}
#app .vdp-datepicker__calendar .cell.day-header {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  color: #6A7378;
}
#app .vdp-datepicker__calendar .cell.day {
  padding: 0;
  border-radius: 50%;
}
#app .vdp-datepicker__calendar .cell.day.today {
  font-weight: 700;
}
#app .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, #app .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, #app .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  background-color: rgba(33, 80, 170, 0.2);
}
#app .vdp-datepicker__calendar .cell.selected {
  background-color: #2150AA !important;
  color: #FFFFFF;
}
#app .vdp-datepicker__calendar .cell {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border: 0;
  margin: 3px 4px;
  transition: 0.2s ease;
}
#app .vdp-datepicker__calendar header + div {
  margin: -3px -4px;
}

.qs-datepicker-container {
  min-width: 300px;
  max-width: 300px;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  padding-top: 2px;
  font-family: inherit;
  font-size: 14px;
  line-height: 22px;
}

.qs-datepicker {
  border: 2px solid #D2DADC;
  background-color: #FFFFFF;
  padding: 0 11px;
}

.qs-controls {
  padding: 16px 0;
  background-color: #FFFFFF;
  border-bottom: 1px solid #D2DADC;
}

.qs-squares {
  padding: 16px 0;
  display: grid;
  grid-template-columns: repeat(7, 32px);
  gap: 8px;
}

.qs-square {
  width: 32px;
}

.qs-num {
  height: 32px;
  border-radius: 50%;
  color: #000009;
}

.qs-day {
  text-transform: uppercase;
  color: #6A7378;
  font-weight: 500;
}

.qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
  background-color: rgba(33, 80, 170, 0.1);
}

.qs-active, .qs-range-start, .qs-range-end {
  background-color: #2150AA;
  color: #FFFFFF;
}

.qs-arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.qs-arrow:after {
  position: static;
  top: 0;
  left: 0;
  border: 0;
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.74951 12.5L10.2495 8L5.74951 3.5' stroke='%236A7378' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.qs-arrow:after:hover {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.74951 12.5L10.2495 8L5.74951 3.5' stroke='%23000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.qs-arrow.qs-right:after {
  transform: none;
}

.qs-arrow.qs-left:after {
  transform: rotate(180deg);
}

#app .vdp-datepicker__calendar .cell.month,
#app .vdp-datepicker__calendar .cell.year {
  width: 47%;
}

.cookie-notification {
  position: fixed;
  z-index: 9;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  padding: 18px 24px;
  display: none;
  align-items: center;
  justify-content: space-between;
  max-width: 480px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  background-color: #131F49;
}
.cookie-notification__ok-btn {
  cursor: pointer;
  margin-left: 24px;
  border: 1px solid #EBF3F8;
  border-radius: 36px;
  padding: 5px 16px;
  background-color: rgba(0, 0, 0, 0);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  transition: 0.2s ease;
}
.cookie-notification__ok-btn:hover {
  background-color: rgba(235, 243, 248, 0.2);
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
}
.pagination__item {
  outline: 0;
  text-decoration: none;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #6A7378;
  width: 36px;
  padding-bottom: 2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  transition: 0.2s ease;
}
.pagination__item:not(:last-child) {
  margin-right: 24px;
}
.pagination__item:not(.pagination__item--dots):hover {
  border-color: #6A7378;
}
.pagination__item:focus {
  border-color: #6A7378;
}
.pagination__item.active {
  color: #173583;
  border-color: #173583;
}
.pagination__item--dots {
  pointer-events: none;
}

.toggler-buttons {
  display: flex;
  border-radius: 36px;
  background-color: #FFFFFF;
  box-shadow: inset 0 0 0 1px #EBF3F8;
  position: relative;
}
.toggler-buttons__option {
  position: relative;
  z-index: 2;
  padding: 7px 24px;
  cursor: pointer;
  border-radius: 36px;
  transition: 0.2s ease;
}
.toggler-buttons__option:not(.active):hover {
  background-color: rgba(33, 80, 170, 0.05);
}
.toggler-buttons__option.active {
  color: #FFFFFF;
}
.toggler-buttons::before {
  content: "";
  position: absolute;
  z-index: 1;
  height: 100%;
  border-radius: 36px;
  background-color: #2150AA;
  top: 0;
  left: var(--bg-l);
  width: var(--bg-w);
  transition: 0.2s ease;
}

.modal {
  background-color: rgba(0, 0, 9, 0.8);
  position: fixed;
  display: flex;
  align-items: flex-end;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  transition: 0.2s ease;
  cursor: pointer;
}
@media (min-width: 680px) {
  .modal {
    align-items: center;
    justify-content: center;
  }
}
.modal:not(.active) {
  opacity: 0;
  pointer-events: none;
}
.modal:not(.active) .modal__inner {
  transform: translateY(calc(100% + 14px));
}
.modal__inner {
  cursor: initial;
  background-color: #FFFFFF;
  width: 100%;
  padding: 0 0 24px 0;
  position: relative;
  transition: 0.2s ease;
}
@media (min-width: 680px) {
  .modal__inner {
    max-width: 640px;
    padding: 36px;
  }
}
.modal__inner::before {
  content: "";
  bottom: calc(100% + 10px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  height: 4px;
  width: 120px;
  border-radius: 6px;
  pointer-events: none;
}
@media (min-width: 680px) {
  .modal__inner::before {
    opacity: 0;
  }
}
.modal__content {
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  overflow-x: hidden;
}
.modal__content, .modal__buttons {
  padding-inline: 24px;
  margin-top: 24px;
}
@media (min-width: 680px) {
  .modal__content, .modal__buttons {
    padding: 0;
    margin-top: 0;
  }
  .modal__content + .modal__content, .modal__content + .modal__buttons, .modal__buttons + .modal__content, .modal__buttons + .modal__buttons {
    margin-top: 24px;
  }
}
.modal__title-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  background-color: #F7FAFC;
  padding: 16px 24px;
}
@media (min-width: 680px) {
  .modal__title-line {
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    display: block;
    text-align: initial;
  }
  .modal__title-line--mobile-only {
    display: none;
  }
}
.modal__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000009;
  flex-grow: 1;
  margin-right: -36px;
}
@media (min-width: 680px) {
  .modal__title {
    margin-right: 0;
  }
}
.modal__close-btn {
  width: 36px;
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease;
}
.modal__close-btn:hover {
  opacity: 0.8;
}
.modal__close-btn .icon {
  width: 36px;
  height: 36px;
}
@media (min-width: 680px) {
  .modal__close-btn {
    display: none;
  }
}
.modal__buttons {
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
}
.modal__buttons .btn + .btn {
  margin-bottom: 16px;
}
@media (min-width: 680px) {
  .modal__buttons {
    flex-direction: row;
    justify-content: flex-end;
  }
  .modal__buttons .btn + .btn {
    margin-bottom: 0;
    margin-left: 20px;
  }
  .modal__buttons .btn {
    min-width: unset;
    padding-inline: 24px;
  }
}
.modal__content strong {
  font-weight: 700;
}
.modal__name {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}
@media (min-width: 680px) {
  .modal__name {
    font-size: 20px;
    line-height: 30px;
  }
}

.proposal-modal__text {
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 680px) {
  .proposal-modal__text strong {
    font-size: 20px;
    line-height: 30px;
  }
}
.proposal-modal__select {
  max-width: 120px;
  margin-top: 10px;
}
.proposal-modal__checkbox-toggler {
  display: inline-flex;
  margin-top: 24px;
}
@media (min-width: 680px) {
  .proposal-modal__checkbox-toggler {
    margin-top: 16px;
  }
}
.proposal-modal__checkbox-toggler:first-child {
  margin-top: 0;
}
@media (min-width: 680px) {
  .proposal-modal__checkbox-toggler:first-child {
    margin-top: 12px;
  }
}
.proposal-modal__amount-input-label {
  max-width: 200px;
  margin-top: 24px;
}
.proposal-modal__amount-input-label .input {
  padding-right: 80px;
}
.proposal-modal__amount-input-label::after {
  pointer-events: none;
  content: attr(data-currency);
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 14px;
  line-height: 22px;
  color: #000009;
}

.lawyers-confirm-modal__item + .lawyers-confirm-modal__item {
  margin-top: 36px;
}
@media (min-width: 680px) {
  .lawyers-confirm-modal__item + .lawyers-confirm-modal__item {
    margin-top: 48px;
  }
}
.lawyers-confirm-modal__item {
  transition: opacity 0.2s ease;
}
.lawyers-confirm-modal__item.disabled {
  opacity: 0.15;
  cursor: not-allowed;
}
.lawyers-confirm-modal__item.disabled > * {
  pointer-events: none;
}
.lawyers-confirm-modal__q {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
.lawyers-confirm-modal__q.req::after {
  content: "*";
  color: #F13B02;
}
.lawyers-confirm-modal__q + .lawyers-confirm-modal__hint {
  margin-top: -12px;
}
.lawyers-confirm-modal__hint {
  font-size: 14px;
  line-height: 22px;
  color: #6A7378;
  margin-bottom: 16px;
}
.lawyers-confirm-modal__radios {
  position: relative;
}
.lawyers-confirm-modal__radios-options {
  display: flex;
}
.lawyers-confirm-modal__radios-options .radio + .radio {
  margin-left: 24px;
  margin-top: 0;
}
.lawyers-confirm-modal__radios-not-allowed-msg {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #F13B02;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
}
.lawyers-confirm-modal__expertise-options {
  display: flex;
  flex-wrap: wrap;
  margin-inline: -6px;
  margin-bottom: -12px;
  white-space: nowrap;
}
.lawyers-confirm-modal__expertise-option {
  margin-inline: 6px;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #173583;
  padding: 7px 16px;
  border: 1px solid #EBF3F8;
  border-radius: 36px;
  cursor: pointer;
  transition: 0.2s ease;
}
.lawyers-confirm-modal__expertise-option.active {
  color: #F7FAFC;
  border-color: rgba(0, 0, 0, 0);
  background-color: #131F49;
}
@media (min-width: 680px) {
  .lawyers-confirm-modal__difficulty-items {
    display: grid;
    gap: 14px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.lawyers-confirm-modal__difficulty-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  transition: 0.2s ease;
  border: 1px solid #D2DADC;
  padding: 16px;
}
.lawyers-confirm-modal__difficulty-item.active {
  border-color: rgba(0, 0, 0, 0);
  background-color: #173583;
}
.lawyers-confirm-modal__difficulty-descr {
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #6A7378;
  margin-top: 10px;
  transition: 0.2s ease;
  margin-bottom: auto;
}
.lawyers-confirm-modal__difficulty-time {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000009;
  margin-top: 10px;
  transition: 0.2s ease;
}
.lawyers-confirm-modal__difficulty-item + .lawyers-confirm-modal__difficulty-item:not(.radio--lined) {
  margin-top: 16px;
}
@media (min-width: 680px) {
  .lawyers-confirm-modal__difficulty-item + .lawyers-confirm-modal__difficulty-item:not(.radio--lined) {
    margin-top: 0;
  }
}
.lawyers-confirm-modal__difficulty-item.active input:checked + .radio-name::before {
  box-shadow: inset 0 0 0 7px #FFFFFF;
}
.lawyers-confirm-modal__difficulty-item.active .radio-name, .lawyers-confirm-modal__difficulty-item.active .lawyers-confirm-modal__difficulty-time {
  color: #FFFFFF;
}
.lawyers-confirm-modal__difficulty-item.active .lawyers-confirm-modal__difficulty-descr {
  color: #D2DADC;
}

.header {
  background-color: #FFFFFF;
  position: relative;
  z-index: 9;
}
.body--home .header {
  background-color: rgba(0, 0, 0, 0);
}
.body--internal .header {
  border-bottom: 1px solid #EBF3F8;
}
@media (min-width: 1080px) {
  .body--internal .header {
    border-bottom: 0;
  }
}
.header-content {
  justify-content: space-between;
  height: 80px;
  padding: 12px 0;
}
@media (min-width: 1080px) {
  .header-content {
    height: 152px;
    padding: 0;
  }
}
.body--internal .header-content {
  height: 64px;
}
@media (min-width: 1080px) {
  .body--internal .header-content {
    height: 152px;
  }
}
.header .mobile-open-nav-btn {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("../../img/open-nav-icon.svg");
  width: 24px;
  min-width: 24px;
  height: 24px;
  cursor: pointer;
  transition: 0.2s ease;
}
.header .mobile-open-nav-btn:hover, .header .mobile-open-nav-btn:active, .header .mobile-open-nav-btn:focus {
  opacity: 0.8;
}
.body--common .header .mobile-open-nav-btn {
  background-image: url("../../img/open-nav-icon-black.svg");
}
@media (min-width: 1080px) {
  .header .mobile-open-nav-btn {
    display: none;
  }
}
.header .logo-link {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("../../img/logo_blue_vector.svg");
  display: inline-block;
  width: 110px;
  min-width: 110px;
  height: 80px;
  transition: 0.2s ease;
  margin-right: 130px;
}
.header .logo-link:hover {
  opacity: 0.8;
}
@media (max-width: 1220px) {
  .header .logo-link {
    margin-right: 64px;
  }
}
@media (max-width: 1079px) {
  .header .logo-link {
    margin: 0 24px;
    width: 77px;
    min-width: 77px;
    height: 56px;
  }
}
.body--home .header .logo-link {
  background-image: url("../../img/logo_white_vector.svg");
}
.body--internal .header .logo-link {
  display: none;
}
@media (min-width: 1080px) {
  .body--internal .header .logo-link {
    display: flex;
  }
}
.header .nav, .header .auth {
  margin: 0;
}
@media (min-width: 1080px) {
  .header .nav, .header .auth {
    margin: 0 -6px;
  }
}
.header .nav {
  margin-right: auto;
}
.header .nav .nav-mobile-title-line {
  display: none;
  position: relative;
  text-align: center;
  padding: 16px 24px 16px 24px;
  background-color: #F7FAFC;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000009;
  margin-bottom: 12px;
  width: 100%;
}
.header .nav .nav-mobile-title-line .close {
  position: absolute;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("../../img/nav-close-icon.svg");
  width: 36px;
  min-width: 36px;
  height: 36px;
  top: calc(50% - 18px);
  right: 24px;
  cursor: pointer;
  transition: 0.2s ease;
}
.header .nav .nav-mobile-title-line .close:hover {
  transform: rotate(180deg);
}
.header .nav-item {
  color: #000009;
}
.body--home .header .nav-item {
  color: #FFFFFF;
}
.header .nav-item + .nav-item {
  margin-left: 48px;
}
@media (max-width: 1499px) {
  .header .nav-item + .nav-item {
    margin-left: 24px;
  }
}
@media (max-width: 1220px) {
  .header .nav-item + .nav-item {
    margin-left: 18px;
  }
}
.header .nav-item.current {
  color: #2150AA;
}
@media (max-width: 1079px) {
  .header .nav {
    background-color: #FFFFFF;
    position: fixed;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 320px;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.2s ease;
  }
  .header .nav .nav-mobile-title-line {
    display: block;
  }
  .header .nav .nav-item {
    color: #000009;
    padding: 12px 24px;
    width: 100%;
  }
  .header .nav .nav-item:hover, .header .nav .nav-item:active, .header .nav .nav-item:focus {
    background-color: #f2f2f2;
  }
  .header .nav .nav-item + .nav-item {
    margin-left: 0;
  }
  .header .nav::before {
    content: "";
    position: fixed;
    top: 0;
    left: 320px;
    bottom: 0;
    width: calc(100% - 320px);
    z-index: 8;
    background-color: rgba(0, 0, 9, 0.7);
  }
  .header .nav:not(.active) {
    transform: translateX(-100%);
  }
}
.header .auth-item {
  font-weight: 600;
  color: #6A7378;
}
.body--home .header .auth-item {
  color: rgba(255, 255, 255, 0.5);
}
.body--internal .header .auth-item:nth-child(1) {
  display: none;
}
@media (min-width: 1080px) {
  .body--internal .header .auth-item:nth-child(1) {
    display: flex;
  }
}
.body--common .header .auth-item:nth-child(1) {
  background-image: url("../../img/login-icon-black.svg");
}
@media (min-width: 1080px) {
  .body--common .header .auth-item:nth-child(1) {
    background-image: none;
  }
}
.header .auth-item + .auth-item {
  margin-left: 42px;
}
@media (max-width: 1499px) {
  .header .auth-item + .auth-item {
    margin-left: 24px;
  }
}
@media (max-width: 1220px) {
  .header .auth-item + .auth-item {
    margin-left: 18px;
  }
}
@media (max-width: 1079px) {
  .header .auth-item:nth-child(2) {
    display: none;
  }
  .header .auth-item:nth-child(1) {
    font-size: 0;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url("../../img/login-icon.svg");
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
}
.header .nav-item, .header .auth-item {
  text-decoration: none;
  outline: 0;
  font-size: 16px;
  line-height: 24px;
  transition: 0.2s ease;
  padding: 6px;
}
.header .nav-item:hover, .header .auth-item:hover {
  opacity: 0.8;
}
.header .nav-item:focus, .header .auth-item:focus {
  box-shadow: 0 0 0 3px rgba(33, 80, 170, 0.25);
}
.header .notifications-button {
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease;
  color: #40434B;
}
.body--home .header .notifications-button {
  color: #FFFFFF;
}
.body--internal .header .notifications-button {
  margin-left: 0;
}
@media (min-width: 1080px) {
  .body--internal .header .notifications-button {
    margin-left: 66px;
  }
}
@media (min-width: 1500px) {
  .header .notifications-button {
    margin-left: 66px;
  }
}
.header .notifications-button:hover {
  opacity: 0.8;
}
.header .notifications-button .icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
}
.header--error-page {
  position: relative;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  width: max-content;
  margin-inline: auto;
}
@media (min-width: 768px) {
  .header--error-page {
    margin-inline: 0;
  }
}
.header--error-page .logo-link {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("../../img/logo-sapphire.svg");
  width: 216px;
  height: 157px;
}

.header-mobile-user-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000009;
  padding: 0 24px;
  text-align: center;
}
@media (min-width: 1080px) {
  .header-mobile-user-name {
    display: none;
  }
}

.mobile-back-btn {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4375 18.75L4.6875 12L11.4375 5.25' stroke='%23000009' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.625 12H19.3125' stroke='%23000009' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
@media (min-width: 1080px) {
  .mobile-back-btn {
    display: none;
  }
}

.footer {
  width: 100%;
  background-color: #000009;
  margin-top: auto;
}
.footer .footer-top-content {
  padding-right: 60px;
  padding-top: 64px;
  padding-bottom: 74px;
}
@media (max-width: 1079px) {
  .footer .footer-top-content {
    display: block;
    padding: 15px 0 36px;
  }
}
.footer .footer-top-column {
  color: #FFFFFF;
}
@media (max-width: 1079px) {
  .footer .footer-top-column {
    padding: 14px 0 12px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);
  }
}
.footer .footer-top-column .name {
  margin-bottom: 27px;
  font-weight: normal;
  font-size: 30px;
  line-height: 34px;
}
@media (max-width: 1079px) {
  .footer .footer-top-column .name {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    position: relative;
    padding-right: 36px;
    transition: 0.2s ease;
    cursor: pointer;
  }
  .footer .footer-top-column .name::after, .footer .footer-top-column .name::before {
    content: "";
    position: absolute;
    right: 10px;
    top: calc(50% - .75px);
    transform-origin: center center;
    width: 13.5px;
    height: 1.5px;
    border-radius: 0.5px;
    background-color: #FFFFFF;
    transition: 0.2s ease;
  }
  .footer .footer-top-column .name::after {
    transform: rotate(90deg);
  }
}
.footer .footer-top-column .items {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 1079px) {
  .footer .footer-top-column .items {
    max-height: 0;
    transition: 0.2s ease;
    overflow: hidden;
    padding-left: 16px;
  }
}
.footer .footer-top-column .items .link {
  text-decoration: none;
  color: inherit;
  transition: 0.2s ease;
}
.footer .footer-top-column .items .link:hover {
  opacity: 0.8;
}
.footer .footer-top-column .items .link:focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}
.footer .footer-top-column .items .link.tel-link {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.footer .footer-top-column .items > *:not(:last-child) {
  margin-bottom: 16px;
}
@media (max-width: 1079px) {
  .footer .footer-top-column.show-links .name {
    color: rgba(255, 255, 255, 0.5);
  }
  .footer .footer-top-column.show-links .name::after {
    transform: rotate(360deg);
  }
  .footer .footer-top-column.show-links .name::before {
    transform: rotate(180deg);
  }
  .footer .footer-top-column.show-links .name::before, .footer .footer-top-column.show-links .name::after {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .footer .footer-top-column.show-links .items {
    max-height: 280px;
    margin-top: 24px;
    margin-bottom: 12px;
  }
}
.footer .footer-bottom {
  background-color: rgba(106, 115, 120, 0.1);
}
.footer .footer-bottom-content {
  padding: 24px 60px 24px 0;
  color: #FFFFFF;
}
.footer .footer-bottom .logo-link {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("../../img/logo-sapphire.svg");
  display: inline-block;
  width: 66px;
  min-width: 66px;
  height: 48px;
  transition: 0.2s ease;
  margin-right: 36px;
}
.footer .footer-bottom .logo-link:hover {
  opacity: 0.8;
}
.footer .footer-bottom .copy {
  margin-right: auto;
}
.footer .footer-bottom .dev-by a {
  text-decoration: none;
  color: #F19202;
  transition: 0.2s ease;
}
.footer .footer-bottom .dev-by a:hover {
  opacity: 0.9;
}
.footer .footer-bottom .dev-by a:focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(241, 146, 2, 0.25);
}
.footer--error-page {
  position: relative;
  z-index: 1;
  margin-top: auto;
  background-color: rgba(0, 0, 0, 0);
}
.footer--error-page .footer-links {
  width: 100%;
}
.footer--error-page .footer-links__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #000009;
  text-align: center;
}
@media (min-width: 768px) {
  .footer--error-page .footer-links__title {
    text-align: left;
  }
}
.footer--error-page .footer-links__items {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
  text-align: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .footer--error-page .footer-links__items {
    text-align: left;
    justify-content: flex-start;
  }
}
.footer--error-page .footer-links__link {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: #2150AA;
}
.footer--error-page .footer-links__link:hover {
  text-decoration: none;
}
.footer--internal {
  display: none;
  background-color: #FFFFFF;
}
@media (min-width: 1080px) {
  .footer--internal {
    display: block;
  }
}
.footer--internal a {
  text-decoration: none;
  transition: 0.2s ease;
}
.footer--internal .footer__line {
  padding: 24px 0;
}
.footer--internal .footer__line:not(:last-child) {
  border-bottom: 1px solid rgba(106, 115, 120, 0.1);
}
.footer--internal .footer__link {
  font-size: 14px;
  line-height: 22px;
  color: #000009;
}
.footer--internal .footer__link:not(:first-child) {
  margin-left: 24px;
}
.footer--internal .footer__link:hover {
  color: #2150AA;
}
.footer--internal .footer__email {
  margin-right: 36px;
  font-size: 14px;
  line-height: 22px;
  color: #2150AA;
}
.footer--internal .footer__email:hover {
  color: #173583;
}
.footer--internal .footer__phone {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000009;
}
.footer--internal .footer__phone:hover {
  color: #173583;
}
.footer--internal .footer__copy {
  font-size: 14px;
  line-height: 22px;
}
.footer--internal .footer__back-to-top {
  cursor: pointer;
  margin-right: 36px;
  color: #000009;
  transform: rotate(180deg);
  transition: 0.2s ease;
}
.footer--internal .footer__back-to-top:hover {
  opacity: 0.8;
}
.footer--internal .footer__back-to-top, .footer--internal .footer__back-to-top svg {
  width: 16px;
  height: 16px;
}

.notifications {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  z-index: 99;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease;
}
.notifications__inner {
  max-width: 100%;
  width: 100%;
  height: calc(100% - 92px);
  background-color: #FFFFFF;
  transition: 0.2s ease;
  transform: translateY(calc(100% + 14px));
  margin-top: auto;
}
@media (min-width: 680px) {
  .notifications__inner {
    max-width: 480px;
    height: 100vh;
    transform: translateX(100%);
    margin-top: 0;
  }
}
.notifications__inner::after {
  content: "";
  bottom: calc(100% + 10px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  height: 4px;
  width: 120px;
  border-radius: 6px;
  pointer-events: none;
}
@media (min-width: 680px) {
  .notifications__inner::after {
    opacity: 0;
  }
}
.notifications.active {
  opacity: 1;
  pointer-events: all;
}
.notifications.active .notifications__inner {
  transition-delay: 0.2s;
  transform: translateX(0);
}
@media (min-width: 680px) {
  .notifications.active .notifications__inner {
    transform: translatey(0);
  }
}
.notifications__title-line {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 24px;
  background-color: #F7FAFC;
}
@media (min-width: 680px) {
  .notifications__title-line {
    padding: 30px 36px;
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 1px solid #D2DADC;
  }
}
.notifications__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000009;
  width: 100%;
}
@media (min-width: 680px) {
  .notifications__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: left;
    width: auto;
  }
}
.notifications__close {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  min-width: 36px;
  height: 36px;
  color: #6A7378;
  transition: 0.2s ease;
  cursor: pointer;
}
.notifications__close:hover {
  color: #000009;
}
.notifications__close:active {
  transform: scale(1.075) translateY(-50%);
}
@media (min-width: 680px) {
  .notifications__close {
    position: static;
    transform: translateY(0);
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
  .notifications__close:active {
    transform: scale(1.075);
  }
}
.notifications__delete-all {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #2150AA;
  width: max-content;
  margin: 15px auto 6px auto;
  padding: 10px 0;
  cursor: pointer;
  transition: 0.2s ease;
}
.notifications__delete-all:hover {
  color: #131F49;
}
@media (min-width: 680px) {
  .notifications__delete-all {
    margin: 12px 36px 12px auto;
  }
}
.notifications__items {
  padding: 0 24px 48px 24px;
  max-height: calc(100% - 156px);
  overflow: auto;
}
@media (min-width: 680px) {
  .notifications__items {
    padding: 0 36px 48px 36px;
  }
}

.notification + .notification {
  margin-top: 32px;
  position: relative;
}
@media (min-width: 680px) {
  .notification + .notification {
    margin-top: 36px;
  }
}
.notification + .notification::before {
  content: "";
  position: absolute;
  top: -16px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #EBF3F8;
}
@media (min-width: 680px) {
  .notification + .notification::before {
    display: none;
  }
}

.notification__date-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.notification__icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 10px;
}
.notification__date-time {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #40434B;
}
.notification__delete {
  margin-left: auto;
  width: 16px;
  min-width: 16px;
  height: 16px;
  color: #6A7378;
  cursor: pointer;
  transition: 0.2s ease;
}
.notification__delete:hover {
  color: #173583;
}
.notification__text {
  font-size: 16px;
  line-height: 24px;
  color: #000009;
}
.notification__text strong {
  font-weight: 600;
}
.notification__buttons {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notification__buttons .btn + .btn {
  margin-left: 21px;
}
.notification__buttons .btn {
  padding: 6px 16px;
  min-width: unset;
}
@media (min-width: 680px) {
  .notification__buttons .btn:first-of-type {
    margin-left: auto;
  }
}
@media (min-width: 680px) {
  .notification__buttons {
    justify-content: initial;
  }
}
.notification__deadline {
  font-size: 14px;
  line-height: 22px;
  color: #40434B;
}
.notification__deadline strong {
  font-weight: 500;
  color: #000009;
}

.block {
  display: block;
}

.i-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.i-flex {
  display: inline-flex;
}

.flex.fdc, .i-flex.fdc {
  flex-direction: column;
}
.flex.aic, .i-flex.aic {
  align-items: center;
}
.flex.aife, .i-flex.aife {
  align-items: flex-end;
}
.flex.jcc, .i-flex.jcc {
  justify-content: center;
}
.flex.jcfe, .i-flex.jcfe {
  justify-content: flex-end;
}
.flex.jcsb, .i-flex.jcsb {
  justify-content: space-between;
}
.flex.aifs, .i-flex.aifs {
  align-items: flex-start;
}
.flex.ais, .i-flex.ais {
  align-items: stretch;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.fix {
  position: fixed;
}

.text-center {
  text-align: center;
}

.vertical-center {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.w100 {
  width: 100%;
}

.half-width {
  width: 50%;
}

.pointer-events-none {
  pointer-events: none;
}

.bg-icon {
  display: inline-block;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-image-cover {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.mb-auto {
  margin-bottom: auto;
}

.lowercase {
  text-transform: lowercase;
}

.caps {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

[data-svg-icons] {
  width: 0;
  height: 0;
}

.svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.svg-icon svg {
  width: 100%;
  height: 100%;
}

.m-center {
  margin: 0 auto;
}

.ml-a {
  margin-left: auto;
}

.mr-a {
  margin-right: auto;
}

.mb-a {
  margin-bottom: auto;
}

.mt-a {
  margin-top: auto;
}

.bg-black {
  background-color: #000009;
}

.bg-ebony {
  background-color: #40434B;
}

.bg-cadetgray {
  background-color: #6A7378;
}

.bg-lightgray {
  background-color: #D2DADC;
}

.bg-alabaster {
  background-color: #EBF3F8;
}

.bg-snow {
  background-color: #F7FAFC;
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-spacecadet {
  background-color: #131F49;
}

.bg-kleinblue {
  background-color: #173583;
}

.bg-sapphire {
  background-color: #2150AA;
}

.bg-beaublue {
  background-color: #E4EFFF;
}

.bg-carrot {
  background-color: #F19202;
}

.bg-melon {
  background-color: #FFF3E8;
}

.bg-keppel {
  background-color: #29BDAB;
}

.bg-lightcyan {
  background-color: #D0F5EA;
}

.bg-coquelicot {
  background-color: #F13B02;
}

.bg-mistyrose {
  background-color: #FFECE8;
}

.color-black {
  color: #000009;
}

.color-ebony {
  color: #40434B;
}

.color-cadetgray {
  color: #6A7378;
}

.color-lightgray {
  color: #D2DADC;
}

.color-alabaster {
  color: #EBF3F8;
}

.color-snow {
  color: #F7FAFC;
}

.color-white {
  color: #FFFFFF;
}

.color-spacecadet {
  color: #131F49;
}

.color-kleinblue {
  color: #173583;
}

.color-sapphire {
  color: #2150AA;
}

.color-beaublue {
  color: #E4EFFF;
}

.color-carrot {
  color: #F19202;
}

.color-melon {
  color: #FFF3E8;
}

.color-keppel {
  color: #29BDAB;
}

.color-lightcyan {
  color: #D0F5EA;
}

.color-coquelicot {
  color: #F13B02;
}

.color-mistyrose {
  color: #FFECE8;
}

/*font-family: 'Cabin', sans-serif;*/
/*font-family: 'Forum', cursive;*/
#app {
  display: flex;
  flex-direction: column;
}

.body {
  display: flex;
  flex-direction: column;
  font-size: 100%;
  background-color: #F7FAFC;
  overflow-x: hidden;
  color: #000009;
  transition: 0.3s ease;
}
.body.modal-opened {
  overflow: hidden;
}
.body.top-notification-opened {
  overflow: hidden;
}
@media (min-width: 1024px) {
  .body.top-notification-opened {
    overflow: auto;
    overflow-x: hidden;
    margin-top: 72px;
  }
}

.container {
  max-width: 1456px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}
.container--thin {
  max-width: 976px;
}

.body--internal .container {
  --internal-container-width: 100%;
  max-width: 100%;
  padding: 0 20px;
}
@media (min-width: 580px) {
  .body--internal .container {
    --internal-container-width: 540px;
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .body--internal .container {
    --internal-container-width: 760px;
    max-width: 760px;
  }
}
@media (min-width: 992px) {
  .body--internal .container {
    --internal-container-width: 952px;
    max-width: 952px;
  }
}
@media (min-width: 1200px) {
  .body--internal .container {
    --internal-container-width: 1140px;
    max-width: 1140px;
  }
}
@media (min-width: 1200px) {
  .body--internal .container {
    --internal-container-width: 1140px;
    max-width: 1140px;
  }
}
@media (min-width: 1500px) {
  .body--internal .container {
    --internal-container-width: 1456px;
    max-width: 1456px;
  }
}

.ui-testing-wrapper {
  min-height: 100vh;
  display: grid;
  place-items: center;
  padding: 36px 10px;
}

.ui-test-case-labels .status + .status {
  margin-left: 12px;
}

.ui-testing-custom-select {
  min-width: 280px;
}

.ui-testing-icons {
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  padding: 36px 10px;
}
.ui-testing-icons .icons {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  gap: 24px;
}
.ui-testing-icons .svg-icon {
  width: 24px;
  height: 24px;
}

.ui-testing-datepicker {
  padding: 36px 10px;
  min-width: 320px;
}

.ui-testing-radio-checkboxes {
  padding: 36px 10px;
}

.ui-testing-inputs {
  width: 100%;
  max-width: 320px;
  padding: 36px 10px;
}

.ui-testing-file-input {
  width: 100%;
  max-width: 320px;
  padding: 36px 10px;
}

.ui-testing-button {
  padding: 36px 0;
}
.ui-testing-button .btn + .btn {
  margin-left: 24px;
}
.ui-testing-button .flex + .flex {
  margin-top: 12px;
}

.body--error {
  padding: 24px 0;
  background-color: #E4EFFF;
  overflow-x: hidden;
}
@media (min-width: 768px) {
  .body--error {
    padding: 64px 0;
  }
}
.body--error .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.main--error-page {
  padding: 64px 0;
  position: relative;
  z-index: 0;
  width: 100%;
}
.main--error-page::before {
  content: attr(data-error-code);
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #FFFFFF;
  z-index: -1;
  font-size: 180px;
  line-height: 180px;
}
@media (min-width: 768px) {
  .main--error-page::before {
    top: -141px;
    font-size: 800px;
    line-height: 800px;
  }
}

.error__text {
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #000009;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}
@media (min-width: 768px) {
  .error__text {
    text-align: left;
    font-size: 36px;
    line-height: 48px;
  }
}
.error__btn {
  margin-top: 24px;
  display: block;
  width: max-content;
  margin-inline: auto;
}
@media (min-width: 768px) {
  .error__btn {
    display: inline-block;
  }
}

.advantages.swiper-container {
  overflow: visible;
}
.advantages .swiper-wrapper {
  transition-duration: 200ms !important;
}
.advantages--noslider {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}
@media (max-width: 1400px) {
  .advantages--noslider {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
  }
}
.advantages__item {
  color: #FFFFFF;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.advantages__item--with-bg-image {
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.advantages__item--text-black {
  color: #000009;
}
.advantages--noslider .advantages__item {
  padding: 36px;
}
.advantages__name {
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 16px;
}
.advantages__name--bigger {
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 12px;
}
.advantages__text {
  font-family: "Cabin", sans-serif;
  font-size: 20px;
  line-height: 30px;
}

.reviews {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  gap: 24px;
}
@media (max-width: 1199px) {
  .reviews {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
  }
}
.reviews__item {
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: 384px;
}
.reviews__item::before {
  content: attr(data-letter);
  font-size: 180px;
  line-height: 180px;
  color: #173583;
  opacity: 0.1;
  position: absolute;
  top: 16px;
  left: 36px;
}
.reviews__name {
  font-size: 32px;
  line-height: 40px;
  color: #40434B;
}
.reviews__date {
  font-size: 14px;
  line-height: 22px;
  color: #6A7378;
  margin-bottom: 10px;
  margin-top: auto;
}
.reviews__text {
  font-size: 16px;
  line-height: 24px;
  color: #000009;
}

.page-title--common {
  font-weight: 600;
  font-size: 56px;
  line-height: 70px;
  color: #000009;
}

.page-title-line {
  margin-bottom: 48px;
  margin-top: 32px;
}
.page-title-line .how-it-works {
  font-size: 16px;
  line-height: 24px;
  color: #2150AA;
  text-decoration: underline;
}
.page-title-line .how-it-works:hover {
  text-decoration: none;
}

.main--upload .how-it-works {
  display: none;
}
@media (min-width: 768px) {
  .main--upload .how-it-works {
    display: inline-block;
  }
}

.page-descr {
  font-size: 24px;
  line-height: 36px;
  max-width: 936px;
}

.section__title {
  font-weight: 600;
  font-size: 56px;
  line-height: 70px;
  color: #000009;
  margin-bottom: 24px;
}
.section__title--small {
  font-size: 44px;
  line-height: 50px;
}
.section__title--forum-font {
  font-weight: normal;
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 12px;
}
@media (max-width: 1199px) {
  .section__title--forum-font {
    font-size: 45px;
    line-height: 50px;
  }
}
.section__descr {
  font-size: 24px;
  line-height: 36px;
  color: #6A7378;
}
.section__small-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #40434B;
}

.section--welcome {
  display: flex;
  margin-top: -152px;
  position: relative;
  z-index: 0;
}
@media (max-width: 1079px) {
  .section--welcome {
    margin-top: -80px;
  }
}
.section--welcome__video {
  max-width: 39%;
  min-width: 39%;
  position: relative;
}
.section--welcome__video .inner {
  position: relative;
  width: 100%;
  padding-top: 106%;
  height: 100%;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("../img/welcome-video-preview.jpg");
  background-color: #131F49;
}
.section--welcome__video .play-btn {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("../img/play-btn-blur.svg");
  cursor: pointer;
  width: 122px;
  height: 122px;
  transition: 0.2s ease;
  backdrop-filter: blur(15px);
  border-radius: 50%;
  background-blend-mode: normal, luminosity;
}
.section--welcome__video .play-btn:hover {
  opacity: 0.9;
}
.section--welcome__text {
  flex-grow: 1;
  background-color: #2150AA;
  padding: 152px 96px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 1079px) {
  .section--welcome__text {
    padding: 164px 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.section--welcome__title {
  font-weight: 600;
  font-size: 56px;
  line-height: 70px;
  color: #F19202;
}
@media (max-width: 1079px) {
  .section--welcome__title {
    font-size: 48px;
    line-height: 60px;
  }
}
.section--welcome__subtitle {
  font-weight: bold;
  font-size: 44px;
  line-height: 50px;
  color: #FFFFFF;
}
.section--welcome__subtitle span {
  opacity: 0.5;
}
@media (max-width: 1079px) {
  .section--welcome__subtitle {
    font-size: 36px;
    line-height: 40px;
  }
}
.section--welcome__descr {
  margin-top: 24px;
  font-size: 20px;
  line-height: 30px;
  color: #D2DADC;
}
@media (max-width: 1079px) {
  .section--welcome__descr {
    font-size: 18px;
    line-height: 24px;
  }
}
.section--welcome__buttons {
  margin-top: 48px;
}
@media (max-width: 1079px) {
  .section--welcome__buttons {
    margin-top: 36px;
  }
}
.section--welcome__buttons .btn {
  width: 251px;
}
.section--welcome__buttons .btn + .btn {
  margin-left: 36px;
}

.section--questions {
  padding: 192px 0 160px;
}
@media (max-width: 1199px) {
  .section--questions {
    padding: 96px 0;
  }
}
.section--questions__columns {
  display: grid;
  grid-template-columns: 480px auto 384px;
  margin-left: -48px;
}
@media (max-width: 1400px) {
  .section--questions__columns {
    margin-left: -36px;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1199px) {
  .section--questions__columns {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    margin-left: 0;
  }
}
.section--questions__col {
  padding: 48px;
  color: #000009;
}
.section--questions__col--with-background {
  color: #FFFFFF;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
@media (max-width: 1400px) {
  .section--questions__col {
    padding: 24px 36px;
  }
}
@media (max-width: 1199px) {
  .section--questions__col {
    width: 50%;
  }
  .section--questions__col:nth-child(3) {
    width: 100%;
    margin-top: 24px;
  }
}
@media (max-width: 1079px) {
  .section--questions__col {
    width: 100%;
  }
}
.section--questions__title {
  font-size: 48px;
  line-height: 56px;
  padding-bottom: 24px;
  border-bottom: 2px solid currentColor;
  margin-bottom: 24px;
}
.section--questions__descr {
  font-size: 20px;
  line-height: 30px;
}
.section--questions__descr p + p {
  margin-top: 24px;
}
.section--questions__image {
  margin-top: 24px;
}
.section--questions__subitems {
  margin: 24px 0;
}
.section--questions__subitem:not(:last-child) {
  margin-bottom: 24px;
}
.section--questions__subitem .name {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #173583;
}
.section--questions__subitem .text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #40434B;
}
.section--questions__buttons {
  margin-top: 24px;
}
.section--questions__buttons .btn + .btn {
  margin-left: 24px;
}

.section--why {
  padding: 120px 0;
  background-color: #EBF3F8;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .section--why {
    padding: 96px 0;
  }
}
.section--why__advantages {
  margin-top: 48px;
}
.section--why__advantages .advantages__item {
  height: 392px;
  width: 100%;
}
.section--why__slider-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.section--why__slider-navigation .swiper-pagination-bullet {
  background-color: rgba(0, 0, 9, 0.25);
  border-radius: 48px;
  width: 16px;
  min-width: 16px;
  height: 2px;
  opacity: 1;
  transition: 0.2s ease;
}
.section--why__slider-navigation .swiper-pagination-bullet-active {
  width: 48px;
  min-width: 48px;
  background-color: #2150AA;
}

.section--reviews {
  padding: 240px 0 200px;
}
@media (max-width: 1199px) {
  .section--reviews {
    padding: 96px 0;
  }
}
.section--reviews--page {
  padding: 0;
  margin-bottom: 200px;
}
@media (max-width: 1199px) {
  .section--reviews--page {
    padding: 0;
  }
}
.section--reviews__more, .section--reviews__more--mobile {
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2150AA;
  white-space: nowrap;
}
.section--reviews__more .svg-icon, .section--reviews__more--mobile .svg-icon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}
.section--reviews__more:hover, .section--reviews__more--mobile:hover {
  color: #102855;
}
.section--reviews__reviews {
  margin-top: 48px;
}
.section--reviews__more--mobile {
  display: none;
  justify-content: center;
}

.what-is {
  margin-bottom: 240px;
  display: grid;
  grid-template-columns: 776px 1fr;
  gap: 64px;
}
@media (max-width: 1400px) {
  .what-is {
    grid-template-columns: 45% 1fr;
  }
}
@media (max-width: 1079px) {
  .what-is {
    display: block;
    margin-bottom: 90px;
  }
}
.what-is__name {
  display: inline-block;
  font-size: 48px;
  line-height: 56px;
  color: #2150AA;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 2px solid #173583;
}
.what-is__descr {
  font-size: 24px;
  line-height: 36px;
  color: #40434B;
}
.what-is__buttons {
  margin-top: 24px;
}
.what-is__buttons .btn + .btn {
  margin-left: 18px;
}
@media (max-width: 1079px) {
  .what-is__media {
    margin-top: 24px;
  }
}
.what-is__media .video {
  cursor: pointer;
  position: relative;
}
.what-is__media .video::after {
  content: "";
  background: url("../img/play-btn-blur.svg"), linear-gradient(0deg, rgba(249, 249, 249, 0.05), rgba(249, 249, 249, 0.05)), rgba(249, 249, 249, 0.3);
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 122px;
  height: 122px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s ease;
  z-index: 1;
  backdrop-filter: blur(15px);
  border-radius: 50%;
  background-blend-mode: normal, luminosity;
}
.what-is__media .video:hover::after {
  opacity: 0.8;
}

[data-mediation-scrollmagic-wrapper] {
  margin-bottom: 90px;
}
@media (min-width: 1080px) {
  [data-mediation-scrollmagic-wrapper] {
    margin-bottom: 240px;
  }
}

.section--advantages--page {
  overflow-x: hidden;
  margin-bottom: 90px;
}
@media (min-width: 1080px) {
  .section--advantages--page {
    margin-bottom: 240px;
  }
}
.section--advantages--page .swiper-container {
  overflow: initial;
}
.section--advantages--page .swiper-wrapper {
  transition-duration: 200ms !important;
}
.section--advantages--page__title {
  margin-bottom: 22px;
}
.section--advantages--page__descr {
  margin-bottom: 48px;
  max-width: 696px;
}
.section--advantages--page .advantages__item {
  min-height: 536px;
}
.section--advantages--page .advantages--mediation .advantages__item {
  min-height: 346px;
  min-width: 336px;
  width: 336px;
}
.section--advantages--page__buttons {
  margin-top: 48px;
}
.section--advantages--page__button .svg-icon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

@media (max-width: 1400px) {
  .section--advantages--arbitration {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
}
.section--how-it-works {
  margin-bottom: 120px;
}
.section--how-it-works .section__title {
  margin-bottom: 64px;
}
.section--how-it-works__tab-content:not(.active) {
  display: none;
}
.section--how-it-works__tab-titles {
  --liner-width: 0px;
  --liner-left-offset: 0px;
  margin-bottom: 64px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #D2DADC;
  position: relative;
}
.section--how-it-works__tab-titles::after {
  content: "";
  position: absolute;
  top: 100%;
  left: var(--liner-left-offset);
  height: 2px;
  width: var(--liner-width);
  background-color: #2150AA;
  transition: left 0.2s ease, width 0.4s ease;
}
.section--how-it-works__tab-title {
  padding: 20px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #40434B;
  transition: 0.2s ease;
  cursor: pointer;
}
.section--how-it-works__tab-title:not(:last-child) {
  margin-right: 48px;
}
.section--how-it-works__tab-title:hover {
  color: rgba(33, 80, 170, 0.8);
}
.section--how-it-works__tab-title.active {
  color: #2150AA;
}
.section--how-it-works a {
  color: #2150AA;
}
.section--how-it-works a:hover {
  text-decoration: none;
}
.section--how-it-works__steps {
  counter-reset: steps;
  max-width: 936px;
  width: 100%;
  margin: 0 auto;
}
.section--how-it-works__step {
  counter-increment: steps;
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  line-height: 30px;
  color: #000009;
}
.section--how-it-works__step::before {
  content: "Step " counter(steps);
  width: 240px;
  min-width: 240px;
  font-size: 24px;
  line-height: 36px;
  color: #173583;
  text-transform: uppercase;
}
.section--how-it-works__step:not(:last-child) {
  margin-bottom: 120px;
}
.section--how-it-works blockquote {
  padding: 24px;
  background-color: #EBF3F8;
  color: #2150AA;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
}
.section--how-it-works blockquote a {
  color: inherit;
}
.section--how-it-works blockquote a:hover {
  text-decoration: none;
}
.section--how-it-works h2, .section--how-it-works h3, .section--how-it-works h4, .section--how-it-works h5, .section--how-it-works h6 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #173583;
  margin-top: 24px;
}
.section--how-it-works p:not(.bigger-font) {
  font-size: 16px;
  line-height: 24px;
  margin-top: 4px;
  color: #40434B;
}
.section--how-it-works p + p {
  margin-top: 24px;
}
.section--how-it-works blockquote + p, .section--how-it-works blockquote + p:not(.bigger-font) {
  margin-top: 24px;
}
.section--how-it-works__scenarios {
  margin-top: 24px;
  counter-reset: scenarios;
}
.section--how-it-works__scenarios + p {
  margin-top: 24px;
}
.section--how-it-works__scenario {
  counter-increment: scenarios;
  font-size: 16px;
  line-height: 24px;
  color: #40434B;
}
.section--how-it-works__scenario::before {
  content: "Scenario " counter(scenarios);
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 4px;
  color: #173583;
}
.section--how-it-works__scenario:not(:last-child) {
  margin-bottom: 24px;
}

.section--legal-info {
  background-color: #D2DADC;
  padding: 120px;
  margin-bottom: 120px;
}
@media (max-width: 1199px) {
  .section--legal-info {
    padding: 90px;
  }
}
@media (max-width: 1079px) {
  .section--legal-info {
    padding: 48px 36px;
  }
}
.section--legal-info__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto auto;
  gap: 24px 84px;
}
@media (max-width: 1199px) {
  .section--legal-info__items {
    gap: 24px 36px;
  }
}
.section--legal-info__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 2px solid #000009;
  padding: 24px 0 22px 0;
  color: #000009;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s ease;
}
.section--legal-info__item:hover {
  color: #40434B;
}
.section--legal-info__item .svg-icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-left: 24px;
}

.section--still-q {
  margin-bottom: 104px;
}
.section--still-q__links {
  margin-top: 12px;
}
.section--still-q__link {
  padding: 12px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2150AA;
  text-decoration: none;
  transition: 0.2s ease;
}
.section--still-q__link:hover {
  opacity: 0.8;
}
.section--still-q__link .svg-icon {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-left: 10px;
}

.section--lawyers {
  margin: 48px 0 200px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  gap: 24px;
}
@media (max-width: 1199px) {
  .section--lawyers {
    grid-template-columns: minmax(0, 1fr);
  }
}
.section--lawyers__item {
  padding: 36px;
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 24px;
  background-color: #FFFFFF;
}
@media (max-width: 1199px) {
  .section--lawyers__item {
    align-items: center;
  }
}
.section--lawyers__name {
  font-size: 30px;
  line-height: 34px;
  color: #173583;
  margin-bottom: 16px;
}
.section--lawyers__domains {
  font-size: 16px;
  line-height: 24px;
  color: #6A7378;
  margin-bottom: 16px;
}
.section--lawyers__descr {
  font-size: 14px;
  line-height: 22px;
  color: #000009;
}

.section--faq {
  margin-top: 8px;
  margin-bottom: 200px;
}
.section--faq__tab-select-mobile {
  display: none;
  margin-bottom: 26px;
}
.section--faq__tab-select-mobile .custom-select {
  min-width: 272px;
}
.section--faq__tab-select-mobile .custom-select-current {
  padding: 16px 56px 16px 20px;
  font-size: 16px;
  line-height: 24px;
}
.section--faq__tab-titles {
  margin-bottom: 24px;
  display: flex;
  position: relative;
  border-bottom: 2px solid #D2DADC;
}
.section--faq__tab-titles::before {
  content: "";
  position: absolute;
  height: 2px;
  width: var(--liner-width);
  top: 100%;
  left: var(--liner-left-offset);
  background-color: #2150AA;
  transition: left 0.2s ease, width 0.4s ease;
}
.section--faq__tab-title {
  padding: 16px 0 14px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #40434B;
  transition: 0.2s ease;
  cursor: pointer;
}
.section--faq__tab-title:not(:last-child) {
  margin-right: 48px;
}
.section--faq__tab-title:hover {
  color: rgba(33, 80, 170, 0.8);
}
.section--faq__tab-title.active {
  color: #2150AA;
}
.section--faq__tab-content:not(.active) {
  display: none;
}
.section--faq__q {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000009;
}
.section--faq__q::after {
  content: "";
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 7.875V28.125' stroke='%23000009' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M28.125 18H7.875' stroke='%23000009' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  width: 36px;
  min-width: 36px;
  height: 36px;
  transition: 0.2s ease;
}
.section--faq__a {
  color: #000009;
  font-size: 16px;
  line-height: 24px;
  max-height: 0px;
  overflow: hidden;
  transition: 0.2s ease;
  transform: scaleY(0);
}
.section--faq__item {
  padding: 36px;
  border-bottom: 2px solid rgba(210, 218, 220, 0.5);
  transition: 0.2s ease;
}
.section--faq__item.active {
  background-color: #FFFFFF;
}
.section--faq__item.active .section--faq__q {
  color: #2150AA;
}
.section--faq__item.active .section--faq__q::after {
  transform: rotate(45deg);
}
.section--faq__item.active .section--faq__a {
  max-height: 2000px;
  transform: scaleY(1);
  margin-top: 36px;
}

.upload-how {
  margin-bottom: 240px;
}
.upload-how--home {
  margin-bottom: 0;
  padding-top: 240px;
}
@media (max-width: 1199px) {
  .upload-how--home {
    padding-top: 96px;
  }
}
.upload-how__title {
  text-align: center;
}
@media (min-width: 1200px) {
  .upload-how__title {
    text-align: left;
  }
}
.upload-how__descr {
  margin-bottom: 48px;
  text-align: center;
}
@media (min-width: 1200px) {
  .upload-how__descr {
    text-align: left;
  }
}
.upload-how__cols {
  justify-content: space-between;
  padding: 48px 24px;
  gap: 24px;
  background-color: #D2DADC;
  width: max-content;
  max-width: 100%;
  margin-inline: auto;
}
@media (min-width: 768px) {
  .upload-how__cols {
    padding: 96px 48px;
  }
}
@media (min-width: 1200px) {
  .upload-how__cols {
    display: flex;
    width: 100%;
  }
}
@media (min-width: 1500px) {
  .upload-how__cols {
    padding: 120px 64px;
  }
}
.upload-how__col {
  max-width: 360px;
  width: 100%;
}
.upload-how__col + .upload-how__col {
  margin-top: 36px;
}
@media (min-width: 1200px) {
  .upload-how__col + .upload-how__col {
    margin-top: 0;
  }
}
.upload-how__name {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.5px;
  padding-bottom: 16px;
  border-bottom: 2px solid #000009;
}
@media (min-width: 768px) {
  .upload-how__name {
    font-size: 36px;
    line-height: 48px;
  }
}
.upload-how__text {
  font-size: 16px;
  line-height: 24px;
  padding-top: 16px;
}
.upload-how__buttons {
  margin-top: 36px;
}
.upload-how__buttons .btn + .btn {
  margin-left: 24px;
}

.contract-clauses {
  max-width: 936px;
  width: 100%;
  margin-inline: auto;
  margin-bottom: 240px;
}
.contract-clauses__hint {
  padding: 24px;
  font-size: 16px;
  line-height: 24px;
}
.contract-clauses__items {
  margin-top: 48px;
}
.contract-clauses__item {
  background-color: #FFFFFF;
  padding: 24px;
}
.contract-clauses__name {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 16px;
}
.contract-clauses__text {
  font-size: 16px;
  line-height: 24px;
}
.contract-clauses__buttons {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.contract-clauses__buttons > * + * {
  margin-left: 16px;
}
.contract-clauses__buttons .btn {
  min-width: 143px;
}
.contract-clauses__item + .contract-clauses__item {
  margin-top: 24px;
}
@media (min-width: 768px) {
  .contract-clauses__item + .contract-clauses__item {
    margin-top: 48px;
  }
}

.contract-templates {
  margin-bottom: 240px;
}
.contract-templates__title {
  margin-bottom: 18px;
}
.contract-templates__items {
  display: block;
  max-width: 100%;
  width: max-content;
  margin-inline: auto;
}
@media (min-width: 1080px) {
  .contract-templates__items {
    display: flex;
    width: auto;
  }
}
.contract-templates__item {
  color: #000009;
  text-decoration: none;
  display: flex;
  align-items: center;
  min-width: 336px;
  padding: 18px 0;
  border-bottom: 2px solid currentColor;
  cursor: pointer;
  transition: 0.2s ease;
}
.contract-templates__item:hover {
  color: #2150AA;
}
.contract-templates__icon {
  width: 36px;
  min-width: 36px;
  height: 36px;
  margin-right: 10px;
}
.contract-templates__name {
  margin-right: 10px;
}
.contract-templates__arrow {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-left: auto;
}
.contract-templates__item + .contract-templates__item {
  margin-top: 36px;
}
@media (min-width: 1080px) {
  .contract-templates__item + .contract-templates__item {
    margin-left: 84px;
    margin-top: 0;
  }
}

.peek {
  padding: 36px;
  margin-bottom: 120px;
}
@media (min-width: 1200px) {
  .peek {
    padding: 120px;
  }
}
.peek__title {
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
  .peek__title {
    font-size: 48px;
    line-height: 56px;
  }
}
.peek__descr {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  opacity: 0.5;
  margin-bottom: 30px;
}

.work-with-us {
  display: grid;
  grid-template-columns: 1fr 576px;
  gap: 64px;
  margin-bottom: 200px;
}
@media (max-width: 1199px) {
  .work-with-us {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
@media (max-width: 1199px) {
  .work-with-us__image {
    order: -1;
    margin-bottom: 24px;
    max-width: 768px;
  }
}
.work-with-us__text {
  font-size: 24px;
  line-height: 36px;
  color: #000009;
  margin-bottom: 36px;
}
.work-with-us__text p + p {
  margin-top: 36px;
}
.work-with-us__button-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #131F49;
  margin-bottom: 24px;
}

.terms {
  margin-bottom: 200px;
}
.terms__title, .terms h2, .terms h3, .terms h4, .terms h5, .terms h6 {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #173583;
  margin-bottom: 16px;
}
.terms p + p {
  font-size: 14px;
  line-height: 22px;
  margin-top: 22px;
}
p + .terms__title, .terms p + h2, .terms p + h3, .terms p + h4, .terms p + h5, .terms p + h6 {
  margin-top: 64px;
}

.auth-page {
  padding: 120px 0 140px;
  min-height: 100vh;
  background-color: #2150AA;
  color: #FFFFFF;
}
.auth-page__layout {
  display: block;
  grid-template-columns: 1fr 576px;
  gap: 36px;
}
@media (min-width: 1080px) {
  .auth-page__layout {
    display: grid;
  }
}
.auth-page__logo-link {
  width: 216px;
  height: 157px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("../img/logo_white_vector.svg");
  display: block;
  margin: 0 auto 48px auto;
}
@media (min-width: 1080px) {
  .auth-page__logo-link {
    margin: 0 0 64px 0;
  }
}
.auth-page__title {
  font-weight: bold;
  font-size: 44px;
  line-height: 50px;
  color: #F19202;
  margin-bottom: 10px;
}
.auth-page__subtitle {
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: -0.5px;
  color: #FFFFFF;
  margin-bottom: 24px;
}
.auth-page__subtitle span {
  opacity: 0.5;
  display: inline-block;
}
.auth-page__descr {
  font-size: 16px;
  line-height: 24px;
  color: #D2DADC;
}
.auth-page__content {
  color: #000009;
  background-color: #FFFFFF;
  padding: 48px;
  max-width: 576px;
  margin: 0 auto;
}
@media (min-width: 1080px) {
  .auth-page__content {
    margin: 0;
    max-width: initial;
  }
}
@media (max-width: 1079px) {
  .auth-page__title, .auth-page__subtitle, .auth-page__descr {
    display: none;
  }
}

.auth__tab-titles {
  --liner-width: 0px;
  --liner-left-offset: 0px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
@media (min-width: 1080px) {
  .auth__tab-titles {
    justify-content: initial;
  }
}
.auth__tab-titles::after {
  content: "";
  position: absolute;
  top: 100%;
  left: var(--liner-left-offset);
  height: 2px;
  width: var(--liner-width);
  background-color: #2150AA;
  transition: left 0.2s ease, width 0.4s ease;
}
.auth__tab-title {
  padding: 0 0 14px;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: #40434B;
  transition: 0.2s ease;
  cursor: pointer;
}
.auth__tab-title:not(:last-child) {
  margin-right: 48px;
}
.auth__tab-title:hover {
  color: rgba(33, 80, 170, 0.8);
}
.auth__tab-title.active {
  color: #2150AA;
}
.auth__non-tab-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .auth__non-tab-title {
    font-size: 36px;
    line-height: 48px;
  }
}
.auth__tab-content:not(.active) {
  display: none;
}
.auth__descr {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .auth__descr {
    margin-bottom: 48px;
  }
}
.auth__input-label + .auth__input-label {
  margin-top: 24px;
}
.auth__submit-line {
  margin-top: 24px;
}
.auth__terms-checkbox {
  margin-right: 24px;
}
.auth__forgot {
  font-size: 16px;
  line-height: 24px;
  color: #131F49;
}
.auth__forgot:hover {
  text-decoration: none;
}
.auth__who {
  margin-bottom: 24px;
}
.auth__who .name {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #6A7378;
  margin-bottom: 10px;
}
.auth__who .radio + .radio {
  margin-left: 70px;
}
.auth__inputs-line {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.auth__inputs-line .auth__input-label {
  margin-top: 0;
}
.auth__inputs-line + .auth__input-label {
  margin-top: 24px;
}
.auth__remembered {
  display: block;
  width: max-content;
  order: 99;
  margin-inline: auto;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: #131F49;
}
.auth__remembered:hover {
  text-decoration: none;
}
@media (min-width: 500px) {
  .auth__remembered {
    order: 0;
    margin-top: 0;
    margin-inline: 0;
  }
}

@media (min-width: 1080px) {
  .internal-layout {
    display: grid;
    align-items: start;
    grid-template-columns: 336px minmax(0, 1fr);
    gap: 24px;
    margin-top: 32px;
    margin-bottom: 200px;
  }
}
.internal-layout__sidebar, .internal-layout__content {
  background-color: #FFFFFF;
  padding: 24px;
}
@media (min-width: 1080px) {
  .internal-layout__sidebar, .internal-layout__content {
    padding: 36px;
  }
}
.internal-layout__sidebar {
  display: none;
}
@media (min-width: 1080px) {
  .internal-layout__sidebar {
    display: block;
  }
}
.internal-layout__content {
  min-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  margin-bottom: 66px;
}
@media (min-width: 1080px) {
  .internal-layout__content {
    min-height: unset;
    display: block;
    margin-bottom: unset;
  }
}
.internal-layout__content.personal-info .internal-page-title {
  display: none;
}
@media (min-width: 1080px) {
  .internal-layout__content.personal-info .internal-page-title {
    display: block;
  }
}
.internal-layout .new-cases--empty {
  flex-grow: 1;
}
@media (min-width: 1080px) {
  .internal-layout .new-cases--empty {
    flex-grow: initial;
  }
}
@media (min-width: 1200px) {
  .internal-layout__add {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 200px;
    gap: 60px;
  }
}
@media (min-width: 1500px) {
  .internal-layout__add {
    grid-template-columns: minmax(0, 1fr) 300px;
  }
}
.internal-layout__add-sidebar {
  display: none;
  position: relative;
}
.internal-layout__add-sidebar-inner {
  position: sticky;
  top: 0;
  z-index: 3;
}
@media (min-width: 1200px) {
  .internal-layout__add-sidebar {
    display: block;
  }
}
.internal-layout__buttons {
  margin-top: 24px;
}
.internal-layout > *:last-child {
  align-self: stretch;
}

.add-new__steps, .award__steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 48px;
}
.add-new__steps.no-padding-top, .award__steps.no-padding-top {
  padding-top: 0;
}
.add-new__step, .award__step {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 24px;
  color: #6A7378;
  transition: 0.2s ease;
  padding: 8px 0;
}
.add-new__step::before, .award__step::before {
  content: "";
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #D2DADC;
  margin-right: 8px;
  position: relative;
  z-index: 2;
}
.add-new__step:not(:last-child)::after, .award__step:not(:last-child)::after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #D2DADC;
  position: absolute;
  left: 7px;
  top: 50%;
  z-index: 1;
}
.add-new__step.done, .award__step.done {
  color: #000009;
}
.add-new__step.done::before, .award__step.done::before {
  background-color: #29BDAB;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.75 3L4.5 9L2.25 6.75' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-size: 12px 12px;
  background-position: 50%;
  background-repeat: no-repeat;
}
.add-new__step.done:not(:last-child)::after, .award__step.done:not(:last-child)::after {
  background-color: #29BDAB;
}

.add-form__preview, .award__preview {
  margin-top: 16px;
  border: 2px solid #000009;
  position: relative;
  cursor: pointer;
}
.add-form__preview::before, .add-form__preview::after, .award__preview::before, .award__preview::after {
  position: absolute;
}
.add-form__preview::before, .award__preview::before {
  z-index: 1;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 9, 0.4);
  opacity: 0;
  transition: 0.2s ease;
}
.add-form__preview::after, .award__preview::after {
  z-index: 2;
  content: "Preview";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s ease;
  opacity: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2150AA;
  border: 1px solid #D2DADC;
  background-color: #FFFFFF;
  border-radius: 48px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 36px;
}
.add-form__preview:hover::before, .add-form__preview:hover::after, .award__preview:hover::before, .award__preview:hover::after {
  opacity: 1;
}

.sidebar__profile {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #EBF3F8;
}
.sidebar__profile .name {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000009;
}
.sidebar__profile .edit {
  font-size: 16px;
  line-height: 24px;
  color: #2150AA;
  transition: 0.2s ease;
  text-decoration: none;
}
.sidebar__profile .edit:hover {
  color: #131F49;
}
.sidebar__add-new-btn {
  width: 100%;
  margin-bottom: 10px;
}
.sidebar__nav .link {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #000009;
  transition: 0.2s ease;
  display: block;
  padding: 16px;
  text-decoration: none;
}
.sidebar__nav .link:hover {
  color: rgba(33, 80, 170, 0.7);
}
.sidebar__nav .link.active {
  color: #2150AA;
}
.sidebar__nav .link.active::before {
  content: "";
  width: 4px;
  min-width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #2150AA;
  position: absolute;
  left: 0;
  top: calc(50% - 2px);
}
.sidebar__nav .link--sign-out {
  margin-top: 17px;
}
.sidebar__nav .link--sign-out::before {
  content: "";
  position: absolute;
  top: -9px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #EBF3F8;
}

.internal-page-descr {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000009;
  margin-bottom: 16px;
}
.internal-page-descr--type2 {
  font-weight: normal;
  margin-bottom: 24px;
}

.title-line {
  margin-bottom: 24px;
}
.title-line .title {
  margin-bottom: 0;
}
.title-line__back-link {
  display: none;
  text-decoration: none;
  transition: 0.2s ease;
  margin-right: 12px;
  width: 36px;
  min-width: 36px;
  height: 36px;
  cursor: pointer;
  color: #000009;
}
.title-line__back-link:hover {
  opacity: 0.7;
}
@media (min-width: 1080px) {
  .title-line__back-link {
    display: flex;
  }
}

.platform-hint {
  background-color: #EBF3F8;
  color: #173583;
  margin-bottom: 24px;
  padding: 16px;
  font-size: 14px;
  line-height: 22px;
}
@media (min-width: 768px) {
  .platform-hint {
    padding: 24px;
    font-size: 16px;
    line-height: 24px;
  }
}

.new-cases--empty {
  height: 100%;
}
.new-cases--empty .internal-page-title,
.new-cases--empty .internal-page-descr {
  margin-bottom: 0;
}
.new-cases--empty .internal-page-descr {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.new-cases--empty .empty-icon {
  margin-bottom: 12px;
  color: #E4EFFF;
}
.new-cases--empty .btn--add-new-case {
  margin-top: 16px;
  width: 216px;
}
.new-cases--add .title-line {
  margin-bottom: 0;
}
@media (min-width: 1080px) {
  .new-cases--add .title-line {
    margin-bottom: 24px;
  }
}
.new-cases--add .title-line .internal-page-title {
  margin-bottom: 0;
}
.new-cases--add .title-line .new-cases__save-as-draft-btn {
  min-width: 125px;
}
.new-cases--add .choose__descr {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6A7378;
  margin-bottom: 16px;
}
@media (min-width: 600px) {
  .new-cases--add .choose__descr {
    font-size: 20px;
    line-height: 30px;
  }
}
.new-cases--add .choose__radio {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.new-cases--add .choose__option-label {
  display: block;
}
.new-cases--add .choose__option-content {
  display: block;
  background-color: #F7FAFC;
  padding: 24px;
  border-bottom: 1px solid #131F49;
  cursor: pointer;
  position: relative;
  transition: background-color 300ms ease-out;
}
@media (min-width: 375px) {
  .new-cases--add .choose__option-content {
    padding: 36px 36px 50px 36px;
  }
}
.new-cases--add .choose__option-content::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #131F49;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 300ms ease-out;
  z-index: 0;
}
.new-cases--add .choose__icon {
  position: relative;
  z-index: 1;
  display: block;
  width: 48px;
  height: 48px;
  margin-bottom: 29px;
  color: #131F49;
  transition: color 300ms ease-out;
}
.new-cases--add .choose__option-input {
  position: absolute;
  width: 0;
  height: 0;
}
.new-cases--add .choose__option-name {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  color: #000009;
  transition: color 300ms ease-out;
}
.new-cases--add .choose__option-name::before {
  content: "";
  display: none;
  width: 24px;
  height: 24px;
  box-shadow: inset 0 0 0 2px #D2DADC;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
  transition: box-shadow 300ms ease-out;
  margin-right: 12px;
}
@media (min-width: 600px) {
  .new-cases--add .choose__option-name::before {
    display: block;
  }
}
.new-cases--add .choose__option-input:checked + .choose__option-content::before {
  transform: scaleY(1);
}
.new-cases--add .choose__option-input:checked + .choose__option-content .choose__icon {
  color: #29BDAB;
}
.new-cases--add .choose__option-input:checked + .choose__option-content .choose__option-name {
  color: #FFFFFF;
}
.new-cases--add .choose__option-input:checked + .choose__option-content .choose__option-name::before {
  box-shadow: inset 0 0 0 7px #FFFFFF;
}
.new-cases--add .continue-line {
  margin-top: 24px;
}
.new-cases--add .continue-line .btn {
  width: 100%;
}
@media (min-width: 600px) {
  .new-cases--add .continue-line .btn {
    width: auto;
  }
}
.new-cases--add .continue-line .btn + .btn {
  margin-top: 24px;
}
@media (min-width: 600px) {
  .new-cases--add .continue-line .btn + .btn {
    margin-top: 0;
    margin-left: 24px;
  }
}

.add-form__section-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000009;
  margin-bottom: 16px;
}
.add-form__item + .add-form__section-title {
  margin-top: 96px;
}
.add-form .mt {
  margin-top: 24px;
}
.add-form .mt-big, .add-form .add-form__item.mt-big {
  margin-top: 64px;
}
.add-form .mb {
  margin-bottom: 24px;
}
.add-form .mb-big {
  margin-bottom: 64px;
}
.add-form .single-half {
  position: relative;
}
@media (min-width: 600px) {
  .add-form .single-half {
    width: calc(50% - 12px);
  }
}
.add-form .single-half .remove-added-line {
  position: absolute;
  top: 50%;
  left: calc(100% + 24px);
  transform: translateY(-50%);
}
.add-form .inputs-line {
  display: block;
}
.add-form .inputs-line > * + * {
  margin-top: 16px;
}
@media (min-width: 600px) {
  .add-form .inputs-line {
    display: grid;
  }
  .add-form .inputs-line > * + * {
    margin-top: 0;
  }
}
.add-form .add-form__checkbox-switch + .add-form__checkbox-switch {
  margin-top: 16px;
}
.add-form__claymant-info {
  margin: 64px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #D2DADC;
}
.add-form__claymant-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000009;
  margin-bottom: 10px;
}
.add-form__claymant-line {
  font-size: 16px;
  line-height: 24px;
}
.add-form__claymant-name {
  color: #000009;
}
.add-form__claymant-addr {
  color: #40434B;
}
.add-form__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000009;
  margin-bottom: 16px;
}
.add-form__subtitle {
  word-break: break-word;
  margin-bottom: 16px;
}
.add-form__subtitle.req::after {
  content: "*";
  color: #F13B02;
}
.add-form__item {
  display: flex;
  flex-direction: column;
}
.add-form__item + .add-form__item {
  margin-top: 24px;
}
.add-form .continue-line {
  margin-top: 46px;
  flex-direction: column-reverse;
}
.add-form .continue-line > * + * {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .add-form .continue-line {
    flex-direction: row;
  }
  .add-form .continue-line > * + * {
    margin-bottom: 0;
  }
}
.add-form .continue-line .save-draft-btn {
  display: none;
}
@media (min-width: 1080px) {
  .add-form .continue-line .save-draft-btn {
    display: inline-block;
  }
}
@media (min-width: 1080px) {
  .add-form .continue-line .mobile-preview-btn {
    display: none;
  }
}

.inputs-add + .add-form__item {
  margin-top: 24px;
}

.claim-modal .vm--modal {
  background-color: rgba(0, 0, 0, 0);
}
@media (min-width: 1080px) {
  .claim-modal .vm--modal {
    background-color: white;
  }
}

.statement-preview {
  max-height: calc(100% - 68px);
  max-width: 100%;
  overflow: auto;
  padding: 24px;
}
.statement-preview * {
  max-width: 100%;
}
@media (min-width: 1080px) {
  .statement-preview {
    overflow: initial;
    max-height: initial;
    max-width: 764px;
    padding: 48px 64px;
  }
}

.claim-modal__inner {
  color: #000009;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100% - 92px);
  background-color: #FFFFFF;
}
@media (min-width: 1080px) {
  .claim-modal__inner {
    overflow: auto;
    position: relative;
    height: 100%;
    padding: 48px calc((100% - 960px) / 2);
  }
}
.claim-modal__inner::before {
  content: "Claim preview";
  position: absolute;
  height: 68px;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-inline: 84px;
  background-color: #F7FAFC;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 1080px) {
  .claim-modal__inner::before {
    display: none;
  }
}
.claim-modal__inner::after {
  content: "";
  bottom: calc(100% + 10px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  height: 4px;
  width: 120px;
  border-radius: 6px;
  pointer-events: none;
}
@media (min-width: 1080px) {
  .claim-modal__inner::after {
    display: none;
  }
}
.claim-modal__inner .close {
  position: absolute;
  width: 36px;
  height: 36px;
  color: #6A7378;
  transition: 0.2s ease;
  cursor: pointer;
  top: 16px;
  right: 24px;
}
.claim-modal__inner .close .svg-icon {
  width: 100%;
  height: 100%;
}
.claim-modal__inner .close:hover {
  color: #000009;
  transform: rotate(180deg);
}
@media (min-width: 1080px) {
  .claim-modal__inner .close {
    position: fixed;
    top: 48px;
    right: calc((100% - 960px) / 2);
  }
}
.claim-modal__inner h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 16px;
}
.claim-modal__inner h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}
.claim-modal__inner h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
.claim-modal__inner p {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
}
.claim-modal__inner h4, .claim-modal__inner p {
  padding-left: 36px;
}
.claim-modal__inner cite {
  display: block;
  padding-left: 60px;
  font-size: 14px;
  line-height: 22px;
  color: #6A7378;
  padding-bottom: 16px;
}

.personal-info__subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .personal-info__subtitle {
    margin-bottom: 24px;
  }
}
* + .personal-info__subtitle {
  margin-top: 36px;
}
.personal-info__lawyer-hint + .personal-info__subtitle {
  margin-top: 0;
}
.personal-info__lawyer-hint {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .personal-info__lawyer-hint {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
}
.personal-info__form {
  max-width: 480px;
  width: 100%;
}
.personal-info__inputs-line + .personal-info__inputs-line, .personal-info__inputs-line + .personal-info__input-label, .personal-info__input-label + .personal-info__inputs-line, .personal-info__input-label + .personal-info__input-label, .personal-info__input-label + .multiselect, .multiselect + .personal-info__input-label, .personal-info__input-label + .input-label, .input-label + .personal-info__input-label {
  margin-top: 16px;
}
@media (min-width: 600px) {
  .personal-info__inputs-line + .personal-info__inputs-line, .personal-info__inputs-line + .personal-info__input-label, .personal-info__input-label + .personal-info__inputs-line, .personal-info__input-label + .personal-info__input-label, .personal-info__input-label + .multiselect, .multiselect + .personal-info__input-label, .personal-info__input-label + .input-label, .input-label + .personal-info__input-label {
    margin-top: 24px;
  }
}
@media (min-width: 600px) {
  .personal-info__inputs-line {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
}
@media (min-width: 600px) {
  .personal-info__inputs-line .personal-info__input-label, .personal-info__inputs-line .input-label, .personal-info__inputs-line .multiselect {
    margin: 0;
  }
}
.personal-info__submit-line {
  margin-top: 24px;
  flex-direction: column-reverse;
}
.personal-info__submit-line > * + * {
  margin-bottom: 28px;
}
.personal-info__submit-line > * {
  width: 100%;
  text-align: center;
}
@media (min-width: 600px) {
  .personal-info__submit-line {
    flex-direction: row;
  }
  .personal-info__submit-line > * + * {
    margin-bottom: 0;
  }
  .personal-info__submit-line > * {
    width: unset;
  }
}
.personal-info__change-password {
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6A7378;
  transition: 0.2s ease;
}
.personal-info__change-password:hover {
  color: #131F49;
}
.personal-info__save {
  min-width: 130px;
}
.personal-info--complete-registration .personal-info__save {
  min-width: 185px;
  margin-left: auto;
}

.case + .cases__who-title {
  margin-top: 24px;
}

.case {
  text-decoration: none;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #D2DADC;
  display: grid;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  gap: 10px;
}
.case--new, .case--ongoing, .case--closed {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 20px;
  grid-template-areas: "number names notes" "status status status" "deadline date date";
}
@media (min-width: 1500px) {
  .case--new, .case--ongoing, .case--closed {
    grid-template-columns: 104px 224px 220px 200px 1fr 20px;
    grid-template-areas: "number names status deadline date notes";
    gap: 20px;
  }
}
.case--draft {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 16px;
  grid-template-areas: "number names names" "status date trash";
}
@media (min-width: 1500px) {
  .case--draft {
    grid-template-columns: 104px 224px 220px 200px 1fr 16px;
    grid-template-areas: "number names status . date trash";
    gap: 20px;
  }
}
.case__number {
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  color: #2150AA;
  grid-area: number;
}
.case__number a {
  text-decoration: none;
  color: inherit;
}
.case__names {
  font-size: 14px;
  line-height: 22px;
  color: #000009;
  grid-area: names;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
@media (min-width: 1500px) {
  .case__names {
    text-align: left;
  }
}
.case__status {
  justify-self: start;
  grid-area: status;
}
.case__deadline {
  font-size: 14px;
  line-height: 22px;
  color: #40434B;
  grid-area: deadline;
}
.case__deadline span {
  font-weight: 500;
  color: #000009;
}
.case__date {
  font-size: 14px;
  line-height: 22px;
  color: #6A7378;
  grid-area: date;
  text-align: right;
}
@media (min-width: 1500px) {
  .case__date {
    text-align: left;
  }
}
.case__contract-type {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #6A7378;
  grid-area: status;
}
.case__notes {
  grid-area: notes;
}
.case__notes .count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #F19202;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.case__additional {
  grid-area: additional;
}
.case__trash {
  grid-area: trash;
}

.internal-page-title.new-cases__title {
  display: none;
}
@media (min-width: 1200px) {
  .internal-page-title.new-cases__title {
    display: block;
  }
}

.new-cases__toggler {
  margin-inline: auto;
  margin-bottom: 16px;
}
@media (min-width: 1200px) {
  .new-cases__toggler {
    margin: 0;
  }
}

.status {
  padding: 4px 16px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 30px;
}
.status--waiting {
  background-color: #FFF3E8;
  color: #F19202;
}
.status--assigned {
  background-color: #D0F5EA;
  color: #29BDAB;
}
.status--won {
  background-color: #E4EFFF;
  color: #2150AA;
}
.status--lost {
  background-color: #FFECE8;
  color: #F13B02;
}
.status--draft {
  background-color: #EBF3F8;
  color: #6A7378;
}

.case-page__box {
  padding: 12px;
  margin: -6px -12px;
  background-color: #F7FAFC;
  flex-grow: 1;
}
.case-page__split {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 60px;
  display: block;
}
@media (min-width: 1500px) {
  .case-page__split {
    display: grid;
  }
}
@media (max-width: 1499px) {
  .case-page__split.active-col-0 > *:nth-child(2) {
    display: none;
  }
  .case-page__split.active-col-1 > *:nth-child(1) {
    display: none;
  }
}
@media (min-width: 1500px) {
  .case-page__split {
    gap: 120px;
  }
}
.case-page__split.bb:not(:last-child) .case-page__splitted-col {
  padding-bottom: 16px;
  border-bottom: 1px solid #D2DADC;
}
.case-page__split .case-page__evidence-file {
  word-break: break-all;
  white-space: normal;
  max-width: calc(100% - 120px);
  width: max-content;
}
.case-page__details-mobile-toggler {
  width: max-content;
  margin-inline: auto;
  margin-bottom: 16px;
}
@media (min-width: 1500px) {
  .case-page__details-mobile-toggler {
    display: none;
  }
}
.case-page__split .case-page__subitem {
  margin-top: 0;
  border-bottom: 0;
}
.case-page__topline {
  width: 100%;
  margin-bottom: 24px;
  display: block;
}
.case-page__topline > * + * {
  margin-top: 10px;
  width: max-content;
}
@media (min-width: 768px) and (max-width: 1079px), (min-width: 1200px) {
  .case-page__topline {
    display: flex;
  }
  .case-page__topline > * + * {
    margin-top: 0;
    width: auto;
  }
}
.case-page__date {
  font-size: 14px;
  line-height: 22px;
  color: #6A7378;
  margin-right: auto;
}
.case-page__pre-status {
  font-size: 14px;
  line-height: 22px;
  margin-right: 24px;
  color: #40434B;
}
.case-page__status {
  margin-right: 36px;
}
.case-page__deadline {
  font-size: 14px;
  line-height: 22px;
  color: #40434B;
}
.case-page__item + .case-page__item {
  margin-top: 64px;
}
.case-page__subitem + .case-page__subitem {
  margin-top: 24px;
}
.case-page__item, .case-page__subitem {
  padding-bottom: 16px;
  border-bottom: 1px solid #D2DADC;
}
.case-page__subitem:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.case-page__title {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  color: #173583;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .case-page__title {
    font-size: 24px;
    line-height: 36px;
  }
}
.case-page__title--togglable {
  position: relative;
  cursor: pointer;
}
.case-page__title--togglable:hover {
  opacity: 0.8;
}
.case-page__title--togglable::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.125 18H7.875' stroke='%23173583' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 10px;
  transition: 0.3s ease;
}
@media (min-width: 768px) {
  .case-page__title--togglable::before {
    width: 36px;
    min-width: 36px;
    height: 36px;
    margin-right: 12px;
  }
}
.case-page__title--togglable::after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 28.125L18 7.875' stroke='%23173583' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 24px;
  min-width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.3s ease;
}
@media (min-width: 768px) {
  .case-page__title--togglable::after {
    width: 36px;
    min-width: 36px;
    height: 36px;
  }
}
.case-page__item.active .case-page__title--togglable::before {
  transform: rotate(-180deg);
}
.case-page__item.active .case-page__title--togglable::after {
  transform: rotate(-270deg);
}
.case-page__split + .case-page__split {
  margin-top: 24px;
}
.case-page__subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000009;
  margin-bottom: 12px;
}
.case-page__mini-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6A7378;
  margin-bottom: 6px;
}
.case-page__descr {
  font-size: 14px;
  line-height: 22px;
  color: #000009;
}
.case-page__parties {
  position: relative;
  display: block;
}
@media (min-width: 1200px) {
  .case-page__parties {
    display: grid;
  }
}
.case-page__parties-item {
  pointer-events: none;
}
.case-page__parties-item > * {
  pointer-events: all;
}
.case-page__parties-item:nth-child(2) {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
@media (min-width: 1200px) {
  .case-page__parties-item:nth-child(2) {
    position: static;
  }
}
.case-page__parties-item:nth-child(2) .case-page__parties-title {
  width: calc(50% - 24px);
  margin-left: auto;
}
@media (min-width: 1200px) {
  .case-page__parties-item:nth-child(2) .case-page__parties-title {
    width: auto;
  }
}
.case-page__parties-person {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  align-items: center;
  margin-bottom: 10px;
}
@media (min-width: 1200px) {
  .case-page__parties-person {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}
.case-page__parties-name {
  font-size: 16px;
  line-height: 24px;
  color: #000009;
  min-width: 118px;
  margin-right: 24px;
}
.case-page__parties-role {
  font-size: 16px;
  line-height: 24px;
  color: #40434B;
}
.case-page__parties-role a, .case-page__parties-role .email {
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  transition: 0.2s ease;
  color: #2150AA;
}
.case-page__parties-role a:hover {
  opacity: 0.8;
}
.case-page__parties-details {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  align-items: center;
}
@media (min-width: 1200px) {
  .case-page__parties-details {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}
.case-page__parties-title {
  transition: 0.2s ease;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .case-page__parties-title {
    cursor: default;
  }
}
.case-page__parties-title:not(.active) {
  color: #6A7378;
}
@media (min-width: 1200px) {
  .case-page__parties-title:not(.active) {
    color: #000009;
  }
}
.case-page__parties-title.active + .case-page__parties-person, .case-page__parties-title.active + .case-page__parties-person + .case-page__parties-details {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.case-page__mini-split {
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(0, max-content);
  gap: 24px;
}
@media (min-width: 768px) {
  .case-page__mini-split {
    grid-template-columns: minmax(180px, max-content) minmax(180px, max-content);
  }
}
* + .case-page__mini-title {
  margin-top: 24px;
}
.case-page__subtitle + .case-page__mini-title {
  margin-top: 0;
}
.case-page__evidence {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 1500px) {
  .case-page__evidence {
    flex-wrap: nowrap;
  }
}
.case-page__evidence.flex.fdc.aifs .case-page__evidence-type {
  margin: 12px 0;
}
.case-page__evidence-type {
  width: 100%;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 22px;
  color: #40434B;
}
@media (min-width: 1500px) {
  .case-page__evidence-type {
    width: auto;
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.case-page__mini-split {
  margin-top: 24px;
}
.case-page__splitted-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.case-page__splitted-col:nth-child(2) .case-page__box {
  background-color: #EBF3F8;
}
.case-page__subitem + .case-page__split {
  margin-top: 24px;
}
.case-page__general-info {
  display: flex;
  flex-wrap: wrap;
  gap: 36px 15px;
}
.case-page__general-info-item {
  width: 25%;
}
.case-page__general-info-item--w50 {
  width: calc((100% - 15px) / 2);
}
.case-page__general-info-item--w100 {
  width: 100%;
}
.case-page__general-info-value div + div {
  margin-top: 10px;
}
.case-page__general-info-value--flex {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.case-page__general-info-value--flex > span {
  display: inline-block;
  width: calc((100% - 10px) / 2);
}
@media (min-width: 1200px) {
  .case-page__general-info-value--flex > span {
    width: calc((100% - 30px) / 4);
  }
}
@media (min-width: 1500px) {
  .case-page__general-info-value--flex > span {
    width: calc((100% - 40px) / 5);
  }
}
.case-page__description {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #6A7378;
  margin-bottom: 12px;
}
.case-page__keywords-items {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14px;
  gap: 10px 12px;
}
.case-page__keywords-item {
  padding: 5px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #40434B;
  background-color: #EBF3F8;
  border-radius: 36px;
  text-align: center;
}
.case-page__keywords-add {
  max-width: 624px;
}

.filename {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: #40434B;
}
.filename::before {
  content: "";
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.25 2.25H6.75C5.50736 2.25 4.5 3.25736 4.5 4.5V19.5C4.5 20.7426 5.50736 21.75 6.75 21.75H17.25C18.4926 21.75 19.5 20.7426 19.5 19.5V4.5C19.5 3.25736 18.4926 2.25 17.25 2.25Z' stroke='%232150AA' stroke-width='1.5' stroke-linejoin='round'/%3E%3Cpath d='M8.25 6H15.75' stroke='%232150AA' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.25 9.75H15.75' stroke='%232150AA' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.25 13.5H12' stroke='%232150AA' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-right: 10px;
}
.filename + .download-file {
  text-decoration: underline;
  font-size: 14px;
  line-height: 22px;
  color: #2150AA;
  margin-left: 24px;
}
.filename + .download-file:hover {
  text-decoration: none;
}

.case-page__save-as-draft-btn,
.new-cases__save-as-draft-btn {
  min-width: 125px;
}
@media (max-width: 1079px) {
  .case-page__save-as-draft-btn,
.new-cases__save-as-draft-btn {
    font-size: 0;
    position: absolute;
    z-index: 9;
    top: 0;
    right: calc((100% - var(--internal-container-width)) / 2);
    min-width: 72px;
    width: 72px;
    padding: 0 24px 0 12px;
    border-radius: 0;
    transition: 0.00001s;
    display: flex;
    align-items: center;
    height: 64px;
    border: 0;
    background-color: #FFFFFF;
  }
  .case-page__save-as-draft-btn:hover,
.new-cases__save-as-draft-btn:hover {
    background-color: #FFFFFF;
  }
  .case-page__save-as-draft-btn::before,
.new-cases__save-as-draft-btn::before {
    content: "SAVE";
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #2150AA;
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1079px) {
  .internal-layout .title-line {
    margin: 0;
  }
}

.framework {
  background: #FFFFFF;
  border: 1px solid #D2DADC;
  padding: 36px;
  position: relative;
  margin-bottom: 24px;
}
.framework::after {
  content: "";
  top: -1px;
  right: -1px;
  position: absolute;
  z-index: 2;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 39px 0 0 26px;
  border-color: transparent transparent transparent #D2DADC;
}
.framework::before {
  content: "";
  top: -1px;
  right: -1px;
  position: absolute;
  background-color: #FFFFFF;
  z-index: 1;
  width: 27px;
  height: 40px;
}
.framework__title {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000009;
  margin-bottom: 36px;
}
.framework__section + .framework__section {
  margin-top: 48px;
}
.framework__section-name {
  text-transform: uppercase;
}
.framework__section-name, .framework__section-descr {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000009;
}
.framework__section-descr {
  margin-bottom: 16px;
}
.framework__split {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 24px;
}
.framework__split > *:first-child {
  color: #000009;
}
.framework__split > *:nth-child(2) {
  color: #6A7378;
}
.framework__participant {
  font-size: 16px;
  line-height: 24px;
}
.framework__participant-email {
  color: #2150AA;
}
.framework__comment {
  font-size: 14px;
  line-height: 22px;
}
.framework__settlement {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.framework__signature {
  font-size: 14px;
  line-height: 22px;
}
.framework__signature-date {
  margin-bottom: 10px;
}

.repeatable-case + .repeatable-case {
  margin-top: 36px;
}

.how-to-btn {
  display: none;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease;
}
@media (min-width: 1500px) {
  .how-to-btn {
    display: flex;
  }
}
.how-to-btn__icon {
  margin-right: 10px;
  width: 36px;
  min-width: 36px;
  height: 36px;
}
.how-to-btn__text {
  font-size: 14px;
  line-height: 22px;
  color: #2150AA;
  transition: 0.2s ease;
}
.how-to-btn:hover {
  opacity: 0.8;
}
.how-to-btn:hover .how-to-btn__text {
  color: #131F49;
}

.nego__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 6px;
  text-align: center;
}
@media (min-width: 1080px) {
  .nego__title {
    text-align: left;
  }
}
.nego__session-line {
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 42px;
  margin-bottom: 90px;
  gap: 18px;
}
@media (min-width: 768px) and (max-width: 1079px), (min-width: 1200px) {
  .nego__session-line {
    margin-bottom: 24px;
    gap: 0;
  }
}
.nego__session-number {
  position: absolute;
  left: 50%;
  top: calc(100% + 24px);
  transform: translateX(-50%);
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #D2DADC;
}
@media (min-width: 768px) and (max-width: 1079px), (min-width: 1200px) {
  .nego__session-number {
    top: 0;
  }
}
.nego__session-participant {
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: #000009;
}
@media (min-width: 768px) and (max-width: 1079px), (min-width: 1200px) {
  .nego__session-participant {
    display: flex;
  }
}
.nego__session-participant::before {
  content: attr(data-letter);
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #000009;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 4px;
}
@media (min-width: 768px) and (max-width: 1079px), (min-width: 1200px) {
  .nego__session-participant::before {
    margin-right: 12px;
    margin-bottom: 0;
  }
}
.nego__session-participant.you {
  color: #2150AA;
}
.nego__session-participant.you::before {
  background-color: #2150AA;
}
.nego__session-participant:last-child {
  text-align: right;
}
.nego__session-participant:last-child::before {
  margin-left: auto;
}
@media (min-width: 768px) and (max-width: 1079px), (min-width: 1200px) {
  .nego__session-participant:last-child {
    text-align: left;
  }
  .nego__session-participant:last-child::before {
    margin-left: 0;
  }
}
.nego__hint {
  margin: 0 auto 0 auto;
  border-radius: 30px;
  padding: 4px 16px;
  font-size: 14px;
  line-height: 22px;
  width: max-content;
  max-width: 100%;
  position: relative;
  text-align: center;
}
.nego__hint::before {
  content: "";
  position: absolute;
  top: -24px;
  bottom: 100%;
  width: 1px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #D2DADC;
}
.nego__result {
  text-align: center;
  max-width: 576px;
  margin: 12px auto 0 auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .nego__result {
    font-size: 24px;
    line-height: 36px;
  }
}
.nego__result--success {
  color: #29BDAB;
}
.nego__result--fail {
  color: #F13B02;
}
.nego__final-amount {
  display: flex;
  align-items: flex-start;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #173583;
  padding: 24px;
  background-color: #E4EFFF;
  max-width: 500px;
  margin: 24px auto 0 auto;
}
.nego__final-amount::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20ZM11.1625 8.235L8.3 8.59375L8.1975 9.06875L8.76 9.1725C9.1275 9.26 9.2 9.3925 9.12 9.75875L8.1975 14.0938C7.955 15.215 8.32875 15.7425 9.2075 15.7425C9.88875 15.7425 10.68 15.4275 11.0388 14.995L11.1487 14.475C10.8987 14.695 10.5337 14.7825 10.2913 14.7825C9.9475 14.7825 9.8225 14.5413 9.91125 14.1163L11.1625 8.235ZM10 6.875C10.3315 6.875 10.6495 6.7433 10.8839 6.50888C11.1183 6.27446 11.25 5.95652 11.25 5.625C11.25 5.29348 11.1183 4.97554 10.8839 4.74112C10.6495 4.5067 10.3315 4.375 10 4.375C9.66848 4.375 9.35054 4.5067 9.11612 4.74112C8.8817 4.97554 8.75 5.29348 8.75 5.625C8.75 5.95652 8.8817 6.27446 9.11612 6.50888C9.35054 6.7433 9.66848 6.875 10 6.875Z' fill='%23173583'/%3E%3C/svg%3E%0A");
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 16px;
  margin-top: 2px;
}
.nego__view-agreement-btn-wrapper {
  margin-top: 24px;
  margin-bottom: 40px;
}
.nego__subsliders-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000009;
  width: max-content;
  padding: 2px 10px;
  margin: 0 auto;
}
.nego__sliders-legend {
  margin-top: 22px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #40434B;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-auto-rows: auto;
  gap: 10px;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .nego__sliders-legend {
    display: flex;
    gap: 0;
    justify-content: space-around;
    align-items: center;
    white-space: nowrap;
  }
}
.nego__sliders-legend .item {
  display: flex;
  align-items: flex-start;
}
.nego__sliders-legend .item:nth-child(3) {
  grid-column: 1/3;
}
.nego__sliders-legend .dot {
  margin-top: 3px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: wheat;
  border-style: solid;
  border-width: 4px;
}
.nego__sliders-legend .dot--visible-bid {
  background-color: #E4EFFF;
  border-color: #2150AA;
}
.nego__sliders-legend .dot--secret-bid {
  background-color: #FFECE8;
  border-color: #F19202;
}
.nego__sliders-legend .dot--move-completed {
  background-color: #D0F5EA;
  border-color: #29BDAB;
}
.nego__end-btn-wrapper {
  margin: 40px 0 48px;
}
.nego__end-btn-wrapper .btn {
  min-width: 138px;
}

.chat {
  margin-top: 48px;
  border: 1px solid #D2DADC;
  max-width: 624px;
}
.chat__title-line {
  border-bottom: 1px solid #D2DADC;
  padding: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}
.chat__messages {
  padding: 12px 0;
  max-height: 342px;
  overflow: auto;
}
.chat__message {
  padding: 12px 24px;
  position: relative;
}
.chat__message::before {
  content: attr(data-letter);
  position: absolute;
  top: 12px;
  left: 24px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF;
  background-color: #6A7378;
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.chat__message > * {
  padding-left: 36px;
}
.chat__message.yours::before {
  background-color: #2150AA;
}
.chat__name-line {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}
.chat__name {
  color: #40434B;
  margin-right: 8px;
}
.chat__name.you {
  color: #2150AA;
}
.chat__datetime {
  color: #6A7378;
}
.chat__text {
  font-size: 14px;
  line-height: 22px;
  color: #000009;
}
.chat__date-divider {
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #2150AA;
}
.chat__date-divider::before, .chat__date-divider::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background-color: #E4EFFF;
}
.chat__date-divider::before {
  margin-right: 16px;
}
.chat__date-divider::after {
  margin-left: 16px;
}
.chat__input-message {
  border-top: 1px solid #D2DADC;
  position: relative;
}
.chat__submit-btn {
  color: #2150AA;
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 16px;
  right: 20px;
  cursor: pointer;
  transition: 0.2s ease;
}
.chat__submit-btn:hover {
  color: #000009;
}
.chat__input-label {
  display: block;
}
.chat__input-field {
  appearance: none;
  outline: 0;
  border-width: 0;
  width: 100%;
  padding: 17px 20px;
  transition: 0.2s ease;
  font-size: 14px;
  line-height: 22px;
  color: #000009;
}
.chat__input-field::placeholder {
  color: #6A7378;
  font-size: 14px;
  line-height: 22px;
}
.chat__input-field:focus {
  box-shadow: 0 0 0 3px rgba(33, 80, 170, 0.3);
}

.award {
  counter-reset: award-items;
}
.award__item {
  counter-increment: award-items;
}
.award__item-title-line {
  display: flex;
  align-items: center;
  padding-block: 12px;
  border-bottom: 1px solid #D2DADC;
  cursor: pointer;
}
.award__item-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #6A7378;
  transition: color 0.2s ease;
  margin-right: 24px;
}
.award__item-title::before {
  content: counter(award-items) ". ";
}
.award__item-content {
  transition: 0.2s ease;
  transform: scaleY(0);
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform-origin: center top;
}
.award__item.active .award__item-content {
  opacity: 1;
  transform: scaleY(1);
  padding: 24px 0;
  max-height: max-content;
  border-bottom: 1px solid #D2DADC;
}
.award__item-state {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: auto;
  user-select: none;
}
.award__item-state.color-cadetgray {
  display: none;
}
@media (min-width: 768px) {
  .award__item-state.color-cadetgray {
    display: block;
  }
}
.award__item-state.color-keppel {
  font-size: 0;
  line-height: 0;
  color: rgba(0, 0, 0, 0);
  min-width: 16px;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='8' fill='%2329BDAB'/%3E%3Cpath d='M11.75 5L6.5 11L4.25 8.75' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: -14px;
}
@media (min-width: 768px) {
  .award__item-state.color-keppel {
    background: none;
    width: max-content;
    height: auto;
    color: #29BDAB;
    font-size: 12px;
    line-height: 16px;
    margin-right: 0;
  }
}
.award__item-state + .award__item-toggler {
  margin-left: auto;
}
@media (min-width: 768px) {
  .award__item-state + .award__item-toggler {
    margin-left: 24px;
  }
}
.award__item-toggler {
  position: relative;
  margin-left: 24px;
  cursor: pointer;
  display: block;
  width: 24px;
  min-width: 24px;
  height: 24px;
  /* vertical line */
  /* horizontal line */
}
.award__item-toggler:before, .award__item-toggler:after {
  content: "";
  position: absolute;
  background-color: #6A7378;
  transition: transform 0.2s ease;
  border-radius: 100px;
}
.award__item-toggler:before {
  top: 5px;
  left: 50%;
  width: 2px;
  height: 14px;
  margin-left: -1px;
}
.award__item-toggler:after {
  top: 50%;
  left: 5px;
  width: 14px;
  height: 2px;
  margin-top: -1px;
}
.award__item.active .award__item-toggler:before {
  transform: rotate(90deg);
  background-color: #173583;
}
.award__item.active .award__item-toggler:after {
  transform: rotate(180deg);
  background-color: #173583;
}
.award__item.active .award__item-title {
  color: #173583;
}
.award__court-of {
  font-size: 20px;
  line-height: 30px;
  color: #6A7378;
  text-align: center;
}
.award__case-nr {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 16px;
}
.award__participants {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #6A7378;
  margin-top: 44px;
}
.award__participant {
  font-size: 16px;
  line-height: 24px;
  color: #173583;
  margin-block: 4px;
}
.award__mega-title {
  font-size: 64px;
  line-height: 80px;
  color: #2150AA;
  margin-top: 92px;
  text-align: center;
}
.award__date {
  font-size: 16px;
  line-height: 24px;
  color: #131F49;
  text-align: center;
}
.award__issued {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin-top: 160px;
}
.award__rendered {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #131F49;
  padding-bottom: 24px;
  margin-top: 10px;
}
.award__subitem-party {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
.award__subitem-descr {
  font-size: 14px;
  line-height: 22px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  transition: 0.2s ease;
  /* textarea {
      padding: 0;
      height: max-content;
      appearance: none;
      border: 0;
      font-size: 14px;
      line-height: 22px;
      margin-top: 16px;
      resize: none;
      width: 100%;
  } */
}
.award__subitem-descr[contenteditable=true] {
  outline: 0;
  padding-bottom: 10px;
  border-bottom-color: #2150AA;
}
.award__subitem-edit-btn {
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: max-content;
  font-size: 14px;
  line-height: 22px;
  color: #2150AA;
  cursor: pointer;
  transition: 0.2s ease;
}
.award__subitem-edit-btn:hover {
  opacity: 0.8;
}
.award__subitem-edit-btn .svg-icon {
  margin-left: 4px;
  width: 16px;
  min-width: 16px;
  height: 16px;
}
.award__subitem-textarea {
  margin-top: 24px;
}
.award__subitem + .award__subitem {
  margin-top: 48px;
}
.award__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin-left: auto;
  margin-top: 24px;
}
.award__upload-btn {
  min-width: 266px;
}
.award__bottom-line {
  margin-top: 24px;
  font-size: 14px;
  line-height: 22px;
  color: #40434B;
  display: block;
}
.award__bottom-line > * + * {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .award__bottom-line {
    display: flex;
  }
  .award__bottom-line > * + * {
    margin-top: 0;
    margin-left: 10px;
  }
}
.award__e-signature {
  margin-top: 12px;
  width: 100%;
}
.award__bottom-buttons {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
}
@media (min-width: 580px) {
  .award__bottom-buttons {
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .award__bottom-buttons {
    flex-direction: column;
  }
}
@media (min-width: 1500px) {
  .award__bottom-buttons {
    flex-direction: row;
  }
}
.award__bottom-buttons > * {
  margin-top: 0;
  margin-left: 0;
  width: auto;
}

.lawyer-case-continue-btn {
  padding: 12px 24px;
  display: none;
}
@media (min-width: 1200px) {
  .lawyer-case-continue-btn {
    display: inline-block;
  }
}

.internal-layout {
  margin-inline: -20px;
}
@media (min-width: 768px) {
  .internal-layout {
    margin-inline: auto;
  }
}

.mobile-internal-nav {
  display: flex;
  background-color: #F7FAFC;
}
.mobile-internal-nav .link {
  padding-block: 16px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  flex-grow: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #6A7378;
  text-align: center;
  text-decoration: none;
}
.mobile-internal-nav .link.active {
  border-color: #2150AA;
  color: #2150AA;
}
@media (min-width: 1080px) {
  .mobile-internal-nav {
    display: none;
  }
}

.mobile-internal-fixed-nav {
  position: fixed;
  z-index: 8;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #F7FAFC;
  border-top: 1px solid #EBF3F8;
  display: flex;
}
@media (min-width: 1080px) {
  .mobile-internal-fixed-nav {
    display: none;
  }
}
.mobile-internal-fixed-nav .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  padding-block: 11px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.mobile-internal-fixed-nav .item:not(.color-sapphire) {
  color: #6A7378;
}
.mobile-internal-fixed-nav .item.active {
  color: #000009;
}
.mobile-internal-fixed-nav .item .icon {
  width: 24px;
  height: 24px;
  margin-bottom: 2px;
}
.mobile-internal-fixed-nav--lawyer .item {
  width: 25%;
}

.answer-form__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 24px;
}
.answer-form__additional-comments {
  margin-top: 36px;
}
.answer-form__buttons {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.answer-form__buttons .btn {
  padding-inline: 48px;
}

.answer-item + .answer-item {
  margin-top: 36px;
}

.answer-item__name {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
.answer-item__agree {
  padding: 24px;
  background-color: #EBF3F8;
}
.answer-item__agree-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}
.answer-item__agree-descr {
  font-size: 14px;
  line-height: 22px;
  color: #40434B;
  margin-bottom: 16px;
}
.answer-item__agree-radios {
  display: flex;
  align-items: center;
}
.answer-item__agree-radios .radio + .radio:not(.radio--lined) {
  margin-top: 0;
  margin-left: 24px;
}
.answer-item__disagree-options {
  margin-top: 16px;
}
.answer-item__disagree-options-name {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
.answer-item__disagree-option + .answer-item__disagree-option {
  margin-top: 10px;
}
.answer-item__disagree-option {
  display: block;
  cursor: pointer;
}
.answer-item__disagree-option input {
  width: 0;
  height: 0;
  position: absolute;
  visibility: hidden;
  opacity: 0;
}
.answer-item__disagree-option-name {
  display: block;
  position: relative;
  padding: 16px 16px 16px 46px;
  background-color: #FFFFFF;
  border: 1px solid #D2DADC;
  transition: 0.2s ease;
}
.answer-item__disagree-option-name::before {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 16px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px #D2DADC;
  transition: 0.2s ease;
}
input:checked + .answer-item__disagree-option-name {
  border-color: #173583;
  background-color: #173583;
  color: #FFFFFF;
}
input:checked + .answer-item__disagree-option-name::before {
  box-shadow: inset 0 0 0 6px #FFFFFF;
}
.answer-item__disagree-other-describe {
  margin-top: 10px;
}

.checkbox-name-right {
  font-size: 14px;
  margin-right: 4px;
}

.history__item + .history__item {
  margin-top: 12px;
}
@media (min-width: 768px) {
  .history__item + .history__item {
    margin-top: 10px;
  }
}
.history__item {
  align-items: center;
}
@media (min-width: 768px) {
  .history__item {
    display: flex;
  }
}
.history__date {
  color: #6A7378;
  font-size: 14px;
  line-height: 22px;
}
@media (min-width: 768px) {
  .history__date {
    font-size: 16px;
    line-height: 24px;
  }
}
.history__info {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: #000009;
}
@media (min-width: 768px) {
  .history__info {
    margin-left: 18px;
    font-size: 16px;
    line-height: 24px;
  }
}
.history__info::before {
  content: "";
  display: none;
  height: 1px;
  width: 24px;
  min-width: 24px;
  background-color: #D2DADC;
  margin-right: 16px;
}
@media (min-width: 768px) {
  .history__info::before {
    display: block;
  }
}

.mr-6 {
  margin-right: 36px;
}

.mt-4 {
  margin-top: 24px;
}

@media (max-width: 1079px) {
  .body.modal-opened .internal-page-title {
    z-index: 0;
  }
}
[data-slide-toggle=content],
[data-slide-toggle=toggler],
[data-slide-toggle=toggler]::after,
[data-slide-toggle=toggler]::before,
[data-slide-toggle=wrapper] {
  transition: 0.3s ease;
}

[data-slide-toggle=content] {
  max-height: 0;
  overflow: hidden;
}

[data-slide-toggle=wrapper].active [data-slide-toggle=content] {
  max-height: var(--max-toggle-height);
}

@media (max-width: 1199px) {
  .section--how-it-works, .section--lawyers, .section--faq,
.work-with-us, .section--reviews--page, .terms,
.contract-clauses, .upload-how, .contract-templates {
    margin-bottom: 96px;
  }
}
@media (max-width: 992px) {
  .section--welcome {
    flex-direction: column;
  }

  .section--welcome__video {
    max-width: 100%;
    min-width: 100%;
    display: flex;
  }

  .section--welcome__text {
    padding: 64px 24px;
  }

  .page-title--common {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
  }

  .what-is__name {
    font-size: 36px;
    line-height: 48px;
    padding-bottom: 12px;
    margin-bottom: 18px;
  }

  .what-is__descr, .page-descr {
    font-size: 20px;
    line-height: 30px;
  }

  .section__title--small {
    font-size: 36px;
    line-height: 42px;
  }

  .section--legal-info {
    padding: 36px 24px;
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 90px;
  }

  .section--legal-info__items {
    grid-template-columns: 1fr 1fr;
    gap: 24px 36px;
  }

  .section--faq__tab-titles {
    display: none;
  }

  .section--faq__tab-select-mobile {
    display: block;
  }

  .section--faq {
    margin-top: 10px;
  }

  .auth-page {
    padding: 48px 0;
  }

  .auth-page__logo-link {
    margin-bottom: 48px;
    width: 120px;
    height: 87px;
  }
}
@media (max-width: 767px) {
  .section__title {
    font-size: 42px;
    line-height: 56px;
  }

  .footer .footer-bottom-content {
    flex-direction: column;
    padding: 24px 0;
  }

  .footer .footer-bottom .logo-link {
    margin-right: 0;
  }

  .footer .footer-bottom .copy {
    margin: 16px 0 6px;
  }
  .footer .footer-bottom .copy br {
    display: none;
  }

  .section--advantages--page .swiper-wrapper {
    all: initial;
  }

  .section--how-it-works__step::before {
    width: 120px;
    min-width: 120px;
  }

  .advantages--noslider {
    display: block;
    margin: 0 -24px;
  }

  .section--advantages--page {
    overflow-x: visible;
  }

  .section--legal-info, .section--how-it-works, .section--lawyers, .section--faq, .work-with-us, .section--reviews--page, .terms {
    margin-bottom: 64px;
  }

  .section--legal-info__items {
    grid-template-columns: 1fr 1fr;
    gap: 24px 36px;
  }

  .section__title--forum-font {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 0;
  }

  .section--lawyers__item {
    padding: 24px;
  }

  .advantages--mediation .advantages__item, .section--advantages--page .advantages--mediation .advantages__item {
    width: 100%;
  }

  .advantages__name--bigger {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 600px) {
  .section--welcome__buttons {
    width: 100%;
    flex-direction: column;
  }

  .section--welcome__buttons .btn {
    width: initial;
  }

  .section--welcome__buttons .btn + .btn {
    margin-left: 0;
    margin-top: 16px;
  }

  .reviews {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .reviews__item {
    padding: 36px 24px 24px 24px;
  }

  .reviews__item + .reviews__item {
    margin-top: 24px;
  }

  .section--reviews__more {
    display: none;
  }

  .section--why__advantages .advantages__item {
    width: 240px;
  }

  .page-title--common {
    font-size: 30px;
    line-height: 40px;
  }

  .page-title-line {
    margin-bottom: 24px;
  }

  .what-is__name {
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 8px;
    margin-bottom: 24px;
  }

  .what-is__descr, .page-descr {
    font-size: 16px;
    line-height: 24px;
  }

  .what-is {
    margin-bottom: 78px;
  }

  .section--advantages--page, [data-mediation-scrollmagic-wrapper] {
    margin-bottom: 64px;
  }

  .section--how-it-works__step {
    display: block;
  }
  .section--how-it-works__step::before {
    display: block;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  }

  .section--how-it-works__step:not(:last-child) {
    margin-bottom: 64px;
  }

  .section--how-it-works__step {
    font-size: 16px;
    line-height: 24px;
  }

  .section--how-it-works__blockquote {
    padding: 16px;
  }

  .section--legal-info__items {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .section--lawyers__item {
    display: block;
  }

  .section--lawyers__image {
    margin-bottom: 24px;
    width: 100%;
    padding-top: 100%;
    position: relative;
  }

  .section--lawyers__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  .section--lawyers {
    margin-top: 24px;
  }

  .section--faq__item {
    padding: 24px;
  }

  .section--faq__item.active .section--faq__a {
    margin-top: 24px;
  }

  .section--faq__tab-contents {
    margin: 0 -24px;
  }

  .section--faq__q {
    font-size: 20px;
    line-height: 30px;
  }

  .section--faq__q::after {
    width: 30px;
    min-width: 30px;
    height: 30px;
  }

  .auth-page {
    padding: 24px 0 0 0;
  }

  .auth-page__logo-link {
    margin-bottom: 34px;
    width: 88px;
    height: 64px;
  }

  .auth-page__layout {
    margin: 0 -20px;
  }

  .auth-page__content {
    padding: 24px 24px 44px 24px;
  }

  .auth__tab-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    width: 50%;
    text-align: center;
  }

  .auth__descr {
    margin-bottom: 24px;
  }

  .auth__input-label + .auth__input-label, .auth__inputs-line + .auth__input-label {
    margin-top: 16px;
  }

  .auth__inputs-line .auth__input-label {
    margin-top: 0;
  }
}
@media (max-width: 500px) {
  .section__title {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 16px;
  }

  .section__descr {
    font-size: 16px;
    line-height: 24px;
  }

  .section--welcome__text {
    padding: 36px 24px;
  }

  .section--welcome__title {
    font-size: 30px;
    line-height: 40px;
  }

  .section--welcome__subtitle {
    font-size: 20px;
    line-height: 30px;
  }

  .section--welcome__descr {
    font-size: 16px;
    line-height: 24px;
  }

  .section--welcome__video .play-btn {
    width: 64px;
    height: 64px;
  }

  .section--questions {
    padding: 64px 0 0 0;
    max-width: 100%;
    overflow: hidden;
  }

  .section--reviews {
    padding: 64px 0;
  }

  .section--reviews--page {
    padding: 0;
  }

  .section--questions__col {
    padding: 0 24px;
  }

  .section--questions__col--with-background {
    padding: 36px 24px;
  }

  .section--questions__title {
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 14px;
    margin-bottom: 16px;
  }

  .section--questions__descr {
    font-size: 16px;
    line-height: 24px;
  }

  .section--questions__buttons .btn {
    min-width: calc(50% - 12px);
  }

  .section--questions__col + .section--questions__col {
    margin-top: 64px;
  }

  .section--questions__buttons .btn + .btn {
    margin-left: 16px;
  }

  .section--questions__columns {
    margin: 0 -24px;
  }

  .section--why__advantages .advantages__item {
    padding: 24px;
  }

  .section--reviews__more--mobile {
    display: flex;
    margin-top: 24px;
  }

  .work-with-us__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .work-with-us__text p + p {
    margin-top: 24px;
  }

  .work-with-us__button-title {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
  }

  .work-with-us__button {
    display: flex;
    margin: 0 auto;
    max-width: 272px;
  }

  .header .nav {
    max-width: 100%;
    top: unset;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .header .nav::before {
    position: absolute;
    opacity: 1;
    transition: 0.2s ease;
    transition-delay: 0.2s;
    top: -200vh;
    left: 0;
    right: 0;
    bottom: calc(100%);
    width: 100%;
  }
  .header .nav::after {
    content: "";
    display: block;
    width: 120px;
    height: 4px;
    background-color: #FFFFFF;
    border-radius: 6px;
    position: absolute;
    z-index: 10;
    left: 50%;
    bottom: calc(100% + 10px);
    transform: translateX(-50%);
  }
  .header .nav:not(.active) {
    transform: translateY(120%);
  }
  .header .nav:not(.active)::before {
    opacity: 0;
    pointer-events: none;
  }

  .auth__submit-line {
    flex-direction: column;
    justify-content: center;
  }

  .auth__terms-checkbox {
    margin-right: 0;
    margin-bottom: 24px;
  }

  .auth__forgot {
    order: 99;
    margin-top: 24px;
  }

  .auth__inputs-line {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
  }

  .auth__who .radios {
    flex-direction: column;
    align-items: flex-start;
  }
  .auth__who .radios .radio + .radio {
    margin-top: 12px;
    margin-left: 0;
  }
}
@media (max-width: 480px) {
  .auth__submit-line .btn {
    width: 100%;
    padding: 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}