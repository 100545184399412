@use '../variables/colors' as colors;

.notifications {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 99;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .8);
    opacity: 0;
    pointer-events: none;
    transition: .3s ease;

    &__inner {
        max-width: 100%;
        width: 100%;
        height: calc(100% - 92px);
        background-color: colors.$white;
        transition: .2s ease;
        transform: translateY(calc(100% + 14px));
        margin-top: auto;

        @media (min-width: 680px) {
            max-width: 480px;
            height: 100vh;
            transform: translateX(100%);
            margin-top: 0;
        }

        &::after {
            content: '';
            bottom: calc(100% + 10px);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background-color: colors.$white;
            height: 4px;
            width: 120px;
            border-radius: 6px;
            pointer-events: none;
            @media (min-width: 680px) { opacity: 0; }
        }
    }
    &.active { opacity: 1; pointer-events: all; }
    &.active &__inner {
        transition-delay: .2s;
        transform: translateX(0);

        @media (min-width: 680px) {
            transform: translatey(0);
        }
    }
    &__title-line {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px 24px;
        background-color: colors.$snow;

        @media (min-width: 680px) {
            padding: 30px 36px;
            background-color: rgba(0,0,0,0);
            border-bottom: 1px solid colors.$lightGray;
        }
    }
    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: colors.$black;
        width: 100%;

        @media (min-width: 680px) {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            text-align: left;
            width: auto;
        }
    }
    &__close {
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
        width: 36px;
        min-width: 36px;
        height: 36px;
        color: #6A7378;
        transition: .2s ease;
        cursor: pointer;
        &:hover { color: colors.$black; }
        &:active { transform: scale(1.075) translateY(-50%); }

        @media (min-width: 680px) {
            position: static;
            transform: translateY(0);
            width: 24px;
            min-width: 24px;
            height: 24px;
            &:active { transform: scale(1.075); }
        }
    }

    &__delete-all {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        color: colors.$sapphire;
        width: max-content;
        margin: 15px auto 6px auto;
        padding: 10px 0;
        cursor: pointer;
        transition: .2s ease;
        &:hover { color: colors.$spaceCadet }

        @media (min-width: 680px) {
            margin: 12px 36px 12px auto;
        }
    }

    &__items {
        padding: 0 24px 48px 24px;
        max-height: calc(100% - 156px);
        overflow: auto;

        @media (min-width: 680px) {
            padding: 0 36px 48px 36px;
        }
    }
}

.notification + .notification {
    margin-top: 32px;
    position: relative;

    @media (min-width: 680px) {
        margin-top: 36px;
    }
    &::before {
        content: '';
        position: absolute;
        top: -16px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: colors.$alabaster;

        @media (min-width: 680px) {
            display: none;
        }
    }

}
.notification {
    &__date-line { display: flex; align-items: center; margin-bottom: 10px; }
    &__icon { width: 24px; min-width: 24px; height: 24px; margin-right: 10px; }
    &__date-time { text-transform: uppercase; font-weight: 500; font-size: 12px; line-height: 16px; letter-spacing: 1px; color: colors.$ebony; }
    &__delete {
        margin-left: auto;
        width: 16px;
        min-width: 16px;
        height: 16px;
        color: colors.$cadetGray;
        cursor: pointer;
        transition: .2s ease;
        &:hover { color: colors.$kleinBlue }
    }

    &__text {
        font-size: 16px;
        line-height: 24px;
        color: colors.$black;
        strong { font-weight: 600; }
    }

    &__buttons {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn + .btn { margin-left: 21px; }
        .btn { padding: 6px 16px; min-width: unset; }
        .btn:first-of-type {
            @media (min-width: 680px) { margin-left: auto; }
        }

        @media (min-width: 680px) {
            justify-content: initial;
        }
    }

    &__deadline {
        font-size: 14px;
        line-height: 22px;
        color: colors.$ebony;
        strong { font-weight: 500; color: colors.$black }
    }
}
