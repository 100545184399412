@use '../variables/colors' as colors;

.toggler-buttons {
    display: flex;
    border-radius: 36px;
    background-color: colors.$white;
    box-shadow: inset 0 0 0 1px colors.$alabaster;
    position: relative;
    &__option {
        position: relative;
        z-index: 2;
        padding: 7px 24px;
        cursor: pointer;
        border-radius: 36px;
        transition: .2s ease;
        &:not(.active):hover { background-color: rgba(colors.$sapphire, .05); }

        &.active { color: colors.$white; }
    }

    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        height: 100%;
        border-radius: 36px;
        background-color: colors.$sapphire;
        top: 0;
        left: var(--bg-l);
        width: var(--bg-w);
        transition: .2s ease;
    }
}