@use 'variables/typography' as *;
@use 'variables/colors' as colors;
@use 'abstracts/mixins' as m;

/*font-family: 'Cabin', sans-serif;*/
/*font-family: 'Forum', cursive;*/

#app {
    display: flex;
    flex-direction: column;
}

.body {
    display: flex;
    flex-direction: column;
    font-size: 100%;
    @extend %cabinFont;
    background-color: colors.$snow;
    overflow-x: hidden;
    color: colors.$black;
    transition: .3s ease;

    &.modal-opened {
        overflow: hidden;
    }

    &.top-notification-opened {
        overflow: hidden;
        @media (min-width: 1024px) {
            overflow: auto;
            overflow-x: hidden;
            margin-top: 72px;
        }
    }
}

.container {
    max-width: 1456px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    &--thin {
        max-width: 976px;
    }
}

.body--internal .container {
    --internal-container-width: 100%;
    max-width: 100%;
    padding: 0 20px;

    @media (min-width: 580px) {
        --internal-container-width: 540px;
        max-width: 540px;
    }

    @media (min-width: 768px) {
        --internal-container-width: 760px;
        max-width: 760px;
    }

    @media (min-width: 992px) {
        --internal-container-width: 952px;
        max-width: 952px;
    }

    @media (min-width: 1200px) {
        --internal-container-width: 1140px;
        max-width: 1140px;
    }

    @media (min-width: 1200px) {
        --internal-container-width: 1140px;
        max-width: 1140px;
    }

    @media (min-width: 1500px) {
        --internal-container-width: 1456px;
        max-width: 1456px;
    }
}

// UI Testing
.ui-testing-wrapper {
    min-height: 100vh;
    display: grid;
    place-items: center;
    padding: 36px 10px;
}


.ui-test-case-labels {
    .status + .status {
        margin-left: 12px;
    }
}

.ui-testing-custom-select {
    min-width: 280px;
}

.ui-testing-icons {
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
    padding: 36px 10px;

    .icons {
        display: grid;
        grid-template-columns: repeat(14, 1fr);
        gap: 24px;
    }

    .svg-icon {
        width: 24px;
        height: 24px;
    }
}

.ui-testing-datepicker {
    padding: 36px 10px;
    min-width: 320px;
}

.ui-testing-radio-checkboxes {
    padding: 36px 10px;
}

.ui-testing-inputs {
    width: 100%;
    max-width: 320px;
    padding: 36px 10px;
}

.ui-testing-file-input {
    width: 100%;
    max-width: 320px;
    padding: 36px 10px;
}

.ui-testing-button {
    padding: 36px 0;

    .btn + .btn {
        margin-left: 24px;
    }

    .flex + .flex {
        margin-top: 12px;
    }
}

// Error Page
.body--error {
    padding: 24px 0;
    background-color: colors.$beauBlue;
    overflow-x: hidden;

    @media (min-width: 768px) {
        padding: 64px 0;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
    }
}

.main--error-page {
    padding: 64px 0;
    position: relative;
    z-index: 0;
    width: 100%;
    @include m.pseudo(before, attr(data-error-code)) {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        @extend %forumFont;
        text-align: center;
        color: colors.$white;
        z-index: -1;
        font-size: 180px;
        line-height: 180px;

        @media (min-width: 768px) {
            top: -141px;
            font-size: 800px;
            line-height: 800px;
        }
    }
}

.error {
    &__text {
        font-weight: 500;
        letter-spacing: -.5px;
        color: colors.$black;
        font-size: 24px;
        line-height: 36px;
        text-align: center;

        @media (min-width: 768px) {
            text-align: left;
            font-size: 36px;
            line-height: 48px;
        }
    }

    &__btn {
        margin-top: 24px;
        display: block;
        width: max-content;
        margin-inline: auto;
        @media (min-width: 768px) {
            display: inline-block;
        }
    }
}

// Advantages
.advantages {
    &.swiper-container {
        overflow: visible;
    }

    .swiper-wrapper {
        transition-duration: 200ms !important;
    }

    &--noslider {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;

        @media (max-width: 1400px) {
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: auto;
        }
    }

    &__item {
        color: colors.$white;
        padding: 48px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        &--with-bg-image {
            @include m.bg-img(cover, 50%, flex);
        }

        &--text-black {
            color: colors.$black;
        }
    }

    &--noslider &__item {
        padding: 36px;
    }

    &__name {
        @extend %forumFont;
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 16px;

        &--bigger {
            font-size: 48px;
            line-height: 56px;
            margin-bottom: 12px;
        }
    }

    &__text {
        font-family: 'Cabin', sans-serif;
        font-size: 20px;
        line-height: 30px;
    }
}

.reviews {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    gap: 24px;

    @media (max-width: 1199px) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: auto;
    }

    &__item {
        padding: 36px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        min-height: 384px;
        @include m.pseudo(before, attr(data-letter)) {
            font-size: 180px;
            line-height: 180px;
            @extend %forumFont;
            color: colors.$kleinBlue;
            opacity: .1;
            position: absolute;
            top: 16px;
            left: 36px;
        }
    }

    &__name {
        font-size: 32px;
        line-height: 40px;
        @extend %forumFont;
        color: colors.$ebony;
    }

    &__date {
        font-size: 14px;
        line-height: 22px;
        color: colors.$cadetGray;
        margin-bottom: 10px;
        margin-top: auto;
    }

    &__text {
        font-size: 16px;
        line-height: 24px;
        color: colors.$black
    }
}

.page-title--common {
    font-weight: 600;
    font-size: 56px;
    line-height: 70px;
    color: colors.$black;
}

.page-title-line {
    margin-bottom: 48px;
    margin-top: 32px;

    .how-it-works {
        font-size: 16px;
        line-height: 24px;
        color: colors.$sapphire;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.main--upload .how-it-works {
    display: none;
    @media (min-width: 768px) {
        display: inline-block;
    }
}

.page-descr {
    font-size: 24px;
    line-height: 36px;
    max-width: 936px;
}


// Sections

.section {
    &__title {
        font-weight: 600;
        font-size: 56px;
        line-height: 70px;
        color: colors.$black;
        margin-bottom: 24px;

        &--small {
            font-size: 44px;
            line-height: 50px;
        }

        &--forum-font {
            font-weight: normal;
            font-size: 48px;
            line-height: 56px;
            margin-bottom: 12px;
            @extend %forumFont;

            @media (max-width: 1199px) {
                font-size: 45px;
                line-height: 50px;
            }
        }
    }

    &__descr {
        font-size: 24px;
        line-height: 36px;
        color: colors.$cadetGray;
    }

    &__small-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: colors.$ebony;
    }
}

.section--welcome {
    display: flex;
    margin-top: -152px;
    position: relative;
    z-index: 0;

    @media (max-width: 1079px) {
        margin-top: -80px;
    }

    &__video {
        max-width: 39%;
        min-width: 39%;
        position: relative;

        .inner {
            position: relative;
            width: 100%;
            padding-top: 106%;
            height: 100%;
            @include m.bg-img(cover, 50%);
            background-image: url('../img/welcome-video-preview.jpg');
            background-color: colors.$spaceCadet;
        }

        .play-btn {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            @include m.bg-img(contain, 50%);
            background-image: url('../img/play-btn-blur.svg');
            cursor: pointer;
            width: 122px;
            height: 122px;
            transition: .2s ease;
            backdrop-filter: blur(15px);
            border-radius: 50%;
            background-blend-mode: normal, luminosity;

            &:hover {
                opacity: .9;
            }
        }
    }

    &__text {
        flex-grow: 1;
        background-color: colors.$sapphire;
        padding: 152px 96px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @media (max-width: 1079px) {
            padding: 164px 36px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 56px;
        line-height: 70px;
        color: colors.$carrotOrange;

        @media (max-width: 1079px) {
            font-size: 48px;
            line-height: 60px;
        }
    }

    &__subtitle {
        font-weight: bold;
        font-size: 44px;
        line-height: 50px;
        color: colors.$white;

        span {
            opacity: .5;
        }

        @media (max-width: 1079px) {
            font-size: 36px;
            line-height: 40px;
        }
    }

    &__descr {
        margin-top: 24px;
        font-size: 20px;
        line-height: 30px;
        color: colors.$lightGray;

        @media (max-width: 1079px) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__buttons {
        margin-top: 48px;

        @media (max-width: 1079px) {
            margin-top: 36px;
        }

        .btn {
            width: 251px
        }

        .btn + .btn {
            margin-left: 36px;
        }
    }
}

.section--questions {
    padding: 192px 0 160px;
    @media (max-width: 1199px) {
        padding: 96px 0;
    }

    &__columns {
        display: grid;
        grid-template-columns: 480px auto 384px;
        margin-left: -48px;
        @media (max-width: 1400px) {
            margin-left: -36px;
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 1199px) {
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            margin-left: 0;
        }
    }

    &__col {
        padding: 48px;
        color: colors.$black;

        &--with-background {
            color: colors.$white;
            @include m.bg-img(cover, 50%);
        }

        @media (max-width: 1400px) {
            padding: 24px 36px;
        }
        @media (max-width: 1199px) {
            width: 50%;
            &:nth-child(3) {
                width: 100%;
                margin-top: 24px;
            }
        }
        @media (max-width: 1079px) {
            width: 100%;
        }
    }

    &__title {
        font-size: 48px;
        line-height: 56px;
        padding-bottom: 24px;
        border-bottom: 2px solid currentColor;
        margin-bottom: 24px;
        @extend %forumFont;
    }

    &__descr {
        font-size: 20px;
        line-height: 30px;

        p + p {
            margin-top: 24px;
        }
    }

    &__image {
        margin-top: 24px;
    }

    &__subitems {
        margin: 24px 0;
    }

    &__subitem {
        &:not(:last-child) {
            margin-bottom: 24px;
        }

        .name {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: colors.$kleinBlue;
        }

        .text {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: colors.$ebony;
        }
    }

    &__buttons {
        margin-top: 24px;

        .btn + .btn {
            margin-left: 24px;
        }
    }
}

.section--why {
    padding: 120px 0;
    background-color: colors.$alabaster;
    overflow: hidden;
    @media (max-width: 1199px) {
        padding: 96px 0;
    }

    &__advantages {
        margin-top: 48px;

        .advantages__item {
            height: 392px;
            width: 100%;
        }
    }

    &__slider-navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;

        .swiper-pagination-bullet {
            background-color: rgba(colors.$black, .25);
            border-radius: 48px;
            width: 16px;
            min-width: 16px;
            height: 2px;
            opacity: 1;
            transition: .2s ease;

            &-active {
                width: 48px;
                min-width: 48px;
                background-color: colors.$sapphire;
            }
        }
    }
}

.section--reviews {
    padding: 240px 0 200px;
    @media (max-width: 1199px) {
        padding: 96px 0;
    }

    &--page {
        padding: 0;
        margin-bottom: 200px;
        @media (max-width: 1199px) {
            padding: 0;
        }
    }

    &__more, &__more--mobile {
        text-decoration: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: colors.$sapphire;
        white-space: nowrap;

        .svg-icon {
            width: 16px;
            height: 16px;
            margin-left: 10px;
        }

        &:hover {
            color: darken(colors.$sapphire, 20%)
        }
    }

    &__reviews {
        margin-top: 48px;
    }

    &__more--mobile {
        display: none;
        justify-content: center;
    }
}

.what-is {
    margin-bottom: 240px;
    display: grid;
    grid-template-columns: 776px 1fr;
    gap: 64px;

    @media (max-width: 1400px) {
        grid-template-columns: 45% 1fr;
    }

    @media (max-width: 1079px) {
        display: block;
        margin-bottom: 90px;
    }

    &__name {
        display: inline-block;
        font-size: 48px;
        line-height: 56px;
        color: colors.$sapphire;
        @extend %forumFont;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 2px solid colors.$kleinBlue;
    }

    &__descr {
        font-size: 24px;
        line-height: 36px;
        color: colors.$ebony;
    }

    &__buttons {
        margin-top: 24px;

        .btn + .btn {
            margin-left: 18px;
        }
    }

    &__media {

        @media (max-width: 1079px) {
            margin-top: 24px;
        }

        .video {
            cursor: pointer;
            position: relative;
            @include m.pseudo(after) {
                background: url('../img/play-btn-blur.svg'), linear-gradient(0deg, rgba(249, 249, 249, 0.05), rgba(249, 249, 249, 0.05)), rgba(249, 249, 249, 0.3);
                @include m.bg-img(contain, 50%);
                width: 122px;
                height: 122px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: .2s ease;
                z-index: 1;
                backdrop-filter: blur(15px);
                border-radius: 50%;
                background-blend-mode: normal, luminosity;
            }

            &:hover::after {
                opacity: .8;
            }
        }
    }
}

[data-mediation-scrollmagic-wrapper] {
    margin-bottom: 90px;

    @media (min-width: 1080px) {
        margin-bottom: 240px;
    }
}

.section--advantages--page {
    overflow-x: hidden;
    margin-bottom: 90px;

    @media (min-width: 1080px) {
        margin-bottom: 240px;
    }

    .swiper-container {
        overflow: initial;
    }

    .swiper-wrapper {
        transition-duration: 200ms !important;
    }

    &__title {
        margin-bottom: 22px;
    }

    &__descr {
        margin-bottom: 48px;
        max-width: 696px;
    }

    .advantages__item {
        min-height: 536px;
    }

    .advantages--mediation .advantages__item {
        min-height: 346px;
        min-width: 336px;
        width: 336px;
    }

    &__buttons {
        margin-top: 48px;
    }

    &__button .svg-icon {
        width: 16px;
        height: 16px;
        margin-left: 10px;
    }
}

@media (max-width: 1400px) {
    .section--advantages--arbitration {
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
    }
}

.section--how-it-works {
    margin-bottom: 120px;

    .section__title {
        margin-bottom: 64px;
    }

    &__tab-content:not(.active) {
        display: none;
    }

    &__tab-titles {
        --liner-width: 0px;
        --liner-left-offset: 0px;
        margin-bottom: 64px;
        margin-top: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid colors.$lightGray;
        position: relative;

        @include m.pseudo(after) {
            position: absolute;
            top: 100%;
            left: var(--liner-left-offset);
            height: 2px;
            width: var(--liner-width);
            background-color: colors.$sapphire;
            transition: left .2s ease, width .4s ease;
        }
    }

    &__tab-title {
        padding: 20px 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: colors.$ebony;
        transition: .2s ease;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 48px;
        }

        &:hover {
            color: rgba(colors.$sapphire, .8);
        }

        &.active {
            color: colors.$sapphire;
        }
    }

    a {
        color: colors.$sapphire;

        &:hover {
            text-decoration: none;
        }
    }

    &__steps {
        counter-reset: steps;
        max-width: 936px;
        width: 100%;
        margin: 0 auto;
    }

    &__step {
        counter-increment: steps;
        display: flex;
        align-items: flex-start;
        font-size: 20px;
        line-height: 30px;
        color: colors.$black;
        @include m.pseudo(before, 'Step ' counter(steps)) {
            width: 240px;
            min-width: 240px;
            font-size: 24px;
            line-height: 36px;
            color: colors.$kleinBlue;
            text-transform: uppercase;
        }

        &:not(:last-child) {
            margin-bottom: 120px;
        }
    }

    blockquote {
        padding: 24px;
        background-color: colors.$alabaster;
        color: colors.$sapphire;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;

        a {
            color: inherit;

            &:hover {
                text-decoration: none;
            }
        }
    }

    h2, h3, h4, h5, h6 {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: colors.$kleinBlue;
        margin-top: 24px;
    }

    p:not(.bigger-font) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 4px;
        color: colors.$ebony;
    }

    p + p {
        margin-top: 24px;
    }

    blockquote + p, blockquote + p:not(.bigger-font) {
        margin-top: 24px
    }

    &__scenarios {
        margin-top: 24px;
        counter-reset: scenarios;

        & + p {
            margin-top: 24px;
        }
    }

    &__scenario {
        counter-increment: scenarios;
        font-size: 16px;
        line-height: 24px;
        color: colors.$ebony;
        @include m.pseudo(before, 'Scenario ' counter(scenarios)) {
            display: block;
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 4px;
            color: colors.$kleinBlue;
        }

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}

.section--legal-info {
    background-color: colors.$lightGray;
    padding: 120px;
    margin-bottom: 120px;

    @media (max-width: 1199px) {
        padding: 90px;
    }

    @media (max-width: 1079px) {
        padding: 48px 36px;
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: auto auto;
        gap: 24px 84px;
        @media (max-width: 1199px) {
            gap: 24px 36px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        border-bottom: 2px solid colors.$black;
        padding: 24px 0 22px 0;
        color: colors.$black;
        text-decoration: none;
        cursor: pointer;
        transition: .2s ease;

        &:hover {
            color: colors.$ebony;
        }

        .svg-icon {
            width: 24px;
            min-width: 24px;
            height: 24px;
            margin-left: 24px;
        }
    }
}

.section--still-q {
    margin-bottom: 104px;

    &__links {
        margin-top: 12px;
    }

    &__link {
        padding: 12px;
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: colors.$sapphire;
        text-decoration: none;
        transition: .2s ease;

        &:hover {
            opacity: .8;
        }

        .svg-icon {
            width: 16px;
            min-width: 16px;
            height: 16px;
            margin-left: 10px;
        }
    }
}

.section--lawyers {
    margin: 48px 0 200px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    gap: 24px;

    @media (max-width: 1199px) {
        grid-template-columns: minmax(0, 1fr);
    }

    &__item {
        padding: 36px;
        display: grid;
        grid-template-columns: 180px 1fr;
        gap: 24px;
        background-color: colors.$white;

        @media (max-width: 1199px) {
            align-items: center;
        }
    }

    &__name {
        @extend %forumFont;
        font-size: 30px;
        line-height: 34px;
        color: colors.$kleinBlue;
        margin-bottom: 16px;
    }

    &__domains {
        font-size: 16px;
        line-height: 24px;
        color: colors.$cadetGray;
        margin-bottom: 16px;
    }

    &__descr {
        font-size: 14px;
        line-height: 22px;
        color: colors.$black;
    }
}

.section--faq {
    margin-top: 8px;
    margin-bottom: 200px;

    &__tab-select-mobile {
        display: none;
        margin-bottom: 26px;

        .custom-select {
            min-width: 272px;
        }

        .custom-select-current {
            padding: 16px 56px 16px 20px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__tab-titles {
        margin-bottom: 24px;
        display: flex;
        position: relative;
        border-bottom: 2px solid colors.$lightGray;
        @include m.pseudo(before) {
            position: absolute;
            height: 2px;
            width: var(--liner-width);
            top: 100%;
            left: var(--liner-left-offset);
            background-color: colors.$sapphire;
            transition: left .2s ease, width .4s ease;
        }
    }

    &__tab-title {
        padding: 16px 0 14px;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: colors.$ebony;
        transition: .2s ease;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 48px;
        }

        &:hover {
            color: rgba(colors.$sapphire, .8);
        }

        &.active {
            color: colors.$sapphire;
        }
    }

    &__tab-content:not(.active) {
        display: none;
    }

    &__q {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        cursor: pointer;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: colors.$black;
        @include m.pseudo(after) {
            @include m.bg-img(contain, 50%);
            background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 7.875V28.125' stroke='%23000009' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M28.125 18H7.875' stroke='%23000009' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            width: 36px;
            min-width: 36px;
            height: 36px;
            transition: .2s ease;
        }
    }

    &__a {
        color: colors.$black;
        font-size: 16px;
        line-height: 24px;
        max-height: 0px;
        overflow: hidden;
        transition: .2s ease;
        transform: scaleY(0);
    }

    &__item {
        padding: 36px;
        border-bottom: 2px solid rgba(colors.$lightGray, .5);
        transition: .2s ease;

        &.active {
            background-color: colors.$white;

            .section--faq__q {
                color: colors.$sapphire;

                &::after {
                    transform: rotate(45deg);
                }
            }

            .section--faq__a {
                max-height: 2000px;
                transform: scaleY(1);
                margin-top: 36px;
            }
        }
    }
}


// Upload arbitration clause page Sections
.upload-how {
    margin-bottom: 240px;

    &--home {
        margin-bottom: 0;
        padding-top: 240px;

        @media (max-width: 1199px) {
            padding-top: 96px;
        }
    }

    &__title {
        text-align: center;
        @media (min-width: 1200px) {
            text-align: left;
        }
    }

    &__descr {
        margin-bottom: 48px;
        text-align: center;
        @media (min-width: 1200px) {
            text-align: left;
        }
    }


    &__cols {
        justify-content: space-between;
        padding: 48px 24px;
        gap: 24px;
        background-color: colors.$lightGray;
        width: max-content;
        max-width: 100%;
        margin-inline: auto;

        @media (min-width: 768px) {
            padding: 96px 48px;
        }

        @media (min-width: 1200px) {
            display: flex;
            width: 100%;
        }

        @media (min-width: 1500px) {
            padding: 120px 64px;
        }
    }

    &__col {
        max-width: 360px;
        width: 100%;
    }

    &__col + &__col {
        margin-top: 36px;
        @media (min-width: 1200px) {
            margin-top: 0;
        }
    }

    &__name {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.5px;
        padding-bottom: 16px;
        border-bottom: 2px solid colors.$black;

        @media (min-width: 768px) {
            font-size: 36px;
            line-height: 48px;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 24px;
        padding-top: 16px;
    }

    &__buttons {
        margin-top: 36px;

        .btn + .btn {
            margin-left: 24px;
        }
    }
}

.contract-clauses {
    max-width: 936px;
    width: 100%;
    margin-inline: auto;
    margin-bottom: 240px;

    &__hint {
        padding: 24px;
        font-size: 16px;
        line-height: 24px;
    }

    &__items {
        margin-top: 48px;
    }

    &__item {
        background-color: colors.$white;
        padding: 24px;
    }

    &__name {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
    }

    &__text {
        font-size: 16px;
        line-height: 24px;
    }

    &__buttons {
        margin-top: 16px;
        display: flex;
        align-items: center;

        > * + * {
            margin-left: 16px;
        }

        .btn {
            min-width: 143px;
        }
    }

    &__item + &__item {
        margin-top: 24px;
        @media (min-width: 768px) {
            margin-top: 48px;
        }
    }
}

.contract-templates {
    margin-bottom: 240px;

    &__title {
        margin-bottom: 18px;
    }

    &__items {
        display: block;
        max-width: 100%;
        width: max-content;
        margin-inline: auto;
        @media (min-width: 1080px) {
            display: flex;
            width: auto;
        }
    }

    &__item {
        color: colors.$black;
        text-decoration: none;
        display: flex;
        align-items: center;
        min-width: 336px;
        padding: 18px 0;
        border-bottom: 2px solid currentColor;
        cursor: pointer;
        transition: .2s ease;

        &:hover {
            color: colors.$sapphire
        }
    }

    &__icon {
        width: 36px;
        min-width: 36px;
        height: 36px;
        margin-right: 10px;
    }

    &__name {
        margin-right: 10px;
    }

    &__arrow {
        width: 16px;
        min-width: 16px;
        height: 16px;
        margin-left: auto;
    }

    &__item + &__item {
        margin-top: 36px;
        @media (min-width: 1080px) {
            margin-left: 84px;
            margin-top: 0;
        }
    }
}

.peek {
    padding: 36px;
    margin-bottom: 120px;

    @media (min-width: 1200px) {
        padding: 120px;
    }

    &__title {
        @extend %forumFont;
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 30px;

        @media (min-width: 1200px) {
            font-size: 48px;
            line-height: 56px;
        }
    }

    &__descr {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        opacity: .5;
        margin-bottom: 30px;
    }
}


// Work With Us Page
.work-with-us {
    display: grid;
    grid-template-columns: 1fr 576px;
    gap: 64px;
    margin-bottom: 200px;

    @media (max-width: 1199px) {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    &__image {
        @media (max-width: 1199px) {
            order: -1;
            margin-bottom: 24px;
            max-width: 768px;
        }
    }

    &__text {
        font-size: 24px;
        line-height: 36px;
        color: colors.$black;
        margin-bottom: 36px;

        p + p {
            margin-top: 36px;
        }
    }

    &__button-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: colors.$spaceCadet;
        margin-bottom: 24px;
    }
}

// Terms
.terms {
    margin-bottom: 200px;

    &__title, h2, h3, h4, h5, h6 {
        text-align: center;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: colors.$kleinBlue;
        margin-bottom: 16px;
    }

    p + p {
        font-size: 14px;
        line-height: 22px;
        margin-top: 22px;
    }

    p + &__title, p + h2, p + h3, p + h4, p + h5, p + h6 {
        margin-top: 64px;
    }
}

// Auth

.auth-page {
    padding: 120px 0 140px;
    min-height: 100vh;
    background-color: colors.$sapphire;
    color: colors.$white;

    &__layout {
        display: block;
        grid-template-columns: 1fr 576px;
        gap: 36px;

        @media (min-width: 1080px) {
            display: grid;
        }
    }

    &__logo-link {
        width: 216px;
        height: 157px;
        @include m.bg-img(contain, 50%);
        background-image: url('../img/logo_white_vector.svg');
        display: block;
        margin: 0 auto 48px auto;

        @media (min-width: 1080px) {
            margin: 0 0 64px 0;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 44px;
        line-height: 50px;
        color: colors.$carrotOrange;
        margin-bottom: 10px;
    }

    &__subtitle {
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
        letter-spacing: -.5px;
        color: colors.$white;
        margin-bottom: 24px;

        span {
            opacity: .5;
            display: inline-block;
        }
    }

    &__descr {
        font-size: 16px;
        line-height: 24px;
        color: colors.$lightGray;
    }

    &__content {
        color: colors.$black;
        background-color: colors.$white;
        padding: 48px;

        max-width: 576px;
        margin: 0 auto;

        @media (min-width: 1080px) {
            margin: 0;
            max-width: initial;
        }
    }

    @media (max-width: 1079px) {
        &__title, &__subtitle, &__descr {
            display: none;
        }
    }
}

.auth {
    &__tab-titles {
        --liner-width: 0px;
        --liner-left-offset: 0px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        position: relative;

        justify-content: center;

        @media (min-width: 1080px) {
            justify-content: initial;
        }

        @include m.pseudo(after) {
            position: absolute;
            top: 100%;
            left: var(--liner-left-offset);
            height: 2px;
            width: var(--liner-width);
            background-color: colors.$sapphire;
            transition: left .2s ease, width .4s ease;
        }
    }

    &__tab-title {
        padding: 0 0 14px;
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
        color: colors.$ebony;
        transition: .2s ease;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 48px;
        }

        &:hover {
            color: rgba(colors.$sapphire, .8);
        }

        &.active {
            color: colors.$sapphire;
        }
    }

    &__non-tab-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 24px;
        @media (min-width: 768px) {
            font-size: 36px;
            line-height: 48px;
        }
    }

    &__tab-content:not(.active) {
        display: none;
    }

    &__descr {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 24px;
        @media (min-width: 768px) {
            margin-bottom: 48px;
        }
    }

    &__input-label + &__input-label {
        margin-top: 24px;
    }

    &__submit-line {
        margin-top: 24px;
    }

    &__terms-checkbox {
        margin-right: 24px;
    }

    &__forgot {
        font-size: 16px;
        line-height: 24px;
        color: colors.$spaceCadet;

        &:hover {
            text-decoration: none;
        }
    }

    &__who {
        margin-bottom: 24px;

        .name {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: colors.$cadetGray;
            margin-bottom: 10px;
        }

        .radio + .radio {
            margin-left: 70px;
        }
    }

    &__inputs-line {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        .auth__input-label {
            margin-top: 0;
        }

        + .auth__input-label {
            margin-top: 24px;
        }
    }

    &__remembered {
        display: block;
        width: max-content;
        order: 99;
        margin-inline: auto;
        margin-top: 24px;
        font-size: 16px;
        line-height: 24px;
        text-decoration: underline;
        color: colors.$spaceCadet;

        &:hover {
            text-decoration: none;
        }

        @media (min-width: 500px) {
            order: 0;
            margin-top: 0;
            margin-inline: 0;
        }
    }
}


// Internal Pages
// Internal Pages Common Styles
.internal-layout {
    @media (min-width: 1080px) {
        display: grid;
        align-items: start;
        grid-template-columns: 336px minmax(0, 1fr);
        gap: 24px;
        margin-top: 32px;
        margin-bottom: 200px;
    }

    &__sidebar, &__content {
        background-color: colors.$white;
        padding: 24px;

        @media (min-width: 1080px) {
            padding: 36px;
        }
    }

    &__sidebar {
        display: none;
        @media (min-width: 1080px) {
            display: block;
        }
    }

    &__content {
        // header + top nav + bottom nav 80+55+65 = 200
        min-height: calc(100vh - 200px);
        display: flex;
        flex-direction: column;
        margin-bottom: 66px;
        @media (min-width: 1080px) {
            min-height: unset;
            display: block;
            margin-bottom: unset;
        }

        &.personal-info .internal-page-title {
            display: none;
            @media (min-width: 1080px) {
                display: block;
            }
        }
    }

    .new-cases--empty {
        flex-grow: 1;
        @media (min-width: 1080px) {
            flex-grow: initial;
        }
    }

    &__add {


        @media (min-width: 1200px) {
            display: grid;
            grid-template-columns: minmax(0, 1fr) 200px;
            gap: 60px;
        }

        @media (min-width: 1500px) {
            grid-template-columns: minmax(0, 1fr) 300px;
        }
    }

    &__add-sidebar {
        display: none;

        position: relative;

        &-inner {
            position: sticky;
            top: 0;
            z-index: 3;
        }

        @media (min-width: 1200px) {
            display: block;
        }
    }

    &__buttons {
        margin-top: 24px;
    }

    > *:last-child {
        align-self: stretch
    }
}

.add-new, .award {
    &__steps {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 48px;

        &.no-padding-top {
            padding-top: 0;
        }
    }

    &__step {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        line-height: 24px;
        color: colors.$cadetGray;
        transition: .2s ease;
        padding: 8px 0;

        &::before {
            content: '';
            width: 16px;
            min-width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: colors.$lightGray;
            margin-right: 8px;
            position: relative;
            z-index: 2;
        }

        &:not(:last-child)::after {
            content: '';
            width: 1px;
            height: 100%;
            background-color: colors.$lightGray;
            position: absolute;
            left: 7px;
            top: 50%;
            z-index: 1;
        }

        &.done {
            color: colors.$black;

            &::before {
                background-color: colors.$keppel;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.75 3L4.5 9L2.25 6.75' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-size: 12px 12px;
                background-position: 50%;
                background-repeat: no-repeat;
            }

            &:not(:last-child)::after {
                background-color: colors.$keppel;
            }
        }
    }
}

.add-form__preview, .award__preview {
    margin-top: 16px;
    border: 2px solid #000009;
    position: relative;
    cursor: pointer;

    &::before, &::after {
        position: absolute;
    }

    &::before {
        z-index: 1;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(colors.$black, .4);
        opacity: 0;
        transition: .2s ease;
    }

    &::after {
        z-index: 2;
        content: 'Preview';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: .2s ease;
        opacity: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: colors.$sapphire;
        border: 1px solid colors.$lightGray;
        background-color: colors.$white;
        border-radius: 48px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 36px;
    }

    &:hover {
        &::before, &::after {
            opacity: 1;
        }
    }
}


.sidebar {
    &__profile {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid colors.$alabaster;

        .name {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: colors.$black;
        }

        .edit {
            font-size: 16px;
            line-height: 24px;
            color: colors.$sapphire;
            transition: .2s ease;
            text-decoration: none;

            &:hover {
                color: colors.$spaceCadet
            }
        }
    }

    &__add-new-btn {
        width: 100%;
        margin-bottom: 10px;
    }

    &__nav {
        .link {
            position: relative;
            font-size: 16px;
            line-height: 24px;
            color: colors.$black;
            transition: .2s ease;
            display: block;
            padding: 16px;
            text-decoration: none;

            &:hover {
                color: rgba(colors.$sapphire, .7)
            }

            &.active {
                color: colors.$sapphire;

                &::before {
                    content: '';
                    width: 4px;
                    min-width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: colors.$sapphire;
                    position: absolute;
                    left: 0;
                    top: calc(50% - 2px);
                }
            }

            &--sign-out {
                margin-top: 17px;

                &::before {
                    content: '';
                    position: absolute;
                    top: -9px;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background-color: colors.$alabaster;
                }
            }
        }
    }
}

.internal-page-descr {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: colors.$black;
    margin-bottom: 16px;

    &--type2 {
        font-weight: normal;
        margin-bottom: 24px;
    }
}

// Internal Pages Specific Styles
.title-line {
    margin-bottom: 24px;

    .title {
        margin-bottom: 0;
    }

    &__back-link {
        display: none;
        text-decoration: none;
        transition: .2s ease;
        margin-right: 12px;
        width: 36px;
        min-width: 36px;
        height: 36px;
        cursor: pointer;
        color: colors.$black;

        &:hover {
            opacity: .7;
        }

        @media (min-width: 1080px) {
            display: flex;
        }
    }
}

.platform-hint {
    background-color: colors.$alabaster;
    color: colors.$kleinBlue;
    margin-bottom: 24px;
    padding: 16px;
    font-size: 14px;
    line-height: 22px;

    @media (min-width: 768px) {
        padding: 24px;
        font-size: 16px;
        line-height: 24px;
    }
}

// New Cases
.new-cases {
    &--empty {
        height: 100%;

        .internal-page-title,
        .internal-page-descr {
            margin-bottom: 0;
        }

        .internal-page-descr {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
        }

        .empty-icon {
            margin-bottom: 12px;
            color: colors.$beauBlue;
        }

        .btn--add-new-case {
            margin-top: 16px;
            width: 216px;
        }
    }

    &--add {
        .title-line {
            margin-bottom: 0;
            @media (min-width: 1080px) {
                margin-bottom: 24px;
            }

            .internal-page-title {
                margin-bottom: 0;
            }

            .new-cases__save-as-draft-btn {
                min-width: 125px;
            }
        }

        .choose {
            &__descr {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: colors.$cadetGray;
                margin-bottom: 16px;

                @media (min-width: 600px) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            &__radio {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 24px;
            }

            &__option-label {
                display: block;
            }

            &__option-content {
                display: block;
                background-color: colors.$snow;
                padding: 24px;
                border-bottom: 1px solid colors.$spaceCadet;
                cursor: pointer;
                position: relative;
                transition: background-color 300ms ease-out;

                @media (min-width: 375px) {
                    padding: 36px 36px 50px 36px;
                }

                @include m.pseudo(before, '') {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: colors.$spaceCadet;
                    transform: scaleY(0);
                    transform-origin: bottom;
                    transition: transform 300ms ease-out;
                    z-index: 0;
                }
            }

            &__icon {
                position: relative;
                z-index: 1;
                display: block;
                width: 48px;
                height: 48px;
                margin-bottom: 29px;
                color: colors.$spaceCadet;
                transition: color 300ms ease-out;
            }

            &__option-input {
                position: absolute;
                width: 0;
                height: 0;
            }

            &__option-name {
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                color: colors.$black;
                transition: color 300ms ease-out;
                @include m.pseudo(before, '') {
                    display: none;
                    @media (min-width: 600px) {
                        display: block;
                    }

                    width: 24px;
                    height: 24px;
                    box-shadow: inset 0 0 0 2px colors.$lightGray;
                    border-radius: 50%;
                    background-color: rgba(0, 0, 0, 0);
                    transition: box-shadow 300ms ease-out;
                    margin-right: 12px;
                }
            }

            &__option-input:checked + .choose__option-content {
                &::before {
                    transform: scaleY(1)
                }

                .choose__icon {
                    color: colors.$keppel;
                }

                .choose__option-name {
                    color: colors.$white;

                    &::before {
                        box-shadow: inset 0 0 0 7px colors.$white;
                    }
                }
            }
        }

        .continue-line {
            margin-top: 24px;

            .btn {
                width: 100%;
                @media (min-width: 600px) {
                    width: auto;
                }
            }

            .btn + .btn {
                margin-top: 24px;
                @media (min-width: 600px) {
                    margin-top: 0;
                    margin-left: 24px;
                }
            }
        }
    }
}

// Add Form
.add-form {

    &__section-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: colors.$black;
        margin-bottom: 16px;
    }

    &__item + &__section-title {
        margin-top: 96px;
    }

    // Helpers
    .mt {
        margin-top: 24px;
    }

    .mt-big, & &__item.mt-big {
        margin-top: 64px;
    }

    .mb {
        margin-bottom: 24px;
    }

    .mb-big {
        margin-bottom: 64px;
    }

    .single-half {
        @media (min-width: 600px) {
            width: calc(50% - 12px);
        }
        position: relative;

        .remove-added-line {
            position: absolute;
            top: 50%;
            left: calc(100% + 24px);
            transform: translateY(-50%);
        }
    }

    .inputs-line {
        display: block;

        > * + * {
            margin-top: 16px;
        }

        @media (min-width: 600px) {
            display: grid;
            > * + * {
                margin-top: 0;
            }
        }
    }

    .add-form__checkbox-switch + .add-form__checkbox-switch {
        margin-top: 16px;
    }

    &__claymant-info {
        margin: 64px 0;
        padding-bottom: 16px;
        border-bottom: 1px solid colors.$lightGray;
    }

    &__claymant-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: colors.$black;
        margin-bottom: 10px;
    }

    &__claymant-line {
        font-size: 16px;
        line-height: 24px;
    }

    &__claymant-name {
        color: colors.$black;
    }

    &__claymant-addr {
        color: colors.$ebony;
    }

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: colors.$black;
        margin-bottom: 16px;
    }

    &__subtitle {
        word-break: break-word;
        margin-bottom: 16px;

        &.req::after {
            content: '*';
            color: colors.$coquelicot
        }
    }

    &__item {
        display: flex;
        flex-direction: column;

        & + & {
            margin-top: 24px;
        }
    }

    .continue-line {
        margin-top: 46px;
        flex-direction: column-reverse;

        > * + * {
            margin-bottom: 16px;
        }

        @media (min-width: 768px) {
            flex-direction: row;
            > * + * {
                margin-bottom: 0;
            }
        }

        .save-draft-btn {
            display: none;
            @media (min-width: 1080px) {
                display: inline-block;
            }
        }

        .mobile-preview-btn {
            @media (min-width: 1080px) {
                display: none;
            }
        }
    }
}

.inputs-add + .add-form__item {
    margin-top: 24px;
}

// Claim Form Preview Modal
.claim-modal .vm--modal {
    background-color: rgba(0, 0, 0, 0);
    @media (min-width: 1080px) {
        background-color: white;
    }
}

.statement-preview {
    max-height: calc(100% - 68px);
    max-width: 100%;
    overflow: auto;
    padding: 24px;

    * {
        max-width: 100%;
    }

    @media (min-width: 1080px) {
        overflow: initial;
        max-height: initial;
        max-width: 764px;
        padding: 48px 64px;
    }
}

.claim-modal__inner {
    color: colors.$black;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(100% - 92px);
    background-color: colors.$white;

    @media (min-width: 1080px) {
        overflow: auto;
        position: relative;
        height: 100%;
        padding: 48px calc((100% - 960px) / 2);
    }

    &::before {
        content: 'Claim preview';
        position: absolute;
        height: 68px;
        width: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-inline: 84px;
        background-color: colors.$snow;

        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        @media (min-width: 1080px) {
            display: none;
        }
    }

    &::after {
        content: "";
        bottom: calc(100% + 10px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: #FFFFFF;
        height: 4px;
        width: 120px;
        border-radius: 6px;
        pointer-events: none;
        @media (min-width: 1080px) {
            display: none;
        }
    }

    .close {
        position: absolute;

        width: 36px;
        height: 36px;
        color: colors.$cadetGray;
        transition: .2s ease;
        cursor: pointer;

        .svg-icon {
            width: 100%;
            height: 100%;
        }

        &:hover {
            color: colors.$black;
            transform: rotate(180deg);
        }

        top: 16px;
        right: 24px;

        @media (min-width: 1080px) {
            position: fixed;
            top: 48px;
            right: calc((100% - 960px) / 2);
        }
    }

    h2 {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
    }

    h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 10px;
    }

    h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;
    }

    h4, p {
        padding-left: 36px;
    }

    cite {
        display: block;
        padding-left: 60px;
        font-size: 14px;
        line-height: 22px;
        color: colors.$cadetGray;
        padding-bottom: 16px;
    }
}

//  Personal Info (+ Complete Registration)
.personal-info {

    &__subtitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 16px;
        @media (min-width: 768px) {
            margin-bottom: 24px;
        }
    }

    * + &__subtitle {
        margin-top: 36px;
    }

    &__lawyer-hint + &__subtitle {
        margin-top: 0;
    }

    &__lawyer-hint {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;

        @media (min-width: 768px) {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
        }
    }

    &__form {
        max-width: 480px;
        width: 100%;
    }

    &__inputs-line + &__inputs-line,
    &__inputs-line + &__input-label,
    &__input-label + &__inputs-line,
    &__input-label + &__input-label,
    &__input-label + .multiselect,
    .multiselect + &__input-label,
    &__input-label + .input-label,
    .input-label + &__input-label {
        margin-top: 16px;
        @media (min-width: 600px) {
            margin-top: 24px;
        }
    }

    &__inputs-line {
        @media (min-width: 600px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 24px;
        }

        .personal-info__input-label, .input-label, .multiselect {
            @media (min-width: 600px) {
                margin: 0
            }
        }
    }

    &__submit-line {
        margin-top: 24px;
        flex-direction: column-reverse;

        > * + * {
            margin-bottom: 28px;
        }

        > * {
            width: 100%;
            text-align: center;
        }

        @media (min-width: 600px) {
            flex-direction: row;
            > * + * {
                margin-bottom: 0;
            }
            > * {
                width: unset;
            }
        }
    }

    &__change-password {
        text-decoration: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: colors.$cadetGray;
        transition: .2s ease;

        &:hover {
            color: colors.$spaceCadet
        }
    }

    &__save {
        min-width: 130px;
    }

    &--complete-registration &__save {
        min-width: 185px;
        margin-left: auto;
    }
}

// Cases

.case + .cases__who-title {
    margin-top: 24px;
}

.case {
    text-decoration: none;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid colors.$lightGray;
    display: grid;
    //grid-template-columns: 104px 180px 220px 220px 140px 1fr;

    grid-auto-rows: auto;
    grid-auto-flow: row;
    gap: 10px;


    // Case Type-specific
    &--new, &--ongoing, &--closed {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 20px;

        grid-template-areas:
            'number names notes'
            'status status status'
            'deadline date date';

        @media (min-width: 1500px) {
            //grid-template-columns: 104px 190px 220px 200px 90px 1fr 20px;
            grid-template-columns: 104px 224px 220px 200px 1fr 20px;
            grid-template-areas: 'number names status deadline date notes';
            gap: 20px;
        }
    }

    &--draft {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 16px;

        grid-template-areas:
            'number names names'
            'status date trash';

        @media (min-width: 1500px) {
            //grid-template-columns: 104px 190px 220px 200px 90px 1fr 20px;
            grid-template-columns: 104px 224px 220px 200px 1fr 16px;
            grid-template-areas: 'number names status . date trash';
            gap: 20px;
        }
    }

    &__number {
        white-space: nowrap;
        font-size: 16px;
        line-height: 24px;
        color: colors.$sapphire;
        grid-area: number;

        a {
            text-decoration: none;
            color: inherit
        }
    }

    &__names {
        font-size: 14px;
        line-height: 22px;
        color: colors.$black;
        grid-area: names;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        text-align: right;
        @media (min-width: 1500px) {
            text-align: left;
        }
        //grid-column: 2/4;
        //@media (min-width: 1500px) { grid-column: auto; }
    }

    &__status {
        justify-self: start;

        grid-area: status;

        //grid-column: 1/4;
        //@media (min-width: 1500px) { grid-column: auto; }
    }

    &__deadline {
        font-size: 14px;
        line-height: 22px;
        color: colors.$ebony;

        grid-area: deadline;

        span {
            font-weight: 500;
            color: colors.$black;
        }
    }

    &__date {
        font-size: 14px;
        line-height: 22px;
        color: colors.$cadetGray;

        grid-area: date;

        text-align: right;
        @media (min-width: 1500px) {
            text-align: left;
        }
    }

    &__contract-type {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: colors.$cadetGray;

        grid-area: status;

        //grid-column: 1/4;
        //@media (min-width: 1500px) { grid-column: auto; }
    }

    &__notes {
        grid-area: notes;

        .count {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: colors.$carrotOrange;
            color: colors.$white;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
        }
    }

    &__additional {
        grid-area: additional;
    }

    &__trash {
        grid-area: trash;
    }
}

.internal-page-title.new-cases__title {
    display: none;
    @media (min-width: 1200px) {
        display: block;
    }
}

.new-cases__toggler {
    margin-inline: auto;
    margin-bottom: 16px;
    @media (min-width: 1200px) {
        margin: 0;
    }
}

// Case Status Labels
.status {
    padding: 4px 16px;
    font-size: 14px;
    line-height: 22px;
    border-radius: 30px;

    &--waiting {
        background-color: colors.$melon;
        color: colors.$carrotOrange;
    }

    &--assigned {
        background-color: colors.$lightCyan;
        color: colors.$keppel;
    }

    &--won {
        background-color: colors.$beauBlue;
        color: colors.$sapphire;
    }

    &--lost {
        background-color: colors.$mistyRose;
        color: colors.$coquelicot;
    }

    &--draft {
        background-color: colors.$alabaster;
        color: colors.$cadetGray;
    }
}

// Case Page
.case-page {

    &__box {
        padding: 12px;
        margin: -6px -12px;
        background-color: colors.$snow;
        flex-grow: 1;
    }

    &__split {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        gap: 60px;
        display: block;

        @media (min-width: 1500px) {
            display: grid;
        }

        @media (max-width: 1499px) {
            &.active-col-0 {
                & > *:nth-child(2) {
                    display: none;
                }
            }
            &.active-col-1 {
                & > *:nth-child(1) {
                    display: none;
                }
            }
        }

        @media (min-width: 1500px) {
            gap: 120px;
        }

        &.bb:not(:last-child) .case-page__splitted-col {
            padding-bottom: 16px;
            border-bottom: 1px solid colors.$lightGray;
        }

        .case-page__evidence-file {
            word-break: break-all;
            white-space: normal;
            max-width: calc(100% - 120px);
            width: max-content;
        }
    }

    &__details-mobile-toggler {
        width: max-content;
        margin-inline: auto;
        margin-bottom: 16px;
        @media (min-width: 1500px) {
            display: none;
        }
    }

    &__split &__subitem {
        margin-top: 0;
        border-bottom: 0;
    }

    &__topline {
        width: 100%;
        margin-bottom: 24px;
        display: block;

        & > * + * {
            margin-top: 10px;
            width: max-content;
        }

        @media (min-width: 768px) and (max-width: 1079px), (min-width: 1200px) {
            display: flex;
            & > * + * {
                margin-top: 0;
                width: auto;
            }
        }
    }

    &__date {
        font-size: 14px;
        line-height: 22px;
        color: colors.$cadetGray;
        margin-right: auto;
    }

    &__pre-status {
        font-size: 14px;
        line-height: 22px;
        margin-right: 24px;
        color: colors.$ebony;
    }

    &__status {
        margin-right: 36px;
    }

    &__deadline {
        font-size: 14px;
        line-height: 22px;
        color: colors.$ebony;
    }

    &__item + &__item {
        margin-top: 64px;
    }

    &__subitem + &__subitem {
        margin-top: 24px;
    }

    &__item, &__subitem {
        padding-bottom: 16px;
        border-bottom: 1px solid colors.$lightGray;
    }

    &__subitem:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }

    &__title {
        display: inline-flex;
        align-items: center;
        text-transform: uppercase;
        color: colors.$kleinBlue;
        margin-bottom: 16px;

        font-size: 16px;
        line-height: 24px;

        @media (min-width: 768px) {
            font-size: 24px;
            line-height: 36px;
        }

        &--togglable {
            position: relative;
            cursor: pointer;

            &:hover {
                opacity: .8;
            }

            @include m.pseudo(before, '') {
                background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.125 18H7.875' stroke='%23173583' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                @include m.bg-img(contain, 50%);
                width: 24px;
                min-width: 24px;
                height: 24px;
                margin-right: 10px;
                transition: .3s ease;


                @media (min-width: 768px) {
                    width: 36px;
                    min-width: 36px;
                    height: 36px;
                    margin-right: 12px;
                }
            }
            @include m.pseudo(after, '') {
                background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 28.125L18 7.875' stroke='%23173583' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                @include m.bg-img(contain, 50%);
                width: 24px;
                min-width: 24px;
                height: 24px;
                position: absolute;
                left: 0;
                top: 0;
                transition: .3s ease;

                @media (min-width: 768px) {
                    width: 36px;
                    min-width: 36px;
                    height: 36px;
                }
            }

        }
    }

    &__item.active &__title--togglable {
        &::before {
            transform: rotate(-180deg);
        }

        &::after {
            transform: rotate(-270deg);
        }
    }

    &__split + &__split {
        margin-top: 24px;
    }

    &__subtitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: colors.$black;
        margin-bottom: 12px;
    }

    &__mini-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: colors.$cadetGray;
        margin-bottom: 6px;
    }

    &__descr {
        font-size: 14px;
        line-height: 22px;
        color: colors.$black;
    }


    &__parties {
        position: relative;
        display: block;
        @media (min-width: 1200px) {
            display: grid;
        }
    }

    &__parties-item {
        pointer-events: none;

        & > * {
            pointer-events: all
        }

        &:nth-child(2) {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;

            @media (min-width: 1200px) {
                position: static;
            }

            .case-page__parties-title {
                width: calc(50% - 24px);
                margin-left: auto;
                @media (min-width: 1200px) {
                    width: auto;
                }
            }
        }
    }

    &__parties-person {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        @media (min-width: 1200px) {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
        align-items: center;
        margin-bottom: 10px;
    }

    &__parties-name {
        font-size: 16px;
        line-height: 24px;
        color: colors.$black;
        min-width: 118px;
        margin-right: 24px;
    }

    &__parties-role {
        font-size: 16px;
        line-height: 24px;
        color: colors.$ebony;

        a, .email {
            font-size: 16px;
            line-height: 24px;
            text-decoration: none;
            transition: .2s ease;
            color: colors.$sapphire;
        }

        a:hover {
            opacity: .8;
        }
    }

    &__parties-details {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        @media (min-width: 1200px) {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
        align-items: center;
    }

    &__parties-title {
        transition: .2s ease;
        cursor: pointer;

        @media (min-width: 1200px) {
            cursor: default;
        }

        &:not(.active) {
            color: colors.$cadetGray;
            @media (min-width: 1200px) {
                color: colors.$black;
            }
        }

        &.active + .case-page__parties-person,
        &.active + .case-page__parties-person + .case-page__parties-details {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
    }

    &__mini-split {
        display: grid;
        grid-template-columns: minmax(0, max-content) minmax(0, max-content);
        gap: 24px;
        @media (min-width: 768px) {
            grid-template-columns: minmax(180px, max-content) minmax(180px, max-content);
        }
    }

    * + &__mini-title {
        margin-top: 24px;
    }

    &__subtitle + &__mini-title {
        margin-top: 0;
    }

    &__evidence {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media (min-width: 1500px) {
            flex-wrap: nowrap;
        }

        &.flex.fdc.aifs &-type {
            margin: 12px 0;
        }
    }

    &__evidence-type {
        width: 100%;
        margin-bottom: 6px;
        font-size: 14px;
        line-height: 22px;
        color: colors.$ebony;
        @media (min-width: 1500px) {
            width: auto;
            margin-right: 16px;
            margin-bottom: 0;
        }
    }

    &__mini-split {
        margin-top: 24px;
    }

    &__splitted-col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__splitted-col:nth-child(2) &__box {
        background-color: colors.$alabaster;
    }

    &__subitem + &__split {
        margin-top: 24px;
    }

    &__general-info {
        display: flex;
        flex-wrap: wrap;
        gap: 36px 15px;

        &-item {
            width: 25%;

            &--w50 {
                width: calc((100% - 15px) / 2);
            }

            &--w100 {
                width: 100%;
            }
        }

        &-value {
            div + div {
                margin-top: 10px;
            }

            &--flex {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;

                > span {
                    display: inline-block;
                    width: calc((100% - 10px) / 2);
                    @media (min-width: 1200px) {
                        width: calc((100% - 30px) / 4)
                    }
                    @media (min-width: 1500px) {
                        width: calc((100% - 40px) / 5)
                    }
                }
            }
        }
    }

    &__description {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: colors.$cadetGray;
        margin-bottom: 12px;
    }


    &__keywords {
        &-items {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 14px;
            gap: 10px 12px;
        }

        &-item {
            padding: 5px 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: colors.$ebony;
            background-color: colors.$alabaster;
            border-radius: 36px;
            text-align: center;
        }

        &-add {
            max-width: 624px;
        }
    }
}

.filename {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    color: colors.$ebony;
    @include m.pseudo(before, '') {
        width: 24px;
        min-width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.25 2.25H6.75C5.50736 2.25 4.5 3.25736 4.5 4.5V19.5C4.5 20.7426 5.50736 21.75 6.75 21.75H17.25C18.4926 21.75 19.5 20.7426 19.5 19.5V4.5C19.5 3.25736 18.4926 2.25 17.25 2.25Z' stroke='%232150AA' stroke-width='1.5' stroke-linejoin='round'/%3E%3Cpath d='M8.25 6H15.75' stroke='%232150AA' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.25 9.75H15.75' stroke='%232150AA' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.25 13.5H12' stroke='%232150AA' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        @include m.bg-img(contain, 50%);
        margin-right: 10px;
    }

    & + .download-file {
        text-decoration: underline;
        font-size: 14px;
        line-height: 22px;
        color: colors.$sapphire;
        margin-left: 24px;

        &:hover {
            text-decoration: none;
        }
    }
}

.case-page__save-as-draft-btn,
.new-cases__save-as-draft-btn {
    min-width: 125px;
    @media (max-width: 1079px) {
        font-size: 0;
        position: absolute;
        z-index: 9;
        top: 0;
        right: calc((100% - var(--internal-container-width)) / 2);
        min-width: 72px;
        width: 72px;
        padding: 0 24px 0 12px;
        border-radius: 0;
        transition: .00001s;

        display: flex;
        align-items: center;
        height: 64px;
        border: 0;
        background-color: colors.$white;
        &:hover {
            background-color: colors.$white;
        }

        &::before {
            content: 'SAVE';
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: colors.$sapphire;
            position: absolute;
            inset: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.internal-layout .title-line {
    @media (max-width: 1079px) {
        margin: 0;
    }
}

// Case Framework
.framework {
    background: colors.$white;
    border: 1px solid colors.$lightGray;
    padding: 36px;
    position: relative;
    margin-bottom: 24px;
    @include m.pseudo(after, '') {
        top: -1px;
        right: -1px;
        position: absolute;
        z-index: 2;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 39px 0 0 26px;
        border-color: transparent transparent transparent colors.$lightGray;
    }
    @include m.pseudo(before, '') {
        top: -1px;
        right: -1px;
        position: absolute;
        background-color: colors.$white;
        z-index: 1;
        width: 27px;
        height: 40px;
    }

    &__title {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: colors.$black;
        margin-bottom: 36px;
    }

    &__section + &__section {
        margin-top: 48px;
    }

    &__section-name {
        text-transform: uppercase;
    }

    &__section-name, &__section-descr {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: colors.$black;
    }

    &__section-descr {
        margin-bottom: 16px;
    }

    &__split {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        gap: 24px;

        > * {
            &:first-child {
                color: #000009;
            }

            &:nth-child(2) {
                color: colors.$cadetGray;
            }
        }
    }

    &__participant {
        font-size: 16px;
        line-height: 24px;

        &-email {
            color: colors.$sapphire;
        }
    }

    &__comment {
        font-size: 14px;
        line-height: 22px;
    }

    &__settlement {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }

    &__signature {
        font-size: 14px;
        line-height: 22px;

        &-date {
            margin-bottom: 10px;
        }
    }
}

.repeatable-case + .repeatable-case {
    margin-top: 36px;
}


// Negotioation Page
.how-to-btn {
    display: none;
    align-items: center;
    cursor: pointer;
    transition: .2s ease;
    @media (min-width: 1500px) {
        display: flex;
    }

    &__icon {
        margin-right: 10px;
        width: 36px;
        min-width: 36px;
        height: 36px;
    }

    &__text {
        font-size: 14px;
        line-height: 22px;
        color: colors.$sapphire;
        transition: .2s ease;
    }

    &:hover {
        opacity: .8;
    }

    &:hover &__text {
        color: colors.$spaceCadet
    }
}

.nego {
    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 6px;
        text-align: center;

        @media (min-width: 1080px) {
            text-align: left;
        }
    }

    &__session-line {
        display: flex;
        justify-content: space-between;
        position: relative;
        min-height: 42px;
        margin-bottom: 90px;
        gap: 18px;

        @media (min-width: 768px) and (max-width: 1079px), (min-width: 1200px) {
            margin-bottom: 24px;
            gap: 0;
        }
    }

    &__session-number {
        position: absolute;
        left: 50%;
        top: calc(100% + 24px);
        transform: translateX(-50%);
        padding: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        border: 1px solid colors.$lightGray;

        @media (min-width: 768px) and (max-width: 1079px), (min-width: 1200px) {
            top: 0;
        }
    }

    &__session-participant {
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        color: colors.$black;

        @media (min-width: 768px) and (max-width: 1079px), (min-width: 1200px) {
            display: flex;
        }

        @include m.pseudo(before, attr(data-letter)) {
            width: 24px;
            min-width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: colors.$black;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: colors.$white;
            margin-bottom: 4px;

            @media (min-width: 768px) and (max-width: 1079px), (min-width: 1200px) {
                margin-right: 12px;
                margin-bottom: 0;
            }
        }

        &.you {
            color: colors.$sapphire;
        }

        &.you::before {
            background-color: colors.$sapphire;
        }

        &:last-child {
            text-align: right;

            &::before {
                margin-left: auto;
            }

            @media (min-width: 768px) and (max-width: 1079px), (min-width: 1200px) {
                text-align: left;
                &::before {
                    margin-left: 0;
                }
            }
        }
    }

    &__hint {
        margin: 0 auto 0 auto;
        border-radius: 30px;
        padding: 4px 16px;
        font-size: 14px;
        line-height: 22px;
        width: max-content;
        max-width: 100%;
        position: relative;
        text-align: center;
        @include m.pseudo(before, '') {
            position: absolute;
            top: -24px;
            bottom: 100%;
            width: 1px;
            left: 50%;
            transform: translateX(-50%);
            background-color: colors.$lightGray;
        }
        //24
    }


    &__result {
        text-align: center;
        max-width: 576px;
        margin: 12px auto 0 auto;

        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        @media (min-width: 768px) {
            font-size: 24px;
            line-height: 36px;
        }

        &--success {
            color: colors.$keppel
        }

        &--fail {
            color: colors.$coquelicot
        }
    }

    &__final-amount {
        display: flex;
        align-items: flex-start;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: colors.$kleinBlue;

        padding: 24px;
        background-color: colors.$beauBlue;
        max-width: 500px;

        margin: 24px auto 0 auto;

        @include m.pseudo(before, '') {
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20ZM11.1625 8.235L8.3 8.59375L8.1975 9.06875L8.76 9.1725C9.1275 9.26 9.2 9.3925 9.12 9.75875L8.1975 14.0938C7.955 15.215 8.32875 15.7425 9.2075 15.7425C9.88875 15.7425 10.68 15.4275 11.0388 14.995L11.1487 14.475C10.8987 14.695 10.5337 14.7825 10.2913 14.7825C9.9475 14.7825 9.8225 14.5413 9.91125 14.1163L11.1625 8.235ZM10 6.875C10.3315 6.875 10.6495 6.7433 10.8839 6.50888C11.1183 6.27446 11.25 5.95652 11.25 5.625C11.25 5.29348 11.1183 4.97554 10.8839 4.74112C10.6495 4.5067 10.3315 4.375 10 4.375C9.66848 4.375 9.35054 4.5067 9.11612 4.74112C8.8817 4.97554 8.75 5.29348 8.75 5.625C8.75 5.95652 8.8817 6.27446 9.11612 6.50888C9.35054 6.7433 9.66848 6.875 10 6.875Z' fill='%23173583'/%3E%3C/svg%3E%0A");
            @include m.bg-img(contain, 50%);
            width: 20px;
            min-width: 20px;
            height: 20px;
            margin-right: 16px;
            margin-top: 2px;
        }
    }

    &__view-agreement-btn-wrapper {
        margin-top: 24px;
        margin-bottom: 40px;
    }

    &__subsliders-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: colors.$black;
        width: max-content;
        padding: 2px 10px;
        margin: 0 auto;
    }

    &__sliders-legend {
        margin-top: 22px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: colors.$ebony;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        grid-auto-rows: auto;
        gap: 10px;
        justify-content: space-between;

        @media (min-width: 768px) {
            display: flex;
            gap: 0;
            justify-content: space-around;
            align-items: center;
            white-space: nowrap;
        }

        .item {
            display: flex;
            align-items: flex-start;

            &:nth-child(3) {
                grid-column: 1/3;
            }
        }

        .dot {
            margin-top: 3px;
            width: 16px;
            min-width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 8px;
            background-color: wheat;
            border-style: solid;
            border-width: 4px;

            &--visible-bid {
                background-color: colors.$beauBlue;
                border-color: colors.$sapphire;
            }

            &--secret-bid {
                background-color: colors.$mistyRose;
                border-color: colors.$carrotOrange;
            }

            &--move-completed {
                background-color: colors.$lightCyan;
                border-color: colors.$keppel;
            }

        }
    }

    &__end-btn-wrapper {
        margin: 40px 0 48px;

        .btn {
            min-width: 138px;
        }
    }
}

.chat {
    margin-top: 48px;
    border: 1px solid colors.$lightGray;
    max-width: 624px;

    &__title-line {
        border-bottom: 1px solid colors.$lightGray;
        padding: 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000;
    }

    &__messages {
        padding: 12px 0;
        max-height: 342px;
        overflow: auto;
    }

    &__message {
        padding: 12px 24px;
        position: relative;
        @include m.pseudo(before, attr(data-letter)) {
            position: absolute;
            top: 12px;
            left: 24px;
            border-radius: 50%;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: colors.$white;
            background-color: colors.$cadetGray;
            width: 24px;
            height: 24px;
            margin-right: 12px;
        }

        > * {
            padding-left: 36px;
        }

        &.yours::before {
            background-color: colors.$sapphire;
        }
    }

    &__name-line {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 4px;
    }

    &__name {
        color: colors.$ebony;
        margin-right: 8px;

        &.you {
            color: colors.$sapphire
        }
    }

    &__datetime {
        color: colors.$cadetGray;
    }

    &__text {
        font-size: 14px;
        line-height: 22px;
        color: colors.$black;
    }

    &__date-divider {
        padding: 8px 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: colors.$sapphire;

        &::before, &::after {
            content: '';
            flex-grow: 1;
            height: 1px;
            background-color: colors.$beauBlue;
        }

        &::before {
            margin-right: 16px;
        }

        &::after {
            margin-left: 16px;
        }
    }

    &__input-message {
        border-top: 1px solid colors.$lightGray;
        position: relative;
    }

    &__submit-btn {
        color: colors.$sapphire;
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 16px;
        right: 20px;
        cursor: pointer;
        transition: .2s ease;

        &:hover {
            color: colors.$black
        }
    }

    &__input-label {
        display: block;
    }

    &__input-field {
        appearance: none;
        outline: 0;
        border-width: 0;
        width: 100%;
        padding: 17px 20px;
        transition: .2s ease;
        font-size: 14px;
        line-height: 22px;
        color: colors.$black;

        &::placeholder {
            color: colors.$cadetGray;
            font-size: 14px;
            line-height: 22px;
        }

        &:focus {
            box-shadow: 0 0 0 3px rgba(colors.$sapphire, .3);
        }
    }
}


// Lawyers Final Award
.award {
    counter-reset: award-items;

    &__item {
        counter-increment: award-items;

        &-title-line {
            display: flex;
            align-items: center;
            padding-block: 12px;
            border-bottom: 1px solid colors.$lightGray;
            cursor: pointer;
        }

        &-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: colors.$cadetGray;
            transition: color .2s ease;
            margin-right: 24px;

            &::before {
                content: counter(award-items) '. ';
            }
        }

        &-content {
            transition: .2s ease;
            transform: scaleY(0);
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            transform-origin: center top;
        }

        &.active &-content {
            opacity: 1;
            transform: scaleY(1);
            padding: 24px 0;
            max-height: max-content;
            border-bottom: 1px solid colors.$lightGray;
        }

        &-state {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-left: auto;
            user-select: none;

            &.color-cadetgray {
                display: none;
                @media (min-width: 768px) {
                    display: block;
                }
            }

            &.color-keppel {
                font-size: 0;
                line-height: 0;
                color: rgba(0, 0, 0, 0);
                min-width: 16px;
                width: 16px;
                height: 16px;
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='8' fill='%2329BDAB'/%3E%3Cpath d='M11.75 5L6.5 11L4.25 8.75' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-position: 50%;
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: -14px;
                @media (min-width: 768px) {
                    background: none;
                    width: max-content;
                    height: auto;
                    color: colors.$keppel;
                    font-size: 12px;
                    line-height: 16px;
                    margin-right: 0;
                }
            }

            & + .award__item-toggler {
                margin-left: auto;
                @media (min-width: 768px) {
                    margin-left: 24px;
                }
            }
        }

        &-toggler {
            position: relative;
            margin-left: 24px;
            cursor: pointer;
            display: block;
            width: 24px;
            min-width: 24px;
            height: 24px;

            &:before,
            &:after {
                content: '';
                position: absolute;
                background-color: colors.$cadetGray;
                transition: transform .2s ease;
                border-radius: 100px;
            }

            /* vertical line */
            &:before {
                top: 5px;
                left: 50%;
                width: 2px;
                height: 14px;
                margin-left: -1px;
            }

            /* horizontal line */
            &:after {
                top: 50%;
                left: 5px;
                width: 14px;
                height: 2px;
                margin-top: -1px;
            }
        }

        &.active &-toggler {
            &:before {
                transform: rotate(90deg);
                background-color: colors.$kleinBlue;
            }

            &:after {
                transform: rotate(180deg);
                background-color: colors.$kleinBlue;
            }
        }

        &.active &-title {
            color: colors.$kleinBlue
        }
    }

    &__court-of {
        font-size: 20px;
        line-height: 30px;
        color: colors.$cadetGray;
        text-align: center;
    }

    &__case-nr {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-top: 16px;
    }

    &__participants {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        color: colors.$cadetGray;
        margin-top: 44px;
    }

    &__participant {
        font-size: 16px;
        line-height: 24px;
        color: colors.$kleinBlue;
        margin-block: 4px;
    }

    &__mega-title {
        font-size: 64px;
        line-height: 80px;
        color: colors.$sapphire;
        margin-top: 92px;
        text-align: center;
    }

    &__date {
        font-size: 16px;
        line-height: 24px;
        color: colors.$spaceCadet;
        text-align: center;
    }

    &__issued {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin-top: 160px;
    }

    &__rendered {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: colors.$spaceCadet;
        padding-bottom: 24px;
        margin-top: 10px;
    }

    &__subitem {
        &-party {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
        }

        &-descr {
            font-size: 14px;
            line-height: 22px;
            border-bottom: 2px solid rgba(0, 0, 0, 0);
            transition: .2s ease;

            &[contenteditable="true"] {
                outline: 0;
                padding-bottom: 10px;
                border-bottom-color: colors.$sapphire;
            }

            /* textarea {
                padding: 0;
                height: max-content;
                appearance: none;
                border: 0;
                font-size: 14px;
                line-height: 22px;
                margin-top: 16px;
                resize: none;
                width: 100%;
            } */
        }

        &-edit-btn {
            margin-top: 10px;
            display: flex;
            align-items: center;
            width: max-content;

            font-size: 14px;
            line-height: 22px;
            color: colors.$sapphire;
            cursor: pointer;
            transition: .2s ease;

            &:hover {
                opacity: .8;
            }

            .svg-icon {
                margin-left: 4px;
                width: 16px;
                min-width: 16px;
                height: 16px;
            }
        }

        &-textarea {
            margin-top: 24px;
        }
    }

    &__subitem + &__subitem {
        margin-top: 48px;
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        margin-left: auto;
        margin-top: 24px;
    }

    &__upload-btn {
        min-width: 266px;
    }

    &__bottom-line {
        margin-top: 24px;
        font-size: 14px;
        line-height: 22px;
        color: colors.$ebony;
        display: block;

        > * + * {
            margin-top: 10px;
        }

        @media (min-width: 768px) {
            display: flex;
            > * + * {
                margin-top: 0;
                margin-left: 10px;
            }
        }
    }

    &__e-signature {
        margin-top: 12px;
        width: 100%;
    }

    &__bottom-buttons {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 24px;

        @media (min-width: 580px) {
            flex-direction: row;
        }

        @media (min-width: 1200px) {
            flex-direction: column;
        }

        @media (min-width: 1500px) {
            flex-direction: row;
        }

        > * {
            margin-top: 0;
            margin-left: 0;
            width: auto;
        }
    }
}

.lawyer-case-continue-btn {
    padding: 12px 24px;
    display: none;
    @media (min-width: 1200px) {
        display: inline-block;
    }
}


.internal-layout {
    margin-inline: -20px;
    @media (min-width: 768px) {
        margin-inline: auto;
    }
}

// Internal Pages Mobile Top Nav
.mobile-internal-nav {
    display: flex;
    background-color: colors.$snow;

    .link {
        padding-block: 16px;
        cursor: pointer;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        flex-grow: 1;

        // text
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: colors.$cadetGray;
        text-align: center;
        text-decoration: none;

        &.active {
            border-color: colors.$sapphire;
            color: colors.$sapphire;
        }
    }

    @media (min-width: 1080px) {
        display: none;
    }
}

// Internal Pages Mobile Bottom (fixed) Nav
.mobile-internal-fixed-nav {
    position: fixed;
    z-index: 8;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: colors.$snow;
    border-top: 1px solid colors.$alabaster;
    display: flex;

    @media (min-width: 1080px) {
        display: none;
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20%;
        padding-block: 11px;
        cursor: pointer;
        text-decoration: none;

        // text
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;

        &:not(.color-sapphire) {
            color: colors.$cadetGray;
        }

        &.active {
            color: colors.$black;
        }

        .icon {
            width: 24px;
            height: 24px;
            margin-bottom: 2px;
        }
    }

    &--lawyer .item {
        width: 25%;
    }
}

// Answer Form
.answer-form {
    &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 24px;
    }

    &__additional-comments {
        margin-top: 36px;
    }

    &__buttons {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;

        .btn {
            padding-inline: 48px;
        }
    }
}

.answer-item + .answer-item {
    margin-top: 36px
}

.answer-item {
    &__name {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    &__agree {
        padding: 24px;
        background-color: colors.$alabaster;

        &-name {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
        }

        &-descr {
            font-size: 14px;
            line-height: 22px;
            color: colors.$ebony;
            margin-bottom: 16px;
        }

        &-radios {
            display: flex;
            align-items: center;

            .radio + .radio:not(.radio--lined) {
                margin-top: 0;
                margin-left: 24px;
            }
        }
    }

    &__disagree-options {
        margin-top: 16px;

        &-name {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
        }
    }

    &__disagree-option + &__disagree-option {
        margin-top: 10px;
    }

    &__disagree-option {
        display: block;
        cursor: pointer;

        input {
            width: 0;
            height: 0;
            position: absolute;
            visibility: hidden;
            opacity: 0;
        }

        &-name {
            display: block;
            position: relative;
            padding: 16px 16px 16px 46px;
            background-color: colors.$white;
            border: 1px solid colors.$lightGray;
            transition: .2s ease;

            &::before {
                content: '';
                width: 22px;
                height: 22px;
                position: absolute;
                z-index: 1;
                top: 16px;
                left: 16px;
                border-radius: 50%;
                box-shadow: inset 0 0 0 2px colors.$lightGray;
                transition: .2s ease;
            }
        }

        input:checked + &-name {
            border-color: colors.$kleinBlue;
            background-color: colors.$kleinBlue;
            color: colors.$white;

            &::before {
                box-shadow: inset 0 0 0 6px colors.$white;
            }
        }
    }

    &__disagree-other-describe {
        margin-top: 10px;
    }
}

.checkbox-name-right {
    font-size: 14px;
    margin-right: 4px;
}


.history {
    &__item + &__item {
        margin-top: 12px;
        @media (min-width: 768px) {
            margin-top: 10px;
        }
    }

    &__item {
        align-items: center;
        @media (min-width: 768px) {
            display: flex;
        }
    }

    &__date {
        color: colors.$cadetGray;
        font-size: 14px;
        line-height: 22px;

        @media (min-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__info {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        color: colors.$black;

        @media (min-width: 768px) {
            margin-left: 18px;
            font-size: 16px;
            line-height: 24px;
        }

        @include m.pseudo(before, '') {
            display: none;
            height: 1px;
            width: 24px;
            min-width: 24px;
            background-color: colors.$lightGray;
            margin-right: 16px;

            @media (min-width: 768px) {
                display: block;
            }
        }
    }
}


.mr-6 {
    margin-right: 36px;
}

.mt-4 {
    margin-top: 24px;
}

@media (max-width: 1079px) {
    .body.modal-opened .internal-page-title {
        z-index: 0;
    }
}


[data-slide-toggle="content"],
[data-slide-toggle="toggler"],
[data-slide-toggle="toggler"]::after,
[data-slide-toggle="toggler"]::before,
[data-slide-toggle="wrapper"] {
    transition: .3s ease
}

[data-slide-toggle="content"] {
    max-height: 0;
    overflow: hidden;
}

[data-slide-toggle="wrapper"].active [data-slide-toggle="content"] {
    max-height: var(--max-toggle-height);
}
