@use '../variables/colors' as colors;

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    &__item {
        outline: 0;
        text-decoration: none;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: colors.$cadetGray;
        width: 36px;
        padding-bottom: 2px;
        border-bottom: 2px solid rgba(0, 0, 0, 0);
        transition: .2s ease;
        &:not(:last-child) { margin-right: 24px; }

        &:not(.pagination__item--dots):hover { border-color: colors.$cadetGray; }

        &:focus { border-color: colors.$cadetGray; }

        &.active { color: colors.$kleinBlue; border-color: colors.$kleinBlue; }

        &--dots { pointer-events: none; }
    }
}
