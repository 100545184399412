@use '../variables/colors' as colors;
@use '../abstracts/mixins' as m;

#app {
    .vdp-datepicker__calendar {
        top: calc(100% + 4px);
        border: 2px solid colors.$lightGray;
        background-color: colors.$white;
        padding: 0 11px 11px;
    }

    .vdp-datepicker__calendar header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid colors.$lightGray;
        padding: 16px 0;
        margin-bottom: 16px;
        line-height: 22px;
    }

    .vdp-datepicker__calendar header .prev, .vdp-datepicker__calendar header .next {
        width: 16px;
        min-width: 16px;
        height: 16px;
        font-size: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.74951 12.5L10.2495 8L5.74951 3.5' stroke='%236A7378' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;

        &::after {
            display: none;
        }
    }

    .vdp-datepicker__calendar header .prev {
        transform: rotate(180deg)
    }

    .vdp-datepicker__calendar .cell.day-header {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        height: 16px;
        color: colors.$cadetGray;
    }

    .vdp-datepicker__calendar .cell.day {
        padding: 0;
        border-radius: 50%;

        &.today {
            font-weight: 700;
        }
    }

    .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
        background-color: rgba(colors.$sapphire, .2);
    }

    .vdp-datepicker__calendar .cell.selected {
        background-color: colors.$sapphire !important;
        color: colors.$white;
    }

    .vdp-datepicker__calendar .cell {
        width: 32px;
        height: 32px;
        line-height: 32px;
        border: 0;
        margin: 3px 4px;
        transition: .2s ease;
    }

    .vdp-datepicker__calendar header + div {
        margin: -3px -4px;
    }
}

.qs-datepicker-container {
    min-width: 300px;
    max-width: 300px;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    padding-top: 2px;
    font-family: inherit;
    font-size: 14px;
    line-height: 22px;
}

.qs-datepicker {
    border: 2px solid colors.$lightGray;
    background-color: colors.$white;
    padding: 0 11px;
}

.qs-controls {
    padding: 16px 0;
    background-color: colors.$white;
    border-bottom: 1px solid colors.$lightGray;
}

.qs-squares {
    padding: 16px 0;
    display: grid;
    grid-template-columns: repeat(7, 32px);
    gap: 8px;
}

.qs-square {
    width: 32px;
}

.qs-num {
    height: 32px;
    border-radius: 50%;
    color: colors.$black;
}

.qs-day {
    text-transform: uppercase;
    color: colors.$cadetGray;
    font-weight: 500;
}

.qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
    background-color: rgba(colors.$sapphire, .1);
}

.qs-active, .qs-range-start, .qs-range-end {
    background-color: colors.$sapphire;
    color: colors.$white;
}

.qs-arrow {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
}

.qs-arrow:after {
    position: static;
    top: 0;
    left: 0;
    border: 0;
    width: 16px;
    min-width: 16px;
    height: 16px;
    @include m.bg-img(cover, 50%);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.74951 12.5L10.2495 8L5.74951 3.5' stroke='%236A7378' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

    &:hover {
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.74951 12.5L10.2495 8L5.74951 3.5' stroke='%23000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
}

.qs-arrow.qs-right:after {
    transform: none;
}

.qs-arrow.qs-left:after {
    transform: rotate(180deg);
}

#app .vdp-datepicker__calendar .cell.month,
#app .vdp-datepicker__calendar .cell.year {
    width: 47%;
}
