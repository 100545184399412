@use '../variables/colors' as colors;
@use '../abstracts/mixins' as m;

.btn {
    display: inline-block;
    border-radius: 64px;
    appearance: none;
    outline: 0;
    border-width: 1px;
    border-style: solid;
    transition: .2s ease;
    cursor: pointer;
    text-decoration: none;
    padding: 20px;
    min-width: 200px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 600px) { padding: 13px; font-size: 14px; line-height: 22px; }

    &-small { padding: 12px; }
    &-tiny { padding: 6px; font-weight: 500; font-size: 14px; line-height: 22px; }
    &-tiny-font { font-weight: 500; font-size: 14px; line-height: 22px; }

    &-iconed {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        .btn__icon {
            margin-left: 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            min-width: 16px;
            height: 16px;

            svg { max-width: 100%; width: 100%; height: 100%; }
        }
    }

    &-blue {
        background-color: colors.$sapphire;
        border-color: colors.$sapphire;
        color: colors.$white;
        &:active {
            background: linear-gradient(0deg, rgba(0, 0, 9, 0.2), rgba(0, 0, 9, 0.2)), colors.$sapphire;
            border-color: rgba(0, 0, 0, 0);
        }
    }
    &-orange {
        background-color: colors.$carrotOrange;
        border-color: colors.$carrotOrange;
        color: colors.$white;
        &:active {
            background: linear-gradient(0deg, rgba(0, 0, 9, 0.2), rgba(0, 0, 9, 0.2)), colors.$carrotOrange;
            border-color: rgba(0, 0, 0, 0);
        }
    }
    &-green {
        background-color: colors.$keppel;
        border-color: colors.$keppel;
        color: colors.$white;
        &:active {
            background: linear-gradient(0deg, rgba(0, 0, 9, 0.2), rgba(0, 0, 9, 0.2)), colors.$keppel;
            border-color: rgba(0, 0, 0, 0);
        }
    }
    &-red {
        background-color: colors.$coquelicot;
        border-color: colors.$coquelicot;
        color: colors.$white;
        &:active {
            background: linear-gradient(0deg, rgba(0, 0, 9, 0.2), rgba(0, 0, 9, 0.2)), colors.$coquelicot;
            border-color: rgba(0, 0, 0, 0);
        }
    }
    &-outline {
        background-color: colors.$white;
        border-color: colors.$lightGray;
        color: colors.$sapphire;
        &:hover { border-color: colors.$sapphire; }
        &:active { background-color: colors.$beauBlue; }
    }
    &-outline2 {
        background-color: colors.$white;
        border-color: colors.$alabaster;
        color: colors.$cadetGray;
        &:hover { background-color: colors.$alabaster; }
        &:active { border-color: colors.$beauBlue; background-color: colors.$beauBlue; }
    }
    &-flat {
        background-color: rgba(0, 0, 0, 0);
        border-color: rgba(0, 0, 0, 0);
        color: colors.$sapphire;
        &:hover { background-color: colors.$alabaster; border-color: colors.$alabaster; }
        &:active { background-color: colors.$beauBlue; border-color: colors.$beauBlue; }
    }

    &-blue, &-orange, &-green, &-red {
        &:hover { opacity: .9; }
        &:disabled, &.disabled { opacity: .5; }
    }

    &-special {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .sub-text {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            opacity: .7;
            &--iconed {
                display: flex;
                align-items: center;
                .icon {
                    margin-left: 10px;
                    width: 16px;
                    height: 16px;
                    svg { width: 100%; height: 100%; }
                }
            }
        }
        @media (max-width: 600px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            flex-direction: row;
            .sub-text { font-size: 0; }
        }
    }
}
