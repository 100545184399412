@use '../variables/typography' as *;
@use '../variables/colors' as colors;
@use '../abstracts/mixins' as m;

.header {
    background-color: colors.$white;
    position: relative;
    z-index: 9;

    .body--home & {
        background-color: rgba(0, 0, 0, 0);
    }

    .body--internal & {
        border-bottom: 1px solid colors.$alabaster;
        @media (min-width: 1080px) {
            border-bottom: 0;
        }
    }

    &-content {
        justify-content: space-between;
        height: 80px;
        padding: 12px 0;
        @media (min-width: 1080px) {
            height: 152px;
            padding: 0;
        }
        .body--internal & {
            height: 64px;
            @media (min-width: 1080px) {
                height: 152px;
            }
        }
    }

    .mobile-open-nav-btn {
        @include m.bg-img(contain, 50%);
        background-image: url('../../img/open-nav-icon.svg');
        width: 24px;
        min-width: 24px;
        height: 24px;
        cursor: pointer;
        transition: .2s ease;
        &:hover, &:active, &:focus { opacity: .8; }

        .body--common & {
            background-image: url('../../img/open-nav-icon-black.svg');
        }

        @media (min-width: 1080px) {
            display: none;
        }
    }

    .logo-link {
        @include m.bg-img(contain, 50%);
        background-image: url('../../img/logo_blue_vector.svg');
        display: inline-block;
        width: 110px;
        min-width: 110px;
        height: 80px;
        transition: .2s ease;
        margin-right: 130px;
        &:hover { opacity: .8; }

        @media (max-width: 1220px) {
            margin-right: 64px;
        }

        @media (max-width: 1079px) {
            margin: 0 24px;
            width: 77px;
            min-width: 77px;
            height: 56px;
        }

        .body--home & {
            background-image: url('../../img/logo_white_vector.svg');
        }

        .body--internal & {
            display: none;
            @media (min-width: 1080px) {
                display: flex;
            }
        }
    }

    .nav, .auth {
        margin: 0;
        @media (min-width: 1080px) {
            margin: 0 -6px;
        }
    }

    .nav {
        margin-right: auto;
        .nav-mobile-title-line {
            display: none;
            position: relative;
            text-align: center;
            padding: 16px 24px 16px 24px;
            background-color: colors.$snow;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: colors.$black;
            margin-bottom: 12px;
            width: 100%;
            .close {
                position: absolute;
                @include m.bg-img(contain, 50%);
                background-image: url('../../img/nav-close-icon.svg');
                width: 36px;
                min-width: 36px;
                height: 36px;
                top: calc(50% - 18px);
                right: 24px;
                cursor: pointer;
                transition: .2s ease;
                &:hover { transform: rotate(180deg) }
            }
        }
        &-item {
            color: colors.$black;

            .body--home & {
                color: colors.$white;
            }

            & + .nav-item {
                margin-left: 48px;
                @media (max-width: 1499px) {
                    margin-left: 24px;
                }
                @media (max-width: 1220px) {
                    margin-left: 18px;
                }
            }

            &.current { color: colors.$sapphire; }
        }

        @media (max-width: 1079px) {
            background-color: colors.$white;
            position: fixed;
            z-index: 9;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            max-width: 320px;
            flex-direction: column;
            align-items: flex-start;
            transition: .2s ease;
            .nav-mobile-title-line { display: block; }
            .nav-item {
                color: colors.$black;
                padding: 12px 24px;
                width: 100%;
                &:hover, &:active, &:focus { background-color: darken(colors.$white, 5%); }
            }
            .nav-item + .nav-item { margin-left: 0; }

            @include m.pseudo(before, '') {
                position: fixed;
                top: 0;
                left: 320px;
                bottom: 0;
                width: calc(100% - 320px);
                z-index: 8;
                background-color: rgba(colors.$black, .7);
            }

            &:not(.active) { transform: translateX(-100%); }
        }
    }
    .auth {
        &-item {
            font-weight: 600;
            color: colors.$cadetGray;

            .body--home & {
                color: rgba(colors.$white, .5);
            }

            .body--internal &:nth-child(1) {
                display: none;
                @media (min-width: 1080px) {
                    display: flex;
                }
            }

            .body--common &:nth-child(1) {
                background-image: url('../../img/login-icon-black.svg');
                @media (min-width: 1080px) {
                    background-image: none;
                }
            }

            & + .auth-item {
                margin-left: 42px;
                @media (max-width: 1499px) {
                    margin-left: 24px;
                }
                @media (max-width: 1220px) {
                    margin-left: 18px;
                }
            }
        }

        @media (max-width: 1079px) {
            &-item:nth-child(2) { display: none; }
            &-item:nth-child(1) {
                font-size: 0;
                @include m.bg-img(contain, 50%);
                background-image: url('../../img/login-icon.svg');
                width: 24px;
                min-width: 24px;
                height: 24px;
            }
        }
    }

    .nav-item, .auth-item {
        text-decoration: none;
        outline: 0;
        font-size: 16px;
        line-height: 24px;
        transition: .2s ease;
        padding: 6px;
        &:hover { opacity: .8; }
        &:focus { box-shadow: 0 0 0 3px rgba(colors.$sapphire, .25); }
    }

    .notifications-button {
        margin-left: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .2s ease;
        color: colors.$ebony;

        .body--home & {
            color: colors.$white;
        }

        .body--internal & {
            margin-left: 0;
            @media (min-width: 1080px) {
                margin-left: 66px;
            }
        }

        @media (min-width: 1500px) {
            margin-left: 66px;
        }

        &:hover { opacity: .8; }
        .icon { width: 24px; min-width: 24px; height: 24px; }
    }

    &--error-page {
        position: relative;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0);
        width: max-content;
        margin-inline: auto;
        @media (min-width: 768px) {
            margin-inline: 0;
        }

        .logo-link { @include m.bg-img(contain, 50%); background-image: url('../../img/logo-sapphire.svg'); width: 216px; height: 157px; }
    }
}

.header-mobile-user-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: colors.$black;
    padding: 0 24px;
    text-align: center;

    @media (min-width: 1080px) {
        display: none;
    }
}

.mobile-back-btn {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4375 18.75L4.6875 12L11.4375 5.25' stroke='%23000009' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.625 12H19.3125' stroke='%23000009' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    width: 24px;
    height: 24px;
    cursor: pointer;


    @media (min-width: 1080px) {
        display: none;
    }
}
