$black: #000009;
$ebony: #40434B;
$cadetGray: #6A7378;
$lightGray: #D2DADC;
$alabaster: #EBF3F8;
$snow: #F7FAFC;
$white: #FFFFFF;
$spaceCadet: #131F49;
$kleinBlue: #173583;
$sapphire: #2150AA;
$beauBlue: #E4EFFF;
$carrotOrange: #F19202;
$melon: #FFF3E8;
$keppel: #29BDAB;
$lightCyan: #D0F5EA;
$coquelicot: #F13B02;
$mistyRose: #FFECE8;
