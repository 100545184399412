@use '../variables/typography' as *;
@use '../variables/colors' as colors;
@use '../abstracts/mixins' as m;
@use '../abstracts/functions' as f;
@import '~vue-multiselect/dist/vue-multiselect.min.css';


.multiselect {
    cursor: pointer;
    min-width: 192px;
    min-height: 68px;
    @include font(14px, 22px);

    @include m.pseudo(before, attr(data-label-text)) {
        display: flex;
        position: absolute;
        top: 10px;
        left: 20px;
        @include font(12px, 16px, 500);
        color: colors.$cadetGray;
        padding-right: 7px;
    }

    &[data-required="true"]::before {
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.40801 3.174L0.476013 2.04L0.854013 1.368L2.67401 2.614L2.60401 0.5H3.37401L3.30401 2.614L5.12401 1.368L5.50201 2.04L3.57001 3.174L5.50201 4.322L5.12401 4.994L3.30401 3.748L3.37401 5.862H2.60401L2.67401 3.748L0.854013 4.994L0.476013 4.322L2.40801 3.174Z' fill='%23F13B02'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 4.5px 4.5px;
        background-position: 100% 3px;
    }

    &__tag {
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0);
        color: colors.$black;
        padding: 4px 20px 4px 0;
        margin-right: 6px;
        margin-bottom: 0;
    }

    &__tag-icon::after {
        font-size: 18px;
    }

    &__tag-icon:focus, &__tag-icon:hover {
        background: rgba(0, 0, 0, 0);
    }

    &__tag-icon:focus::after, &__tag-icon:hover::after {
        color: inherit;
    }

    &__option--highlight:after,
    &__option--selected:after {
        display: none;
    }

    &__placeholder {
        padding: 0;
        margin: 0;
    }

    &__single {
        padding: 0;
        margin: 0;
    }

    &__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-height: 68px;
        border: 2px solid colors.$lightGray;
        border-radius: 0;
        padding: 28px 56px 10px 20px;
        @include font(14px, 22px);
        transition: .2s ease;
    }

    &__select {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        padding: 0;
        margin: 0;

        &::before {
            background-image: f.inline-svg('<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.49951 5.75L7.99951 10.25L12.4995 5.75" stroke="#6A7378" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            @include m.bg-img();
            width: 16px;
            height: 16px;
            border: 0;
            top: 0;
            margin: 0;
        }
    }

    &__option {
        width: 100%;
        padding: 10px 18px;
        color: colors.$black;

        &:hover, &.multiselect__option--highlight {
            background-color: colors.$snow;
            color: colors.$sapphire
        }

        &.multiselect__option--selected {
            background-color: colors.$snow;
            color: colors.$cadetGray;
        }
    }

    &__tags-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    &__content-wrapper {
        top: calc(100% + 2px);
        border: 2px solid colors.$lightGray;
    }

    &__content, &__element {
        max-width: 100%;
    }

    &__option {
        max-width: 100%;
        white-space: initial;
    }

    &--active {
        &::before {
            color: colors.$sapphire
        }

        .multiselect__tags {
            border-color: colors.$sapphire;
        }

        .multiselect__select {
            transform: translateY(-50%) rotate(180deg);

            &::before {
                background-image: f.inline-svg('<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.49951 5.75L7.99951 10.25L12.4995 5.75" stroke="#{colors.$sapphire}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            }
        }
    }

    &.no-label::before {
        display: none;
    }

    &.no-label &__tags {
        padding: 12px 20px;
        min-height: 48px;
    }

    &.no-label {
        min-height: 48px;
        min-width: 160px;
    }
}

.multiselect__input, .multiselect__single {
    padding: 0;
    margin: 0;
}

.multiselect, .multiselect__input, .multiselect__single {
    @include font(14px, 22px);
}

.multiselect__input, .multiselect__single {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


select[data-select] {
    display: none;
}

.custom-select {
    position: relative;
    cursor: pointer;
    min-width: 220px;
    user-select: none;
    @include font(14px, 22px);

    &-current {
        background-color: colors.$white;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 28px 56px 10px 20px;
        border: 2px solid colors.$lightGray;
        transition: .2s ease;
        @include m.pseudo(before, attr(data-name)) {
            position: absolute;
            top: 10px;
            left: 20px;
            @include font(12px, 16px, 500);
            color: colors.$cadetGray;
        }

        @include m.pseudo(after, '') {
            background-image: f.inline-svg('<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.49951 5.75L7.99951 10.25L12.4995 5.75" stroke="#6A7378" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            @include m.bg-img();
            width: 16px;
            height: 16px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            transition: .2s ease;
        }
    }

    &-options {
        width: 100%;
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        max-height: 172px;
        overflow-y: auto;
        transition: .3s ease;
        border: 2px solid colors.$lightGray;
        background-color: colors.$white;
        z-index: 2;
        transform: scaleY(0);
        transform-origin: center top;

        &-item {
            padding: 10px 18px;
            color: colors.$black;

            &:hover {
                background-color: colors.$snow;
                color: colors.$sapphire
            }

            &.selected {
                background-color: colors.$snow;
                color: colors.$cadetGray;
            }
        }
    }

    &:focus {
        outline: 0;

        .custom-select-current {
            border-color: colors.$sapphire;

            &::before {
                color: colors.$sapphire
            }
        }
    }

    &.active {
        .custom-select-current {
            border-color: colors.$sapphire;

            &::before {
                color: colors.$sapphire
            }

            &::after {
                background-image: f.inline-svg('<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.49951 5.75L7.99951 10.25L12.4995 5.75" stroke="#{colors.$sapphire}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                transform: translateY(-50%) rotate(180deg);
            }
        }

        .custom-select-options {
            transform: scaleY(1);
        }
    }
}


// VUE Select Height Fix
.multiselect__content-wrapper {
    bottom: unset;
}

.multiselect--above .multiselect__content-wrapper,
.multiselect--below .multiselect__content-wrapper {
    border-bottom: 2px solid colors.$lightGray;
    border-top: 2px solid colors.$lightGray;
    border-radius: 0;
}

.multiselect--above .multiselect__content-wrapper {
    top: unset;
    bottom: calc(100% + 2px);
}

// Oher VUE Select fixes
.multiselect__strong {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: colors.$ebony;
    margin-left: 4px;
}

.multiselect__tag {
    padding: 0 10px;
    border-radius: 24px;
    background-color: colors.$black;
    color: colors.$white;
    font-size: 14px;
    line-height: 22px;
    display: inline-flex;
    align-items: center;
}

.multiselect__tag-icon {
    position: static;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 11.5L4.5 4.5' stroke='%23D2DADC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 4.5L4.5 11.5' stroke='%23D2DADC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-left: 4px;

    &::after {
        display: none;
        opacity: 0;
        visibility: hidden;
    }

    &:hover {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 11.5L4.5 4.5' stroke='%23D2DADC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 4.5L4.5 11.5' stroke='%23D2DADC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-color: none;
        opacity: .8;
    }
}
