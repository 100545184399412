// IMPORTANT: This file doesn't contain all of the media queries.
// Most of them are located inside other files and are mobileFirst-based
// TODO:
//  1. Refactor - Move responsive styles to corresponding selectors in the main files (_common, _header, _footer, etc).
//  2. Remove this file from project.

@use 'variables/colors' as colors;
@use 'abstracts/mixins' as m;

@media (max-width: 1199px) {
    // m
    .section--how-it-works, .section--lawyers, .section--faq,
    .work-with-us, .section--reviews--page, .terms,
    .contract-clauses, .upload-how, .contract-templates {
        margin-bottom: 96px;
    }
}

@media (max-width: 992px) {
    .section--welcome {
        flex-direction: column;
    }
    .section--welcome__video {
        max-width: 100%;
        min-width: 100%;
        display: flex;
    }
    .section--welcome__text {
        padding: 64px 24px;
    }
    .page-title--common {
        font-weight: bold;
        font-size: 48px;
        line-height: 56px;
    }
    .what-is__name {
        font-size: 36px;
        line-height: 48px;
        padding-bottom: 12px;
        margin-bottom: 18px;
    }
    .what-is__descr, .page-descr {
        font-size: 20px;
        line-height: 30px;
    }

    .section__title--small {
        font-size: 36px;
        line-height: 42px;
    }

    .section--legal-info {
        padding: 36px 24px;
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: 90px;
    }
    .section--legal-info__items {
        grid-template-columns: 1fr 1fr;
        gap: 24px 36px;
    }

    .section--faq__tab-titles {
        display: none;
    }
    .section--faq__tab-select-mobile {
        display: block;
    }

    .section--faq {
        margin-top: 10px;
    }

    .auth-page {
        padding: 48px 0;
    }

    .auth-page__logo-link {
        margin-bottom: 48px;
        width: 120px;
        height: 87px;
    }
}

// max-width: 992px

@media (max-width: 767px) {
    .section__title {
        font-size: 42px;
        line-height: 56px;
    }
    .footer .footer-bottom-content {
        flex-direction: column;
        padding: 24px 0;
    }
    .footer .footer-bottom .logo-link {
        margin-right: 0;
    }
    .footer .footer-bottom .copy {
        margin: 16px 0 6px;

        br {
            display: none;
        }
    }

    .section--advantages--page .swiper-wrapper {
        all: initial
    }

    .section--how-it-works__step::before {
        width: 120px;
        min-width: 120px;
    }

    .advantages--noslider {
        display: block;
        margin: 0 -24px;
    }
    .section--advantages--page {
        overflow-x: visible;
    }

    // m
    .section--legal-info, .section--how-it-works, .section--lawyers, .section--faq, .work-with-us, .section--reviews--page, .terms {
        margin-bottom: 64px;
    }
    .section--legal-info__items {
        grid-template-columns: 1fr 1fr;
        gap: 24px 36px;
    }

    .section__title--forum-font {
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 0;
    }

    .section--lawyers__item {
        padding: 24px;
    }

    .advantages--mediation .advantages__item, .section--advantages--page .advantages--mediation .advantages__item {
        width: 100%;
    }
    .advantages__name--bigger {
        font-size: 32px;
        line-height: 40px;
    }
}

@media (max-width: 600px) {
    .section--welcome__buttons {
        width: 100%;
        flex-direction: column;
    }
    .section--welcome__buttons .btn {
        width: initial;
    }
    .section--welcome__buttons .btn + .btn {
        margin-left: 0;
        margin-top: 16px;
    }
    .reviews {
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .reviews__item {
        padding: 36px 24px 24px 24px;
    }
    .reviews__item + .reviews__item {
        margin-top: 24px;
    }
    .section--reviews__more {
        display: none;
    }
    .section--why__advantages .advantages__item {
        width: 240px;
    }

    .page-title--common {
        font-size: 30px;
        line-height: 40px;
    }

    .page-title-line {
        margin-bottom: 24px;
    }
    .what-is__name {
        font-size: 32px;
        line-height: 40px;
        padding-bottom: 8px;
        margin-bottom: 24px;
    }
    .what-is__descr, .page-descr {
        font-size: 16px;
        line-height: 24px;
    }
    .what-is {
        margin-bottom: 78px;
    }
    .section--advantages--page, [data-mediation-scrollmagic-wrapper] {
        margin-bottom: 64px;
    }
    .section--how-it-works__step {
        display: block;

        &::before {
            display: block;
            min-width: 100%;
            max-width: 100%;
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .section--how-it-works__step:not(:last-child) {
        margin-bottom: 64px;
    }
    .section--how-it-works__step {
        font-size: 16px;
        line-height: 24px;
    }
    .section--how-it-works__blockquote {
        padding: 16px;
    }

    .section--legal-info__items {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .section--lawyers__item {
        display: block;
    }
    .section--lawyers__image {
        margin-bottom: 24px;
        width: 100%;
        padding-top: 100%;
        position: relative;
    }
    .section--lawyers__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
    .section--lawyers {
        margin-top: 24px;
    }
    .section--faq__item {
        padding: 24px;
    }
    .section--faq__item.active .section--faq__a {
        margin-top: 24px;
    }
    .section--faq__tab-contents {
        margin: 0 -24px;
    }

    .section--faq__q {
        font-size: 20px;
        line-height: 30px;
    }
    .section--faq__q::after {
        width: 30px;
        min-width: 30px;
        height: 30px;
    }

    .auth-page {
        padding: 24px 0 0 0;
    }

    .auth-page__logo-link {
        margin-bottom: 34px;
        width: 88px;
        height: 64px;
    }

    .auth-page__layout {
        margin: 0 -20px;
    }

    .auth-page__content {
        padding: 24px 24px 44px 24px;
    }

    .auth__tab-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        width: 50%;
        text-align: center;
    }

    .auth__descr {
        margin-bottom: 24px;
    }

    .auth__input-label + .auth__input-label, .auth__inputs-line + .auth__input-label {
        margin-top: 16px;
    }
    .auth__inputs-line .auth__input-label {
        margin-top: 0;
    }
}

// max-width: 600px

@media (max-width: 500px) {
    .section__title {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
    }
    .section__descr {
        font-size: 16px;
        line-height: 24px;
    }
    .section--welcome__text {
        padding: 36px 24px;
    }
    .section--welcome__title {
        font-size: 30px;
        line-height: 40px;
    }
    .section--welcome__subtitle {
        font-size: 20px;
        line-height: 30px;
    }
    .section--welcome__descr {
        font-size: 16px;
        line-height: 24px;
    }
    .section--welcome__video .play-btn {
        width: 64px;
        height: 64px;
    }

    .section--questions {
        padding: 64px 0 0 0;
        max-width: 100%;
        overflow: hidden;
    }
    .section--reviews {
        padding: 64px 0;
    }
    .section--reviews--page {
        padding: 0;
    }
    .section--questions__col {
        padding: 0 24px;
    }
    .section--questions__col--with-background {
        padding: 36px 24px;
    }

    .section--questions__title {
        font-size: 32px;
        line-height: 40px;
        padding-bottom: 14px;
        margin-bottom: 16px;
    }
    .section--questions__descr {
        font-size: 16px;
        line-height: 24px;
    }
    .section--questions__buttons .btn {
        min-width: calc(50% - 12px);
    }

    .section--questions__col + .section--questions__col {
        margin-top: 64px;
    }
    .section--questions__buttons .btn + .btn {
        margin-left: 16px;
    }


    .section--questions__columns {
        margin: 0 -24px;
    }

    .section--why__advantages .advantages__item {
        padding: 24px;
    }

    .section--reviews__more--mobile {
        display: flex;
        margin-top: 24px;
    }

    .work-with-us__text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
    }
    .work-with-us__text p + p {
        margin-top: 24px;
    }
    .work-with-us__button-title {
        text-align: center;
        font-size: 20px;
        line-height: 30px;
    }
    .work-with-us__button {
        display: flex;
        margin: 0 auto;
        max-width: 272px;
    }

    .header .nav {
        max-width: 100%;
        top: unset;
        left: 0;
        right: 0;
        bottom: 0;

        &::before {
            position: absolute;
            opacity: 1;
            transition: .2s ease;
            transition-delay: .2s;
            top: -200vh;
            left: 0;
            right: 0;
            bottom: calc(100%);
            width: 100%;
        }

        &::after {
            content: '';
            display: block;
            width: 120px;
            height: 4px;
            background-color: colors.$white;
            border-radius: 6px;
            position: absolute;
            z-index: 10;
            left: 50%;
            bottom: calc(100% + 10px);
            transform: translateX(-50%);
        }

        &:not(.active) {
            transform: translateY(120%);

            &::before {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    .auth__submit-line {
        flex-direction: column;
        justify-content: center;
    }
    .auth__terms-checkbox {
        margin-right: 0;
        margin-bottom: 24px;
    }

    .auth__forgot {
        order: 99;
        margin-top: 24px;
    }

    .auth__inputs-line {
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
    }

    .auth__who .radios {
        flex-direction: column;
        align-items: flex-start;

        .radio + .radio {
            margin-top: 12px;
            margin-left: 0;
        }
    }
}

// max-width: 600px

@media (max-width: 480px) {
    .auth__submit-line .btn {
        width: 100%;
        padding: 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
}
