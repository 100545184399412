@use '../variables/colors' as colors;
@use '../variables/typography' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as *;

.input-label, .textarea-label, .select-wrapper {
    display: block;
    position: relative;

    .tooltip {
        position: absolute;
        right: 20px;
        z-index: 1;
        border-radius: 50%;
        background-color: colors.$white;
        display: inline-block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        @include bg-img(contain, 50%) {
            background-image: url('../../img/info-tooltip-icon.svg');
        }

        & + .field, & + .textarea {
            padding-right: 60px;
        }

        &::after {
            content: attr(data-text);
            position: absolute;
            padding: 16px;
            max-width: 240px;
            min-width: 240px;
            left: 50%;
            bottom: calc(100% + 14px);
            transform: translateX(-50%);
            transition: .2s ease;
            background-color: colors.$black;
            opacity: 0;
            font-size: 14px;
            line-height: 22px;
            color: colors.$white;
        }

        &::before {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 0 8px;
            border-color: colors.$black transparent transparent transparent;
            left: 50%;
            bottom: calc(100% + 6px);
            transform: translateX(-50%);
            opacity: 0;
            transition: .2s ease;
        }

        &:hover {
            &::before, &::after {
                opacity: 1;
            }
        }
    }
}


.input-label { // text, email, num, phone
    display: flex;
    flex-direction: column;
    position: relative;

    .name {
        max-width: calc(100% - 40px);
        padding-right: 6px;
        width: max-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        pointer-events: none;
        position: absolute;
        left: 20px;
        right: 20px;
        top: 40%;

        transition: .2s ease;
        @include font(14px, 22px);
        color: colors.$ebony;
        transform: translate(0, calc(-50% - 9px));
        @include font(12px, 16px, 500);
    }

    .field {
        padding: 30px 20px 12px 20px;
        border: 2px solid colors.$lightGray;
        appearance: none;
        outline: 0;
        @include font(14px, 22px);
        color: colors.$black;
        background-color: colors.$white;
        transition: .2s ease;
        box-shadow: none;

        [type="number"] {
            -webkit-appearance: none;
            -moz-appearance: textfield;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:disabled {
            opacity: .5;
            cursor: not-allowed;

            & + .name {
                opacity: .5;
                cursor: not-allowed;
            }
        }

        &:required {
            & + .name::after {
                content: '*';
                display: inline-block;
                color: colors.$coquelicot;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        &.filled {
            & + .name {
                color: colors.$cadetGray;
            }
        }

        &.has-error {
            border-color: colors.$coquelicot;

            & + .name {
                color: colors.$coquelicot;
            }
        }

        &:focus {
            border-color: colors.$sapphire;

            & + .name {
                color: colors.$sapphire
            }
        }

        &:focus, &.filled {
            & + .name {
                transform: translate(0, calc(-50% - 9px));
                @include font(12px, 16px, 500);
            }
        }

        &[data-datepicker], &.field-datepicker {
            width: 100%;
            @include bg-img(16px 16px, calc(100% - 20px) 50%);
            background-image: url('../../img/datepicker-icon.svg');

            &:focus {
                background-image: url('../../img/datepicker-icon-sapphire.svg');
            }
        }
    }

    .error {
        position: absolute;
        left: 0;
        top: calc(100% + 4px);
        cursor: pointer;
        color: colors.$coquelicot;
        @include font(12px, 16px, 500);
    }

    .tooltip {
        top: 50%;
        transform: translateY(-50%);
    }
}

.input-label--datepicker {
    &:focus-within .name,
    &.field-not-empty .name {
        color: colors.$sapphire;
        transform: translate(0, calc(-50% - 9px));
        @include font(12px, 16px, 500);
    }

    &.required .name::after {
        content: '*';
        color: colors.$coquelicot;
    }
}

.inputs-line {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 24px;

    &.added {
        grid-template-columns: minmax(0, calc(50% - 24px)) minmax(0, calc(50% - 44px)) 20px;
    }
}

//.internal-layout__content .inputs-line {
//    display: block;
//    > * + * { margin-top: 18px; }
//
//    @media (min-width: 1500px) {
//        display: grid;
//        > * + * { margin-top: 0; }
//    }
//}

.remove-added-line {
    width: 20px;
    height: 20px;
    min-width: 20px;
    align-self: center;
    color: colors.$cadetGray;
    transition: .2s ease;
    cursor: pointer;

    &:hover {
        color: colors.$spaceCadet
    }
}

.repeatable-case {
    .remove-added-line {
        margin-top: 24px;
        margin-left: 24px;
    }
}

.checkbox-name, .radio-name {
    @include font(14px, 22px);
    vertical-align: middle;

    a {
        color: colors.$sapphire;

        &:hover {
            text-decoration: none;
        }
    }
}

.checkbox {
    display: block;
    cursor: pointer;

    input {
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }

    .checkbox-name {
        display: flex;
        align-items: flex-start;
        @include pseudo(before, '') {
            margin-top: 3px;
            width: 16px;
            min-width: 16px;
            height: 16px;
            margin-right: 8px;
            border: 1px solid colors.$lightGray;
            transition: .2s ease;
        }
    }

    input:checked + .checkbox-name::before {
        border-color: rgba(0, 0, 0, 0);
        background-color: colors.$sapphire;
        background-image: url('../../img/checkmark-white.svg');
        @include bg-img();
    }

    &:focus-within &-name::before {
        box-shadow: 0 0 0 3px rgba(colors.$sapphire, .25);
    }
}

.checkbox-switch {
    cursor: pointer;
    position: relative;
    display: inline-flex;

    input {
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }

    .checkbox-name {
        padding-left: 36px;
        @include pseudo(before, '') {
            position: absolute;
            width: 30px;
            min-width: 30px;
            height: 20px;
            background-color: colors.$lightGray;
            transition: .2s ease;
            border-radius: 22px;
            left: 0;
            top: 2px;
        }

        @include pseudo(after, '') {
            position: absolute;
            left: 4px;
            top: 6px;
            width: 12px;
            min-width: 12px;
            height: 12px;
            background-color: colors.$white;
            border-radius: 50%;
            transition: .2s ease;
        }
    }

    input:checked + .checkbox-name::before {
        background-color: colors.$sapphire;
    }

    input:checked + .checkbox-name::after {
        transform: translateX(10px);
    }

    &:focus-within .checkbox-name::before {
        box-shadow: 0 0 0 3px rgba(colors.$sapphire, .25);
    }
}

.radio {
    cursor: pointer;
    display: flex;

    & + .radio:not(.radio--lined) {
        margin-top: 12px;
    }

    input {
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }

    .radio-name {
        display: flex;
        @include pseudo(before, '') {
            margin-right: 8px;
            width: 24px;
            min-width: 24px;
            height: 24px;
            box-shadow: inset 0 0 0 2px colors.$lightGray;
            transition: .2s ease;
            border-radius: 50%;
        }
    }

    input:checked + .radio-name::before {
        border-width: 0;
        box-shadow: inset 0 0 0 7px colors.$black;
    }

    &:focus-within {
        .checkbox-name::before {
            box-shadow: inset 0 0 0 2px colors.$lightGray,
            0 0 0 3px rgba(colors.$sapphire, .25);
        }

        input:checked + .radio-name::before {
            border-width: 0;
            box-shadow: inset 0 0 0 7px colors.$black,
            0 0 0 3px rgba(colors.$sapphire, .25);
        }
    }
}

.radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--horizontal {
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -12px -12px -12px;

        .radio + .radio:not(.radio--lined) {
            margin-top: 0;
        }

        .radio:not(.radio--lined) {
            margin: 0 12px 12px 12px;
        }

    }
}

.textarea-label {
    display: block;
    position: relative;

    .name-line {
        display: flex;
        align-items: flex-end;
        margin-bottom: 6px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: colors.$cadetGray;
        width: 100%;

        .name {
            letter-spacing: 1px;
            position: relative;
            padding-right: 10px;
        }

        .toggler {
            display: flex;
            align-items: center;
            margin-left: 4px;
            margin-right: 4px;
            margin-bottom: -3px;
            text-transform: none;
        }

        .checkbox-switch .checkbox-name::after {
            top: 4px;
        }

        .checkbox-switch .checkbox-name::before {
            top: 0;
        }

        .length {
            opacity: .5;
            margin-left: auto;
        }
    }

    &.required .name-line .name::after {
        content: '*';
        color: colors.$coquelicot;
        position: absolute;
        top: 0;
        right: 0;
    }

    .textarea {
        display: block;
        width: 100%;
        padding: 10px 20px;
        border: 2px solid colors.$lightGray;
        appearance: none;
        outline: 0;
        @include font(14px, 22px);
        color: colors.$black;
        background-color: colors.$white;
        transition: opacity .2s ease, border .2s ease;
        box-shadow: none;
        resize: vertical;

        &.no-resize {
            resize: none;
        }

        &.single-line {
            height: 46px;
        }

        &:disabled {
            opacity: .5;
            cursor: not-allowed;
        }

        &.has-error {
            border-color: colors.$coquelicot;
        }

        &:focus {
            border-color: colors.$sapphire;
        }
    }

    .tooltip {
        top: 36px;
    }
}

.custom-file-upload {
    .selected-files {

        .item {
            padding: 12px 12px 12px 10px;
            display: flex;
            align-items: center;
            position: relative;
            background-color: colors.$alabaster;
            font-size: 14px;
            line-height: 22px;
            color: colors.$ebony;
            margin-bottom: 6px;

            .icon {
                margin-right: 10px;
                width: 24px;
                min-width: 24px;
                height: 24px;
            }

            .name {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .remove {
                cursor: pointer;
                margin-left: auto;
                transition: .2s ease;

                &:hover {
                    opacity: .8;
                }

                .svg-icon {
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                }
            }
        }
    }

    .custom-file-input {
        width: 100%;

        input {
            opacity: 0;
            position: absolute;
            width: 0;
            height: 0;
        }

        .info-line {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            padding: 12px 20px;
            transition: .2s ease;

            border: 1px dashed rgba(colors.$sapphire, .5);

            &:hover {
                border-color: currentColor;
            }

            .name {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: colors.$black;
            }

            .fake-btn {
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 22px;

                .text {
                    white-space: nowrap;
                }

                .icon {
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                    margin-left: 6px;
                }
            }
        }
    }
}

.inputs-add {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        right: 0;
        height: 1px;
        transform: translateY(-50%);
        background-color: colors.$beauBlue;
    }

    .button {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        background: #E4EFFF;
        border-radius: 24px;
        font-size: 14px;
        line-height: 22px;
        padding: 4px 16px;
        color: colors.$sapphire;
        transition: .2s ease;

        .icon {
            margin-left: 6px;
            width: 16px;
            min-width: 16px;
            height: 16px;
        }

        &:hover {
            background: colors.$sapphire;
            color: colors.$white;
        }
    }
}

.select-wrapper {
    .tooltip {
        top: 50%;
        transform: translateY(-50%);

        &::after {
            pointer-events: none;
        }
    }

    .multiselect.no-label .multiselect__tags, .multiselect__tags {
        padding-right: 60px;
    }

}
