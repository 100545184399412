//@function rem($pxValue) {
//    @return $pxValue / 16px * 1rem;
//}

%forumFont { font-family: 'Forum', serif; }
%cabinFont { font-family: 'Cabin', sans-serif; }

@mixin font($sz, $lh, $fw: normal) {
    font-weight: $fw;
    font-size: $sz;
    line-height: $lh;
}
