@mixin bg-img($size: contain, $position: 50%, $display: inline-block) {
    display: $display;
    background-size: $size;
    background-repeat: no-repeat;
    background-position: $position;
    @content;
}

@mixin pseudo($type: before, $content: '') {
    &::#{$type} {
        content: $content;
        @content;
    }
}
