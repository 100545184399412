@use '../variables/colors' as colors;

.cookie-notification {
    position: fixed;
    z-index: 9;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    padding: 18px 24px;
    display: none;
    align-items: center;
    justify-content: space-between;
    max-width: 480px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: colors.$white;
    background-color: colors.$spaceCadet;
    &__ok-btn {
        cursor: pointer;
        margin-left: 24px;
        border: 1px solid colors.$alabaster;
        border-radius: 36px;
        padding: 5px 16px;
        background-color: rgba(0, 0, 0, 0);
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: colors.$white;
        transition: .2s ease;
        &:hover { background-color: rgba(colors.$alabaster, .2); }
    }
}
