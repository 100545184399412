@use '../variables/typography' as *;
@use '../variables/colors' as colors;
@use '../abstracts/mixins' as m;

.footer {
    width: 100%;
    background-color: colors.$black;
    margin-top: auto;

    .footer-top {
        &-content {
            padding-right: 60px;
            padding-top: 64px;
            padding-bottom: 74px;

            @media (max-width: 1079px) {
                display: block;
                padding: 15px 0 36px;
            }
        }

        &-column {
            color: colors.$white;

            @media (max-width: 1079px) {
                padding: 14px 0 12px;
                border-bottom: 2px solid rgba(colors.$white, .25);
            }

            .name {
                margin-bottom: 27px;
                @extend %forumFont;
                @include font(30px, 34px);

                @media (max-width: 1079px) {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 0;
                    position: relative;
                    padding-right: 36px;
                    transition: .2s ease;
                    cursor: pointer;
                    &::after, &::before {
                        content: '';
                        position: absolute;
                        right: 10px;
                        top: calc(50% - .75px);
                        transform-origin: center center;
                        width: 13.5px;
                        height: 1.5px;
                        border-radius: .5px;
                        background-color: colors.$white;
                        transition: .2s ease;
                    }

                    &::after { transform: rotate(90deg) }
                }
            }
            .items {
                @include font(14px, 22px);
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @media (max-width: 1079px) {
                    max-height: 0;
                    transition: .2s ease;
                    overflow: hidden;
                    padding-left: 16px;
                }

                .link {
                    text-decoration: none;
                    color: inherit;
                    transition: .2s ease;

                    &:hover { opacity: .8; }

                    &:focus { outline: 0; box-shadow: 0 0 0 3px rgba(colors.$white, .25); }

                    &.tel-link { @include font(16px, 24px, 700) }
                }

                > *:not(:last-child) { margin-bottom: 16px; }
            }

            &.show-links {
                @media (max-width: 1079px) {
                    .name { color: rgba(colors.$white, .5) }
                    .name::after { transform: rotate(360deg) }
                    .name::before { transform: rotate(180deg) }
                    .name::before, .name::after { background-color: rgba(colors.$white, .5); }
                    .items { max-height: 280px; margin-top: 24px; margin-bottom: 12px; }
                }
            }
        }
    }

    .footer-bottom {
        background-color: rgba(colors.$cadetGray, .1);
        &-content { padding: 24px 60px 24px 0; color: colors.$white; }

        .logo-link {
            @include m.bg-img(contain, 50%);
            background-image: url('../../img/logo-sapphire.svg');
            display: inline-block;
            width: 66px;
            min-width: 66px;
            height: 48px;
            transition: .2s ease;
            margin-right: 36px;
            &:hover { opacity: .8; }
        }

        .copy { margin-right: auto; }

        .dev-by {
            a {
                text-decoration: none;
                color: colors.$carrotOrange;
                transition: .2s ease;
                &:hover { opacity: .9; }
                &:focus { outline: 0; box-shadow: 0 0 0 3px rgba(colors.$carrotOrange, .25); }
            }
        }
    }

    &--error-page {
        position: relative;
        z-index: 1;
        margin-top: auto;
        background-color: rgba(0, 0, 0, 0);
        .footer-links {
            width: 100%;
            &__title {
                font-weight: bold;
                font-size: 24px;
                line-height: 36px;
                color: colors.$black;
                text-align: center;
                @media (min-width: 768px) {
                    text-align: left;
                }
            }
            &__items {
                margin-top: 24px;
                display: flex;
                flex-wrap: wrap;
                gap: 16px 24px;
                text-align: center;
                justify-content: center;
                @media (min-width: 768px) {
                    text-align: left;
                    justify-content: flex-start;
                }
            }
            &__link {
                display: inline-block;
                font-size: 16px;
                line-height: 24px;
                text-decoration: underline;
                color: colors.$sapphire;
                &:hover { text-decoration: none; }
            }
        }
    }

    &--internal {
        display: none;
        background-color: colors.$white;
        @media (min-width: 1080px) {
            display: block;
        }

        a { text-decoration: none; transition: .2s ease; }
        .footer {
            &__line {
                padding: 24px 0;
                &:not(:last-child) { border-bottom: 1px solid rgba(#6A7378, .1); }
            }
            &__link {
                font-size: 14px;
                line-height: 22px;
                color: colors.$black;
                &:not(:first-child) { margin-left: 24px }
                &:hover { color: colors.$sapphire }
            }
            &__email {
                margin-right: 36px;
                font-size: 14px;
                line-height: 22px;
                color: colors.$sapphire;
                &:hover { color: colors.$kleinBlue }
            }
            &__phone {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: colors.$black;
                &:hover { color: colors.$kleinBlue }
            }
            &__copy { font-size: 14px; line-height: 22px; }
            &__back-to-top {
                cursor: pointer;
                margin-right: 36px;
                color: colors.$black;
                transform: rotate(180deg);
                transition: .2s ease;
                &:hover { opacity: .8; }
                &, svg { width: 16px; height: 16px; }
            }
        }
    }
}
