@use '../variables/colors' as colors;

// Modals
.modal {
    background-color: rgba(colors.$black, .8);
    position: fixed;
    display: flex;
    align-items: flex-end;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    transition: .2s ease;
    cursor: pointer;

    @media (min-width: 680px) {
        align-items: center;
        justify-content: center;
    }

    &:not(.active) {
        opacity: 0;
        pointer-events: none;
    }

    &:not(.active) &__inner {
        transform: translateY(calc(100% + 14px));
    }

    &__inner {
        cursor: initial;
        background-color: colors.$white;
        width: 100%;
        padding: 0 0 24px 0;
        position: relative;
        transition: .2s ease;

        @media (min-width: 680px) {
            max-width: 640px;
            padding: 36px;
        }

        &::before {
            content: '';
            bottom: calc(100% + 10px);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background-color: colors.$white;
            height: 4px;
            width: 120px;
            border-radius: 6px;
            pointer-events: none;
            @media (min-width: 680px) { opacity: 0; }
        }
    }
    &__content {
        max-height: calc(100vh - 400px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    &__content, &__buttons {
        padding-inline: 24px;
        margin-top: 24px;
        @media (min-width: 680px) {
            padding: 0;
            margin-top: 0;
            & + & { margin-top: 24px; }
        }
    }
    &__title-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        background-color: colors.$snow;
        padding: 16px 24px;
        @media (min-width: 680px) {
            background-color: rgba(0, 0, 0, 0);
            padding: 0;
            display: block;
            text-align: initial;
            &--mobile-only { display: none; }
        }
    }
    &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: colors.$black;
        flex-grow: 1;
        margin-right: -36px;
        @media (min-width: 680px) { margin-right: 0; }
    }
    &__close-btn {
        width: 36px;
        min-width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .2s ease;
        &:hover { opacity: .8; }
        .icon {
            width: 36px;
            height: 36px;
        }

        @media (min-width: 680px) {
            display: none;
        }
    }
    &__buttons {
        display: flex;
        flex-direction: column-reverse;
        align-items: stretch;
        .btn + .btn {
            margin-bottom: 16px;
        }

        @media (min-width: 680px) {
            flex-direction: row;
            justify-content: flex-end;
            .btn + .btn {
                margin-bottom: 0;
                margin-left: 20px;
            }
            .btn {
                min-width: unset;
                padding-inline: 24px;
            }
        }
    }
    &__content {
        strong { font-weight: 700; }
    }

    &__name {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
        @media (min-width: 680px) {
            font-size: 20px;
            line-height: 30px;
        }
    }
}

// Proposal modals
.proposal-modal{
    &__text {
        font-size: 16px;
        line-height: 24px;
        strong {
            @media (min-width: 680px) {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
    &__select {
        max-width: 120px;
        margin-top: 10px;
    }
    &__checkbox-toggler {
        display: inline-flex;
        margin-top: 24px;

        @media (min-width: 680px) {
            margin-top: 16px;
        }

        &:first-child {
            margin-top: 0;
            @media (min-width: 680px) {
                margin-top: 12px;
            }
        }
    }

    &__amount-input-label {
        max-width: 200px;
        margin-top: 24px;
        .input {
            padding-right: 80px;
        }
        &::after {
            pointer-events: none;
            content: attr(data-currency);
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            font-size: 14px;
            line-height: 22px;
            color: colors.$black;
        }
    }
}


// Laqyers Confirm modal
.lawyers-confirm-modal {

    &__item + &__item {
        margin-top: 36px;
        @media (min-width: 680px) {
            margin-top: 48px;
        }
    }
    &__item {
        transition: opacity .2s ease;
        &.disabled {
            opacity: .15;
            cursor: not-allowed;
            & > * { pointer-events: none }
        }
    }

    &__q {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
        &.req {
            &::after {
                content: '*';
                color: colors.$coquelicot
            }
        }
    }
    &__q + &__hint {
        margin-top: -12px;
    }
    &__hint {
        font-size: 14px;
        line-height: 22px;
        color: colors.$cadetGray;
        margin-bottom: 16px;
    }

    &__radios {
        position: relative;

        &-options {
            display: flex;

            .radio + .radio {
                margin-left: 24px;
                margin-top: 0;
            }
        }

        &-not-allowed-msg {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: colors.$coquelicot;
            position: absolute;
            top: calc(100% + 8px);
            left: 0;
            width: 100%;
        }
    }

    &__expertise {
        &-options {
            display: flex;
            flex-wrap: wrap;
            margin-inline: -6px;
            margin-bottom: -12px;
            white-space: nowrap;
        }
        &-option {
            margin-inline: 6px;
            margin-bottom: 12px;

            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: colors.$kleinBlue;
            padding: 7px 16px;
            border: 1px solid colors.$alabaster;
            border-radius: 36px;

            cursor: pointer;
            transition: .2s ease;

            &.active {
                color: colors.$snow;
                border-color: rgba(0,0,0,0);
                background-color: colors.$spaceCadet;
            }
        }
    }

    &__difficulty {

        &-items {
            @media (min-width: 680px) {
                display: grid;
                gap: 14px;
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        }
        &-item {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            transition: .2s ease;

            border: 1px solid colors.$lightGray;
            padding: 16px;

            &.active {
                border-color: rgba(0,0,0,0);
                background-color: colors.$kleinBlue;
            }
        }
        &-descr {
            display: block;
            font-size: 14px;
            line-height: 22px;
            color: colors.$cadetGray;
            margin-top: 10px;
            transition: .2s ease;
            margin-bottom: auto;
        }
        &-time {
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            color: colors.$black;
            margin-top: 10px;
            transition: .2s ease;
        }
        &-item + &-item:not(.radio--lined) {
            margin-top: 16px;
            @media (min-width: 680px) { margin-top: 0; }
        }

        &-item.active input:checked + .radio-name::before {
            box-shadow: inset 0 0 0 7px colors.$white;
        }
        &-item.active .radio-name,
        &-item.active &-time {
            color: colors.$white;
        }
        &-item.active &-descr { color: colors.$lightGray; }
    }
}
