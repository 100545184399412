@use 'variables/colors' as colors;

// Classes
.block { display: block; }
.i-block { display: inline-block; }
.flex {	display: flex; }
.i-flex { display: inline-flex; }
.flex, .i-flex {
    &.fdc { flex-direction: column; }
    &.aic { align-items: center; }
    &.aife { align-items: flex-end; }
    &.jcc { justify-content: center; }
    &.jcfe { justify-content: flex-end; }
    &.jcsb { justify-content: space-between; }
    &.aifs { align-items: flex-start; }
    &.ais { align-items: stretch; }
}

.f-left { float: left; }
.f-right { float: right; }

.abs { position: absolute; }
.rel { position: relative; }
.fix { position: fixed; }

.text-center { text-align: center; }

.vertical-center {
    display: flex;
    align-items: center;
    min-height: 100%;
}

.w100 { width: 100%; }
.half-width { width: 50%; }

.pointer-events-none { pointer-events: none; }

.bg-icon { display: inline-block; background-position: 50%; background-repeat: no-repeat; background-size: contain; }

.bg-image-cover {
    background-position: 50%; background-repeat: no-repeat; background-size: cover;
}

.mb-auto { margin-bottom: auto; }

.lowercase { text-transform: lowercase; }
.caps { text-transform: uppercase; }
.capitalize { text-transform: capitalize; }

[data-svg-icons] { width: 0; height: 0; }

.svg-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg { width: 100%; height: 100%; }
}

.m-center { margin: 0 auto; }
.ml-a { margin-left: auto; }
.mr-a { margin-right: auto; }
.mb-a { margin-bottom: auto; }
.mt-a { margin-top: auto; }

// Color Helpers
// * background color
.bg-black { background-color: colors.$black; };
.bg-ebony { background-color: colors.$ebony; };
.bg-cadetgray { background-color: colors.$cadetGray; };
.bg-lightgray { background-color: colors.$lightGray; };
.bg-alabaster { background-color: colors.$alabaster; };
.bg-snow { background-color: colors.$snow; };
.bg-white { background-color: colors.$white; };
.bg-spacecadet { background-color: colors.$spaceCadet; };
.bg-kleinblue { background-color: colors.$kleinBlue; };
.bg-sapphire { background-color: colors.$sapphire; };
.bg-beaublue { background-color: colors.$beauBlue; };
.bg-carrot { background-color: colors.$carrotOrange; };
.bg-melon { background-color: colors.$melon; };
.bg-keppel { background-color: colors.$keppel; };
.bg-lightcyan { background-color: colors.$lightCyan; };
.bg-coquelicot { background-color: colors.$coquelicot; };
.bg-mistyrose { background-color: colors.$mistyRose; };

// * text color
.color-black { color: colors.$black; };
.color-ebony { color: colors.$ebony; };
.color-cadetgray { color: colors.$cadetGray; };
.color-lightgray { color: colors.$lightGray; };
.color-alabaster { color: colors.$alabaster; };
.color-snow { color: colors.$snow; };
.color-white { color: colors.$white; };
.color-spacecadet { color: colors.$spaceCadet; };
.color-kleinblue { color: colors.$kleinBlue; };
.color-sapphire { color: colors.$sapphire; };
.color-beaublue { color: colors.$beauBlue; };
.color-carrot { color: colors.$carrotOrange; };
.color-melon { color: colors.$melon; };
.color-keppel { color: colors.$keppel; };
.color-lightcyan { color: colors.$lightCyan; };
.color-coquelicot { color: colors.$coquelicot; };
.color-mistyrose { color: colors.$mistyRose; };
